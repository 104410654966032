.loading{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  /* / background: rgba(0, 0, 0, 0.834); / */
  z-index: 999;
}

.spinner {
  width: 60px;
  height: 60px;
  background: url("Loader.svg") center no-repeat;
  animation: spin-anim 1.2s linear infinite;
  background-size: 60px 60px !important;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}