@import 'variables';

/**************************************/
.user-card {
    background: $bg-light-blue;
    border: 1px solid #707070;
    border-radius: 14px;

    .user-img {
        border: 3px solid #434343;
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50px;
        }

        .verified {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 20px;
            height: 20px;
            background: #259f00;
            color: #fff;
            border-radius: 50px;
            text-align: center;
            line-height: 20px;
        }
    }
}

.user-verify {
    position: relative;

    .verified {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 20px;
        height: 20px;
        background: #259f00;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        line-height: 20px;
    }
}

/**************************************/
.card {
    border: none;
    box-shadow: none;
    border-radius: 0;

    .card-header {
        background: $bg-light-blue2;
        padding: 5px 15px;
        border: none;
        border-radius: 0;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .card-body {
        padding: 10px;
        background-color: #F3F2F8;
        border-radius: 24px;
    }
}

.app-card {
    background: $bg-light-blue;
    border: 1px solid #707070;
    border-radius: 14px;
}

.card-gray-1 {
    background: #f3f2f8;
    border: 1px solid #707070;
    border-radius: 14px;
}

.card-light-green {
    background: #F2F7FF;
    border: 1px solid #707070;
    border-radius: 14px;
}

.card-tan-two-ton {
    background: #E8DCB9;
    border-radius: 20px;
    padding: 15px 10px;

    .card-body {
        position: relative;
        background: #FFF8E3;
        border-radius: 20px;
        padding: 15px;

    }
}

.card-certification {
    background: #FDE7D6;
    border-radius: 0px;
    padding: 15px;

    .card-body {
        position: relative;
        background: #fff;
        border-radius: 0px;
        padding: 15px;

        .card-delete-icon {
            position: absolute;
            right: 0px;
            bottom: 3px;
        }
    }
}

.card-skills {
    background: #FFFCF2;
    border-radius: 0;

    .card-body {
        position: relative;
        border-radius: 0;
        padding: 15px;
    }

    .rating {
        background: #bdbdbd;
        padding: 5px 15px;
        display: flex;
        justify-content: space-between;
    }

    .card-action {
        position: relative;
        top: -15px;
    }
}

.edit-icon {
    background-image: url(../../assets/images/edit-icon.svg);
    cursor: pointer;
    width: 26px;
    height: 26px;
    background-repeat: no-repeat;
    object-fit: contain;
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: center;
}

.card-tabs {
    border: none;
    display: flex;
    gap: 12px;

    .nav-item {
        border: none;

        .nav-link {
            border: none;
            background: #FFFFFF;
            color: #434343;
            font-size: 14px;
            font-family: $font-sb;
            height: 25px;
            border-radius: 15px;
            padding: 4px 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.active {
                background: $orange;
            }
        }
    }
}

/**************************************/
.fa-layout {
    display: flex;
    flex-wrap: wrap;

    .form-col {
        flex: 0 0 30%;
        border-right: 0.5px solid #a7a7a7;
        // height: 100vh;

        &.w-35 {
            flex: 0 0 35%;
        }
    }

    .accordian-col {
        flex: 0 0 70%;
        position: relative;

        &.w-65 {
            flex: 0 0 64%;
        }
    }

    @media (max-width: 600px) {
        .form-col {
            flex: 0 0 100%;
        }

        .accordian-col {
            flex: 0 0 100%;
        }
    }
}

/**************************************/

.app-accordian-info {
    .MuiAccordion-root {
        &:not(:last-of-type) {
            margin-bottom: 15px;
        }

        &::before {
            display: none;
        }

        box-shadow: none;
        border: none;
        margin-top: 0 !important;

        .MuiAccordionSummary-root {
            background: #e5e5e5;
            height: 45px;
            min-height: 32px;
            padding: 10px 13px;
        }

        .MuiCollapse-root {
            .MuiAccordionDetails-root {
                padding: 15px;
            }
        }
    }
}

/**************************************/

.app-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .tab-content {
        flex-grow: 1;

        .tab-pane {
            height: 100%;
        }
    }
}

.fix-tabs {
    >ul {
        margin-left: $expanded;
        transition: all 0.3s;
        position: fixed;
        left: 0;
        right: 0;
        z-index: 9;
        background: #fff;
    }

    >.tab-content {
        padding-top: 40px;
    }

    .fix-tab {
        >ul {
            margin-left: $expanded;
            transition: all 0.3s;
            position: fixed;
            left: 0;
            right: 0;
            z-index: 9;
            background: #fff;
        }

        >.tab-content {
            // padding-top: 40px;
        }
    }

    .app-tabs {

        >ul,
        >.nav-tabs {
            margin-left: $expanded;
            position: fixed;
            top: 85px;
            left: 0;
            right: 0;
            z-index: 9;
        }

        >.tab-content {
            padding-top: 40px;
        }
    }
}

// .tabs-container {
//     padding-bottom: 50px;
// }
.rb-tabs {
    border-bottom: 1px solid $br-clr;
    height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow: auto;
    white-space: nowrap;

    &.items-border {
        li:not(:last-of-type) {
            border-right: 1px solid #959595;
        }
    }

    li {

        .nav-link {
            border: none;
            border-bottom: 4px solid transparent;
            color: $text;
            font-size: 15px;
            padding: 0 20px;
            height: 100%;
            font-family: $font-r;
            background-color: transparent;
            display: flex;
            align-items: center;
            gap: 10px;

            &:active {
                font-family: $font-sb;
                
            }

            .cross-cricle {
                background: url(../images/cross-circle.svg);
                background-repeat: no-repeat;
                background-size: contain;
                width: 15px;
                height: 15px;
                cursor: pointer;
                display: inline-block;
            }


            &.active {
                font-family: $font-sb;
                background-color: transparent;
                border-bottom-color: $orange;
            }
        }
    }
}

.rb-tabs-v2 {
    border-bottom: 0.5px solid #959595;
    border-top: unset !important;
    height: 40px;
    min-height: 40px;
    background: #fff;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow: auto;
    white-space: nowrap;

    li {
        .nav-link {
            border: none;
            color: #707070;
            font-size: 14px;
            padding: 0 20px;
            height: 100%;
            font-family: $font-r;
            position: relative;
            background-color: transparent;
            border-radius: 0px;


            &.active {
                font-family: $font-sb;
                background-color: transparent;

                // &::before {
                //     content: "\F135";
                //     font-family: bootstrap-icons !important;
                //     position: absolute;
                //     left: 0px;
                //     top: 7px;
                //     font-size: 24px;
                //     line-height: 1;
                // }
            }
        }
    }
}

// .rb-tabs-v3 {
//     border-bottom: 1px solid $br-clr;
//     height: 40px;
//     min-height: 40px;
//     background: #fff;
//     display: flex;
//     flex-direction: row;
//     flex-wrap: nowrap;
//     overflow: hidden;
//     overflow: auto;
//     white-space: nowrap;

//     .nav-item {
//         border-right: 1px solid $br-clr;

//         .nav-link {
//             border: none;
//             color: $text;
//             font-size: 12px;
//             padding: 0 15px;
//             height: 100%;
//             font-family:  "Montserrat", sans-serif;
//             position: relative;
//             background-color: transparent;
//             border-radius: 0px;
//             display: flex;
//             align-items: center;

//             i {
//                 margin-right: 5px;
//                 color: $theme;
//             }



//             &.active {
//                 font-family: $font-sb;
//                 background-color: $orange;
//                 color: #fff;
//                 // &::before{
//                 //     content: '\F135';
//                 //     font-family: bootstrap-icons !important;;
//                 //     position: absolute;
//                 //     left: 0px;
//                 //     top: 7px;
//                 //     font-size: 24px;
//                 //     line-height: 1;
//                 // }
//             }
//         }
//     }
// }

$tabs-header-height: 44px;


.fix-tabs-v2 {
    .tabs-header {
        transition: all 0.3s;
        position: fixed;
        left: 0;
        right: 0;
        z-index: 9;
        margin-left: $expanded;
        height: $tabs-header-height;
    }

    >.tabs-header {
        // top: $header-height;
        background: #fff;
    }

    >.tab-content {
        // padding-top: $tabs-header-height;
    }

    .app-tabs {
        >.tabs-header {
            // top: $header-height+$tabs-header-height
        }

        >.tab-content {
            // padding-top: $tabs-header-height;
        }
    }
}

.tabs-v1-custom {
    .nav-tabs {
        border: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;
        gap: 20px;

        .nav-item {
            .nav-link {
                border: none;
                color: $text;
                font-size: 14px;
                // padding: 0 20px;
                font-family: $font-sb;
                background-color: transparent;
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 0;

                // .counter {
                //     background: $orange;
                //     height: 22px;
                //     min-width: 22px;
                //     border-radius: 50px;
                //     color: #fff;
                //     font-family: $font-sb;
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
                //     padding: 0 5px;
                //     font-size: 12px;
                // }

                .cross-cricle {
                    background: url(../images/cross-circle.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 15px;
                    height: 15px;
                    cursor: pointer;
                }



                &.active {
                    font-family: $font-sb;
                }
            }
        }
    }


}

.tabs-v2-custom {
    .nav-tabs {
        border: 1px solid $br-clr;
        border-radius: 50px;
        padding: 0px 15px;
        background: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;
        gap: 25px;
        width: auto;

        .nav-item {
            margin: 5px 0px;

            .nav-link {
                border: none;
                color: #333333;
                font-size: 13px;
                // padding: 0 20px;
                font-family: $font-r;
                background-color: transparent;
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 2px;
                padding-right: 25px;
                border-right: 2px solid transparent;
                border-radius: 0;

                &:not(:last-of-type) {
                    border-right: none;
                }

                &.active {
                    font-family: $font-sb;
                    font-size: 13px;
                    color: #FFFFFF;
                    background-color: #FD8458;
                    border-radius: 12px;
                    padding: 2px 6px;
                }

                img {
                    margin-left: 5px;
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                    object-position: center;
                }
            }

            &:last-of-type .nav-link {
                border-right: none;
            }
        }
    }



}

.tabs-action-bottom {
    background: #FCFCFC;
    position: fixed;
    height: 60px;
    // margin-left: $expanded;
    left: 213px;
    right: 0;
    bottom: 20px;
    transition: all 0.3s;
    z-index: 10;
    border-radius: 0 0 0 25px;
}

.tabs-action-bottom-admin {
    margin-top: 80px;
    background: #FCFCFC;
    position: absolute;
    height: 60px;
    // margin-left: $expanded;
    left: 0;
    right: 0;
    // bottom: 20px;
    transition: all 0.3s;
}

.tabs-expenses {
    .nav.nav-tabs {
        border: none;
        border-radius: 50px;
        overflow: hidden;
        width: max-content;
        background: #F3F2F8;
        margin: 0 auto;

        .nav-item {
            background: #E0E0E0;

            .nav-link {
                background: #E0E0E0;
                border: none;
                color: #434343;
                font-size: 12px;
                font-family: $font-sb;
                border-radius: 0;

                &.active {
                    background: #FD8458;
                    color: #fff;
                    border-radius: 50px;
                }
            }
        }
    }
}

.tabs-expenses-content {
    .category-icon {
        width: 18px;
        height: 18px;
        min-width: 18px;
        border-radius: 2px;
        color: #fff;
        background: #344777;
        font-size: 10px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/**************************************/
.modal {
    padding-left: 0;
}

.app-modal {
    &.modal-xxl {
        max-width: 1269px;
    }

    &.modal-xl {
        max-width: 1182px;
    }

    &.modal-md {
        max-width: 689px;
    }

    &.modal-c-lg {
        max-width: 600px;
    }

    // @media (max-width:1000px) {
    //     max-width: 90%;
    // }
    // @media (max-width:600px) {
    //     max-width: 90%;
    // }
    .modal-content {
        background: #f4f4f4;
        border: 1px solid #707070;
        border-radius: 40px;

        .modal-body {
            padding: 25px 30px;
            padding-bottom: 0;

            .modal-body-scroll {
                max-height: 550px;
                overflow: hidden;
                overflow-y: auto;
                margin-right: -40px;
                padding-right: 40px;
            }
        }

        .modal-footer {
            padding: 20px 40px;
        }
    }
}

/**************************************/
.delete-modal {
    width: 370px;

    .delete-icon {
        width: 60px;
        min-width: 60px;
        height: 60px;
        border-radius: 50px;
        border: 2px solid $danger;
        color: $danger;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-body {
        padding: 35px 20px;
    }
}

/**************************************/
.gender {
    background: $orange;
    color: #fff;
    font-size: 16px;
    height: 30px;
    line-height: 30px;
    font-family: $font-sb;
    text-decoration: none;
    border-radius: 50px;
    width: 152px;
}

/**************************************/
.alphabetic-filter {
    border: 1px solid $br-clr;
    border-radius: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;
    padding: 0 10px;

    >button {
        color: $text;
        font-family: $font-sb;
        background: transparent;
        border: none;
        text-align: center;

        &.active {
            color: $orange;
        }
    }

    .alphabetics {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 4px;
        color: $text;
        font-family: $font-sb;

        span {
            display: block;
            cursor: pointer;
            padding: 0 4px;
            text-align: center;

            &.active {
                color: $orange;
            }
        }
    }
}

/**************************************/
.grid-pagination {
    background: #e5e5e5;
    padding: 5px 10px;
    display: flex;
    align-self: center;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 10px;

    .row-per-page {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
            font-family: "Poppins-regular";
            font-size: 12px;
            color: #434343;
            margin: 0;
        }

        select {
            background: #fff;
            border: 1px solid #959595;
            border-radius: 6px;

            height: 20px;
            width: 50px;
            padding-left: 4px;
            font-size: 12px;
        }

        select:focus {
            outline: none;
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        gap: 15px;

        .page-nav {
            background: transparent;
            border: none;
            padding: 0;
            width: 25px;
            min-width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: $text-muted;
                font-size: 12px;
            }

            &.disabled {

                cursor: context-menu;

                i {
                    color: #6f6e6e80;
                }
            }
        }

        .left {
            i {
                transform: rotate(180deg);
                display: block;
            }
        }



        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            margin-left: 0 !important;
            display: flex;
            align-items: center;

            li {
                width: 25px;
                min-width: 25px;
                height: 25px;
                font-family: $font-sb;
                font-size: 12px;
                color: #434343;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &.active {
                    background: #fff;
                    border: 1px solid #959595;
                    border-radius: 4px;
                    color: #407BFF;
                }
            }
        }
    }

    .go-to-page {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
            font-family: "Poppins-regular";
            font-size: 12px;
            color: #434343;
            margin: 0;
        }

        input {
            background: #fff;
            border: 1px solid #959595;
            border-radius: 6px;
            padding: 2px 4px !important;
            height: 20px;
            width: 50px;
            font-size: 12px;
            text-align: center;
        }

        input:focus {
            outline: none;
        }

        button {
            background: transparent;
            border: none;
            padding: 0;
            color: $text-muted;
        }
    }
}

/**************************************/
.emp-profile-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .upload-emp-img {
        input {
            display: none;
        }

        label {
            font-size: 12px;
            font-family: $font-r;
        }
    }
}

/**************************************/
.patient-profile-img {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;

    .upload-patient-img {
        cursor: pointer;
        width: 31px;
        height: 31px;
        border-radius: 50px;
        background: $theme;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        input {
            display: none;
        }

        label {
            font-size: 12px;
            font-family: $font-m;
            cursor: pointer;

            i {
                color: #fff;
            }
        }
    }
}

/**************************************/
.academics-tabs {
    display: flex;

    >.nav-tabs {
        flex: 0 0 180px;
        min-width: 180px;
        max-width: 180px;
        display: flex;
        flex-direction: column;
        border: none;
        gap: 15px;
        padding: 15px 0;
        border-right: 1px solid $br-clr;

        .nav-item {
            .nav-link {
                border: none;
                text-align: center;
                color: $text;
                font-family: $font-sb;
                font-size: 16px;
                padding: 0;

                .tab-icon {
                    width: 65px;
                    height: 65px;
                    font-size: 20px;
                    border-radius: 50px;
                    margin: 0 auto;
                    // margin-bottom: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &.education {
                    .tab-icon {
                        background: #D5FFC3;
                        color: #49902A;
                    }
                }

                &.certification {
                    .tab-icon {
                        background: #C3EAFF;
                        color: #3A7898;
                    }
                }

                &.experience {
                    .tab-icon {
                        background: #EAD284;
                        color: #49902A;
                    }
                }

                &.skills {
                    .tab-icon {
                        background: #D7DEF7;
                        color: #707DAC;
                    }
                }

                &.active {
                    background: transparent;
                    font-family: $font-sb;

                    .tab-icon {
                        box-shadow: 0px 3px 6px #00000029;
                        border: 2px solid #838383;
                    }

                }

                .tab-img {
                    width: 85px;
                    height: 85px;
                    font-size: 30px;
                    border-radius: 50px;
                    margin: 0 auto;
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}

.app-vertical-tabs {
    display: flex;

    .tabs-menu {
        flex: 0 0 150px;
        min-width: 150px;
        max-width: 150px;
        display: flex;
        flex-direction: column;
        border: none;

        padding: 0;
        border-right: 1px solid $br-clr;

        >.nav-tabs {
            display: flex;
            flex-direction: column;
            border: none;
            gap: 15px;
            padding: 0;

            .nav-item {
                .nav-link {
                    border: none;
                    text-align: center;
                    color: $text;
                    font-family: $font-sb;
                    font-size: 14px;
                    padding: 0;
                    position: relative;

                    .move-item {
                        background: url('../../../public/assets/images/Icon material-swap-vert.svg');
                        position: absolute;
                        top: 0px;
                        right: 10px;
                        width: 20px;
                        height: 20px;
                        object-fit: contain;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }

                    .tab-img {
                        width: 60px;
                        height: 60px;
                        font-size: 30px;
                        border-radius: 50px;
                        margin: 0 auto;
                        margin-bottom: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }

                        .char {
                            position: absolute;
                            bottom: -5px;
                            right: -5px;
                            width: 20px;
                            height: 20px;
                            object-fit: contain;
                            background-repeat: no-repeat;
                            background-size: contain;
                        }

                        .p {
                            background: url('../../../public/assets/images/p.svg');
                        }

                        .s {
                            background: url('../../../public/assets/images/s.svg');
                        }
                    }
                }
            }
        }
    }
}


.upload-file {
    input {
        display: none;
    }
.form-control{
    height: 30px !important;
}
    label {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-style: italic;
    }
}

@mixin pills($pill-name, $bg, $text) {
    &.pill-#{$pill-name} {
        background: $bg;
        color: $text;
    }

    &.pill-#{$pill-name}-light {
        background: lighten($bg, 30%);
        color: $bg;
    }
}

.pill {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 2px 10px;
    font-size: 12px;
    font-family: $font-sb;
    height: 24px;
    width: 80px;

    img {
        width: 15px;
        height: 15px;
        margin-right: 3px;
    }

    &.rounded-icon {
        border-radius: 50%;
        width: 30px;
        height: 30px;
    }

    &.pill-sm {
        height: 20px;
    }

    /*Theme*/
    // @include pills(theme, $theme-primary, #fff);
    // @include pills(theme-light, #e5eaff, $theme-primary);
    /*Secondary*/
    // @include pills(secondary, $secondary, #505780);
    // @include pills(secondary-light, #82b1ff4d, #2196f3);
    /*Danger*/
    @include pills(danger, #FFBCBC, $text);
    // @include pills(danger-light, #fedddd, $danger);
    // @include pills(dark, #505780, #fff);
    /*Success*/
    @include pills(success, #7BD57E, $text);
    // @include pills(success-light, #deffee, #39bf85);
    /*Warning*/
    @include pills(warning, #E8E592, $text);
    // @include pills(warning-light, #ffcf8b4d, #eb7a52);
}


.filter-panel {
    width: 325px;
    position: absolute;
    right: -325px;
    bottom: 0;
    top: 47px;
    background: #F3F2F8;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;

    &.filter-open {
        right: 0 !important;
    }

    .filter-header {
        padding: 50px 20px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .filter-body {
        >div {
            border-top: 1px solid #ddd;
        }

        .input {
            border-top: 1px solid #ddd;

            .form-control:not(.search) {
                border: none;
                background: transparent;
                font-size: 14px;
                font-family: $font-sb;
            }


        }

        .app-accordian {
            background: transparent;
            overflow: hidden;

            .MuiAccordion-root {
                background: transparent;

                .MuiAccordionSummary-root {
                    background: transparent;
                    height: 28px;
                    min-height: 28px;

                    .MuiAccordionSummary-content {
                        font-size: 12px;
                        font-family: $font-sb;
                        color: $text;
                    }
                }

                .MuiCollapse-root {
                    .MuiAccordionDetails-root {
                        padding: 0 20px;
                    }
                }
            }
        }
    }

    .filter-footer {
        padding: 15px 20px;
        margin-top: auto;
    }

    &.live-preview {
        width: 575px !important;
        position: absolute;
        right: -575px;
        bottom: 0;
        top: 40px;
        bottom: 220px;

        .filter-header {
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .filter-body {
            padding: 0 20px 20px 20px;

            >div {
                border: none;
            }
        }
    }
}

/******************************************/
.npi-panel {
    position: absolute;
    background: #CECECE;
    transition: all 0.3s;
    display: none;
    bottom: 0;
    left: 5%;
    transform: translate(0, 0);
    padding: 20px 30px;
    right: 5%;
    border-radius: 5px;

    &.npi-open {
        display: block;
    }


}

/******************************************/

.list-info {

    display: flex;
    flex-direction: column;
    gap: 5px;

    .item {
        display: flex;
        align-items: center;

        .title {
            flex: 0 0 250px;
            font-size: 16px;
            color: $text;
            font-family: $font-r;
        }

        .value {
            font-size: 16px;
            color: $text;
            font-family: $font-r;
            margin-left: 30px;
        }
    }

}

.preview-img {
    width: 100%;
    height: 250px;
    overflow: hidden;
    box-shadow: 0 0 15px #00000042;
    border-radius: 20px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.login {
    .content {
        padding: 7rem 0;
    }

    .half,
    .half .container>.row {
        height: 100vh;
        min-height: 700px;
    }

    @media (max-width: 991.98px) {
        .half .bg {
            height: 200px;
        }
    }

    .half .contents {
        background: #fff;
    }

    .half .contents {
        width: 35%;
    }

    .half .bg {
        width: 65%;
    }

    @media (max-width: 1199.98px) {

        .half .contents,
        .half .bg {
            width: 100%;
        }
    }

    // .half .contents .form-control,
    // .half .bg .form-control {
    //     border: none;
    //     -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    //     box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    //     border-radius: 4px;
    //     height: 54px;
    //     background: #fff;
    // }

    // .half .contents .form-control:active,
    // .half .contents .form-control:focus,
    // .half .bg .form-control:active,
    // .half .bg .form-control:focus {
    //     outline: none;
    //     -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    //     box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    // }

    .bg {
        img {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }

    .half .forgot-pass {
        position: relative;
        top: 2px;
        font-size: 14px;
    }

    .control {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 14px;
    }

    .control .caption {
        position: relative;
        top: .2rem;
        color: #888;
    }

    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .control__indicator {
        position: absolute;
        top: 2px;
        left: 0;
        height: 20px;
        width: 20px;
        background: #e6e6e6;
        border-radius: 4px;
    }

    .control--radio .control__indicator {
        border-radius: 50%;
    }

    .control:hover input~.control__indicator,
    .control input:focus~.control__indicator {
        background: #ccc;
    }

    .control input:checked~.control__indicator {
        background: #fb771a;
    }

    .control:hover input:not([disabled]):checked~.control__indicator,
    .control input:checked:focus~.control__indicator {
        background: #fb8633;
    }

    .control input:disabled~.control__indicator {
        background: #e6e6e6;
        opacity: 0.9;
        pointer-events: none;
    }

    .control__indicator:after {
        font-family: 'icomoon';
        content: '\e5ca';
        position: absolute;
        display: none;
        font-size: 16px;
        -webkit-transition: .3s all ease;
        -o-transition: .3s all ease;
        transition: .3s all ease;
    }

    .control input:checked~.control__indicator:after {
        display: block;
        color: #fff;
    }

    .control--checkbox .control__indicator:after {
        top: 50%;
        left: 50%;
        margin-top: -1px;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .control--checkbox input:disabled~.control__indicator:after {
        border-color: #7b7b7b;
    }

    .control--checkbox input:disabled:checked~.control__indicator {
        background-color: #7e0cf5;
        opacity: .2;
    }

    .login-logo {
        width: 300px;
        margin: 0 auto;
        height: 200px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;

        }
    }
}

/******************************************/
.doted-text {
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;

    >div {
        flex-grow: 1;
        width: 100%;
        height: 2px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23434343FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
    }
}

/******************************************/
.reporting-columns {
    border: 1px solid #707070;
    display: flex;

    .item {
        flex: 1 0 200px;
        flex-basis: 200px;

        &:not(:last-of-type) {
            border-right: 1px solid #707070;
        }

        .report-title {
            background: #F2F7FF;
            font-size: 16px;
            color: #434343;
            display: flex;
            align-items: center;
            padding: 5px 15px;
            font-family: $font-sb;
            height: 45px;
            border-bottom: 1px solid #707070;
        }

        .reports {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 16px;
                    color: #434343;
                    font-family: $font-r;
                    border-bottom: 1px solid #707070;
                    padding: 5px 15px;

                    &::after {
                        content: '';
                        background-image: url('../images/Icon feather-arrow-up-left.svg');
                        display: block;
                        width: 12px;
                        height: 12px;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }
            }
        }

        &.general {
            background: #FFF8F4;
        }

        &.clinical {
            background: #EDF8FF;
        }

        &.financial {
            background: #FCF7FF;
        }

        &.productivity {
            background: #E4F0EC;
        }
    }

    &.v2 {
        border: none;

        .item {
            border: 1px solid #707070;
            border-radius: 14px;

            .report-title {
                background: transparent;
                border: none;
                color: #434343;
                padding-bottom: 0;
                height: 30px;
                padding-top: 15px;
            }

            .reports {
                padding: 15px;

                ul {

                    li {

                        border: 1px solid #707070;
                        height: auto;
                        border-radius: 7px;
                        font-size: 15px;

                        &:not(:last-of-type) {
                            margin-bottom: 10px;
                        }

                        &::after {
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            &.general li {
                background: #FEE7D6;
            }

            &.clinical li {
                background: #CDEAFD;
            }

            &.financial li {
                background: #F5E8FE;
            }

            &.productivity li {
                background: #E4F0EC;
            }
        }
    }

}

.report-preview {
    background: #F4F4F4;
    height: 100%;
}

.center-block {
    margin-left: 30px;
}

.center-img {
    // margin-left: 89px;
}