/*Typography*/
/*Inter*/
/*Typography Color*/
/*Theme*/
.layout {
  background: #344777;
  height: 100%;
}
/* .layout .sidebar {
  height: 100%;
  background: #344777;
  position: fixed;
  z-index: 99;
  transition: all 0.3s;
} */
/* .layout .sidebar .sidebar-header {
  padding: 0.5rem 1rem;
  height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
} */
.layout .sidebar .menu-list-container{
  display: flex;
  flex-direction: column;
  height: calc(100% - 170px);
  overflow: hidden;
  overflow-y: auto;
  justify-content: space-between;
  &::-webkit-scrollbar{
    width: 0px !important;
  }
}
/* .layout .sidebar .sidebar-header .toggle-sidebar {
  background: #DBDBDB;
  border: none;
  padding: 0;
  border-radius: 50px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -12px;
} */
/* .layout .sidebar .sidebar-header .toggle-sidebar i {
  color: #344777;
  font-size: 20px;
} */
/* .layout .sidebar .menu-list-container .menu-lists li a .angle-right-bottom {
  font-size: 25px;
  transform: rotate(45deg);
  margin-left: auto;
  position: relative;
  right: -15px;
}  */
/* .layout .sidebar .menu-list-container .menu-lists li.submenu .sub-menu-list {
  margin: 0 0 0 33px;
  display: none;
}
.layout .sidebar .menu-list-container .menu-lists li.submenu .sub-menu-list ul {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  border-left: 1px solid #fff;
}
.layout .sidebar .menu-list-container .menu-lists li.submenu .sub-menu-list ul li a {
  height: 30px;
  border-radius: 15px;
  padding: 0 24px;
  gap: 10px;
  padding-right: 0;
  border: none;
}
.layout .sidebar .menu-list-container .menu-lists li.submenu .sub-menu-list ul li a .dot {
  width: 5px;
  height: 5px;
}
.layout .sidebar .menu-list-container .menu-lists li.submenu .sub-menu-list ul li a.active {
  background: rgba(211, 211, 211, 0.53);
  border: none;
} */
/* .layout .sidebar .menu-list-container .menu-lists li.submenu.open .sub-menu-list {
  display: block;
}
.layout .sidebar .menu-list-container .menu-lists li.submenu.open .sub-menu-list ul {
  height: auto;
  visibility: visible;
  opacity: 1;
}  */
/* .layout .sidebar .menu-list-container .menu-lists.bottom > li {
  gap: 10px;
  border-right: none !important;
  padding: 0 5px;
  flex-grow: 1;
  width: 50%;
}
.layout .sidebar .menu-list-container .menu-lists.bottom > li a {
  padding: 0;
  padding: 0 10px;
  border-right: none !important;
}
.layout .sidebar .menu-list-container .menu-lists.bottom > li.submenu .sub-menu-list {
  background: #326060;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.69);
  border-radius: 14px;
  margin: 0;
  position: absolute;
  margin-top: -135px;
  padding: 15px;
  width: 230px;
}
.layout .sidebar .menu-list-container .menu-lists.bottom > li.submenu .sub-menu-list ul li a {
  padding-left: 15px;
}
.layout .sidebar .menu-list-container .menu-lists.bottom > li.submenu .sub-menu-list ul li a.active {
  background: rgba(211, 211, 211, 0.53);
  border: none;
}
.layout .sidebar .menu-list-container .menu-lists.bottom > li.submenu.open ul {
  height: auto;
  visibility: visible;
  opacity: 1;
} */
/* .layout .app-container {
  background: #FCFCFC;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 50px 0 0 50px;
  transition: all 0.3s;
}  */
/* @media (max-width: 1200px) {
  .layout .app-container .header {
    padding: 0rem 1rem;
  }
} */
/* .layout .app-container .header .header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
.layout .app-container .header .header-container .btn-header-toggle {
  background: transparent;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
  min-width: 20px;
  font-size: 20px;
  line-height: 1;
} */
/* @media (min-width: 600px) {
  .layout .app-container .header .header-container .btn-header-toggle {
    display: none;
  }
} */
.layout .app-container .app-content {
  /* overflow: hidden;
  overflow-y: auto;
  height: calc(100% - 100px); */
} 

/*************************/
/* .desktop .layout .sidebar {
  width: 250px;
}
.desktop .layout .app-container {
  margin-left: 250px;
} */
.desktop.sidebar-slim .layout .sidebar {
  width: 80px;
}
.desktop.sidebar-slim .layout .app-container {
  margin-left: 80px;
}
.desktop.sidebar-slim .layout .app-container .app-content .fix-tabs > ul {
  margin-left: 80px;
}
.desktop.sidebar-slim .layout .app-container .app-content .fix-tabs .app-tabs > ul, .desktop.sidebar-slim .layout .app-container .app-content .fix-tabs .app-tabs > .nav-tabs {
  margin-left: 80px;
}
.desktop.sidebar-slim .layout .app-container .app-content .fix-tabs-v2 .tabs-header {
  margin-left: 80px;
}
.desktop.sidebar-slim .layout .app-container .app-content .tabs-action-bottom {
  /* margin-left: 80px; */
}

.tablet .layout .sidebar {
  width: 250px;
}
.tablet .layout .app-container {
  margin-left: 80px;
}
.tablet.sidebar-slim .layout .sidebar {
  width: 80px;
}
.tablet.sidebar-slim .layout .app-container {
  margin-left: 80px;
}
.tablet.sidebar-slim .layout .app-container .app-content .fix-tabs > ul {
  margin-left: 80px;
}
.tablet.sidebar-slim .layout .app-container .app-content .fix-tabs .app-tabs > ul,
.tablet.sidebar-slim .layout .app-container .app-content .fix-tabs .app-tabs .nav-tabs {
  margin-left: 80px;
}
.tablet.sidebar-slim .layout .app-container .app-content .tabs-action-bottom {
  margin-left: 80px;
}

.mobile .layout .sidebar {
  left: -250px;
  width: 250px;
}
.mobile .layout .app-container {
  margin-left: 0;
  border-radius: 0;
}
.mobile .layout .app-container .app-content .fix-tabs > ul {
  margin-left: 0;
}
.mobile .layout .app-container .app-content .fix-tabs .app-tabs > ul,
.mobile .layout .app-container .app-content .fix-tabs .app-tabs .nav-tabs {
  margin-left: 0;
}
.mobile .layout .app-container .app-content .tabs-action-bottom {
  margin-left: 0;
}
.mobile.sidebar-mobile-open .layout .sidebar {
  left: 0;
}

.sidebar-slim .sidebar .sidebar-header {
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
}
.sidebar-slim .sidebar .sidebar-header .user-view .user-info {
  display: none;
}
.sidebar-slim .sidebar .menu-list-container .menu-lists li a {
  text-align: center;
  justify-content: center;
}
.sidebar-slim .sidebar .menu-list-container .menu-lists li a i {
  margin: 0 !important;
}
.sidebar-slim .sidebar .menu-list-container .menu-lists li a span {
  display: none;
}
.sidebar-slim .sidebar .menu-list-container .menu-lists li a .angle-right-bottom {
  display: none;
}
.sidebar-slim .sidebar .menu-list-container .menu-lists li.submenu .sub-menu-list {
  position: absolute;
  background: #326060;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6862745098);
  border-radius: 14px;
  margin-top: -50px;
  left: 45px;
  width: 230px;
}
.sidebar-slim .sidebar .menu-list-container .menu-lists li.submenu .sub-menu-list ul li a {
  justify-content: start;
}
.sidebar-slim .sidebar .menu-list-container .menu-lists li.submenu.open .sub-menu-list {
  padding: 15px;
}
.sidebar-slim .sidebar .menu-list-container .menu-lists.bottom {
  flex-direction: column;
  align-items: center;
}
.sidebar-slim .sidebar .menu-list-container .menu-lists.bottom .submenu.open .sub-menu-list {
  margin-top: -50px;
  left: 77px;
  width: 230px;
}

/*************************/
/******************************************/
.border-2 {
  border-style: solid;
  border-width: 2px;
}

/******************************************/
.app-padding {
  padding: 1rem 1.5rem;
}
 

/******************************************/
.alert-icon {
  position: relative;
}
.alert-icon i {
  color: #344777;
  font-size: 20px;
}
.alert-icon .count {
  width: 12px;
  height: 12px;
  font-size: 10px;
  text-align: center;
  border-radius: 50px;
  color: #fff;
  position: absolute;
  z-index: 999;
  background: #FC5050;
  right: -4px;
  top: -4px;
  line-height: 11px;
}

/******************************************/
.user-logo {
  width: 45px;
  height: 45x;
}
.user-logo img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/******************************************/
.sr-heading {
  font-family: "Poppins-semibold";
  font-size: 13px;
  font-weight: 600;
  color: #434343;
  margin: 0;
}

.modal-overflow-hidden .modal-content {
  overflow: hidden;
}
.modal-overflow-hidden .modal-content .modal-body {
  padding-block: 15px 30px;
}

.circle-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.circle-summary .cs-circle {
  width: 54px;
  height: 54px;
  padding: 10px;
  border: 1px solid #707070;
  border-radius: 50%;
  background-color: #E1C45A;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins-regular";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #FFFFFF;
}
.circle-summary .cs-text {
  font-family: "Poppins-regular";
  font-size: 12px;
  font-weight: 400;
  color: #434343;
  margin-block: 5px 0;
}

.sr-table .rdg-header-sort-name, .sr-table .rdg-cell {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #434343;
}
.sr-table .rdg-header-sort-name {
  font-weight: 600;
}
.sr-table .rdg-cell {
  font-weight: 400;
}
.sr-table div[role=row] div[role=columnheader] {
  background-color: #FCC1C0;
}

/******************************************/
.hk-dropdown-menu svg.MuiSvgIcon-root {
  width: 2rem;
  top: 0;
}
.hk-dropdown-menu .MuiInputBase-root {
  width: 100%;
}

.hk-form-fields label {
  font-family: "Poppins-regular";
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #707070;
}
.hk-form-fields .hk-checkbox {
  display: flex;
  align-items: flex-end;
}
.hk-form-fields .hk-checkbox .input {
  flex-direction: row;
}
.hk-form-fields .hk-checkbox .input label {
  font-family: "Poppins-semibold";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #434343;
}
.hk-form-fields .hk-checkbox .input input[type=checkbox] {
  width: 14px;
  height: 15px;
  margin-right: 5px;
}

/******************************************/
.hk-opt-bar .input label {
  font-size: 12px;
  font-weight: 600;
  color: #434343;
  margin-left: 15px;
}

/******************************************/
.hk-popper {
  position: absolute;
  top: 60px;
  right: 40px;
  width: 500px;
  height: 92%;
  background: #CECECE;
  z-index: 9000;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out;
  overflow-y: scroll;
  /* Popper Tables */
}
.hk-popper.popper-hidden {
  opacity: 0;
  visibility: hidden;
}
.hk-popper .hk-TopHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  padding-inline: 5px;
}
.hk-popper .hk-TopHeader .icon-Icon-metro-cross {
  font-size: 12.22px;
}
.hk-popper .hk-TopHeader + .row {
  width: 100%;
  margin-left: 0;
}
.hk-popper .popper-searchbar {
  width: 174px;
}
.hk-popper .popper-searchbar input[type=search] {
  height: 23px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-bottom: 12px;
}
.hk-popper .popper-searchbar input[type=search]::-moz-placeholder {
  font-family: "Poppins-regular";
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  color: #434343;
}
.hk-popper .popper-searchbar input[type=search]::placeholder {
  font-family: "Poppins-regular";
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  color: #434343;
}
.hk-popper .popper-searchbar .input-group-text {
  height: 23px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.hk-popper .popper-searchbar .input-group-text .bi-search::before {
  font-size: 12px;
}
.hk-popper .input-group-radio {
  display: flex;
  align-items: center;
}
.hk-popper .input-group-radio label {
  font-family: "Poppins-semibold";
  font-size: 12px;
  font-weight: 600;
  color: #434343;
  margin-right: 5px;
}
.hk-popper .input-group-radio input {
  margin: 0 5px 0 0;
}
.hk-popper .popper-t-table {
  padding: 0;
  height: 100%;
  border: 0;
}
.hk-popper .popper-t-table div[aria-colspan="15"] {
  padding: 0;
  height: -moz-fit-content;
  height: fit-content;
}
.hk-popper .popper-t-table div[aria-colindex="2"]:not(.popper-detailed-table div[aria-colindex="2"]) {
  grid-column: 2/4;
  width: 488px;
  font-family: "Poppins-regular";
  font-size: 12px;
  letter-spacing: 0px;
  font-weight: 400;
  color: #434343;
}
.hk-popper .popper-t-table div[role=gridcell]:not(.popper-detailed-table div[role=gridcell]) {
  border-right: 0;
}

.popper-detailed-table div[role=columnheader] {
  font-family: "Poppins-semibold";
  font-size: 12px;
  font-weight: 600;
  color: #434343;
}
.popper-detailed-table div[role=gridcell] {
  font-family: "Poppins-regular";
  font-size: 12px;
  letter-spacing: 0px;
  font-weight: 400;
  color: #434343;
}

.hk-lt-popper {
  position: relative;
}
.hk-lt-popper .hk-popper {
  top: 40px;
  left: 0;
  width: 531px;
  height: 636px;
  overflow-y: auto;
}

/******************************************/
.hk-tabs .nav-item {
  position: relative;
}
.hk-tabs .nav-item .nav-link::after {
  content: "";
  position: absolute;
  width: 80px;
  height: 4px;
  background: #EA9A4B;
  bottom: 0;
  inset-inline: 0;
  margin-inline: auto;
  opacity: 0;
  visibility: hidden;
}
.hk-tabs .nav-item .nav-link:hover {
  border-color: transparent;
}
.hk-tabs .nav-item .nav-link.active {
  border-color: transparent;
}
.hk-tabs .nav-item .nav-link.active::after {
  border: 0;
  opacity: 1;
  visibility: visible;
}
.hk-tabs .nav-item .nav-link.active:hover {
  border-color: transparent;
}
.hk-tabs .nav-item .nav-link:not(.nav-link.active):hover::after {
  opacity: 0.2;
  visibility: visible;
}

/******************************************/
.hk-file-picker {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border: 0.5px solid #959595;
  border-radius: 12px;
  cursor: pointer;
}
.hk-file-picker input {
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  opacity: 0;
  cursor: pointer;
}
.hk-file-picker label {
  font-family: "Poppins-regular";
  font-size: 13px;
  font-style: italic;
  letter-spacing: 0px;
  color: #707070;
}

.hk-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: -12px;
}
.hk-btn-container .hk-btn {
  padding: 6px 12px;
  margin-inline: 12px;
  background-color: transparent;
  border: 0;
  font-family: "Poppins-medium";
  font-size: 12px;
  font-weight: 500;
}
.hk-btn-container .hk-btn.grn-btn {
  color: #344777;
}
.hk-btn-container .hk-btn.rd-btn {
  color: #A52A2A;
}
/* 
.hk-accordian {
  overflow-x: scroll;
}
.hk-accordian .MuiAccordionDetails-root {
  padding: 0 !important;
} */
/* 
.hk-employee-card {
  height: 560px;
  background-color: #F8F9F9;
  border-radius: 0;
  padding: 0;
}
.hk-employee-card div[role=grid] {
  height: 100%;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #FCFCFC;
  border-color: #959595;
  border-left: 0;
}
.hk-employee-card div[role=grid] div[role=row]:hover div[role=gridcell] {
  background-color: #CCCCCC !important;
}
.hk-employee-card div[role=grid] div[role=row] .ym-view-btn {
  width: -moz-fit-content;
  width: fit-content;
  min-width: none;
}
.hk-employee-card div[role=grid] div[role=row] .rdg-cell {
  font-family: "Poppins-regular";
  font-size: 14px !important;
  font-weight: 400;
  color: #606060;
  background-color: #F8F9F9 !important;
  border-right: 0;
  border-color: #959595 !important;
} */
/* .hk-employee-card div[role=grid] div[role=row] .rdg-cell .rdg-header-sort-name {
  font-family: "Poppins-regular";
  font-size: 15px !important;
  font-weight: 400;
  color: #848484;
} */

/* .hk-doc-block .hk-sd-heading {
  background: #e5e5e5;
  height: 54px;
  min-height: 54px;
  padding: 13px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.hk-doc-block .hk-docs img {
  width: 45px;
}
/* .hk-doc-block .hk-docs .hk-close-btn {
  position: relative;
  width: 22px;
  height: 22px;
  background-color: #326060;
  margin-bottom: -10px;
  margin-left: -5px;
  z-index: 1;
}
.hk-doc-block .hk-docs .hk-close-btn:hover {
  background-color: #569f9f;
}
.hk-doc-block .hk-docs .hk-close-btn i {
  font-size: 10px;
  color: #fff;
} */
.hk-doc-block .hk-docs .icon-pdf {
  color: #FC5050;
  font-size: 60px;
}

.hk-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
}
.hk-grid .btn-icon-transparent {
  width: -moz-fit-content !important;
  width: fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.hk-dlt-modal .heading {
  font-family: "Poppins-medium";
  font-size: 22px;
  font-weight: 500;
  color: #333232;
}
.hk-dlt-modal .hk-dlt-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 35px;
  border: 3px solid #FC5050;
  border-radius: 100%;
}
.hk-dlt-modal .hk-dlt-icon .icon-delete {
  font-size: 45px;
  color: #FC5050;
}

/******************************************/
.hk-org-card {
  background-color: #F3F2F8;
  border: 0.5px solid #707070;
  border-radius: 14px;
  height: 100%;
}
.hk-org-card::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 12px;
  bottom: -2px;
  background: #E0E0E0;
  border-radius: 0px 0px 12px 12px;
  transition: 0.3s all;
}
.hk-org-card:hover::after {
  background: #F4C23A;
}
.hk-org-card.star-card-active::after {
  background: #F4C23A;
}
.hk-org-card.star-card-active .org-card-actions .hk-starbtn .icon-star-full {
  display: block;
}
.hk-org-card.star-card-active .org-card-actions .hk-starbtn .icon-star-empty {
  display: none;
}
.hk-org-card .org-card-actions {
  position: absolute;
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px 0;
}
.hk-org-card .org-card-actions .hk-iconMenu {
  cursor: pointer;
  padding: 20px;
  transition: 0.3s all;
}
.hk-org-card .org-card-actions .hk-iconMenu:hover {
  background-color: #e3e3e6;
}
.hk-org-card .org-card-actions .hk-starbtn i {
  font-size: 20px;
  font-weight: 700;
  color: #F4C23A;
}
.hk-org-card .org-card-actions .hk-starbtn .icon-star-full {
  display: none;
}
.hk-org-card .org-card-actions .hk-submenu {
  position: absolute;
  width: 207px;
  left: 95%;
  top: 30px;
  background: #F3F2F8;
  border: 0.5px solid #707070;
  border-radius: 9px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity, 0.3s all;
}
.hk-org-card .org-card-actions .hk-submenu ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.hk-org-card .org-card-actions .hk-submenu ul li {
  transition: 0.3s all;
}
.hk-org-card .org-card-actions .hk-submenu ul li:hover {
  background: #eaeaea;
}
.hk-org-card .org-card-actions .hk-submenu ul li:not(:last-child) {
  border-bottom: 1px solid #888888;
}
.hk-org-card .org-card-actions .hk-submenu ul li a {
  display: block;
  padding: 10px 16px;
  font-family: "Poppins-regular";
  font-size: 14px;
  font-weight: 400;
  color: #888888;
  text-decoration: none;
  color: #888888;
}
.hk-org-card .org-card-actions.active-submenu .hk-submenu {
  opacity: 1;
  visibility: visible;
}
.hk-org-card .org-card-actions.active-submenu-revert .hk-submenu {
  top: 70px;
  left: 20%;
  opacity: 1;
  visibility: visible;
}
.hk-org-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.hk-org-card .card-body .org-card-content .heading {
  min-height: 60px;
}
.hk-org-card .btn.btn-orange {
  background-color: #FDA269;
  height: 36px;
}
.hk-org-card .btn.btn-orange .bi-arrow-up-right::before {
  font-weight: 700;
}

@media (max-width: 786px) {
  .hk-org-card .org-card-actions .hk-submenu {
    left: 10%;
    top: 70px;
  }
}
/******************************************/
.hk-login {
  overflow: hidden;
  background: linear-gradient(45deg, #2F7BEA, #3a637c);
  height: 100%;
}
.hk-login .row {
  height: 100%;
  min-height: 100%;
}
.hk-login .half {
  height: 100%;
  min-height: 100%;
}
.hk-login .half .container {
  height: 100%;
  min-height: 100%;
}
.hk-login .half .bg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hk-login .half .bg img {
  width: 679px;
  height: 679px;
  -o-object-fit: contain;
     object-fit: contain;
}
.hk-login .half .contents {
  background-color: #FCFCFC;
  width: 50%;
  border-radius: 0px 137px 137px 0px;
}
.hk-login .half .contents .hk-t-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}
.hk-login .half .contents .hk-form-t {
  margin-top: 134px;
}
.hk-login .half .contents .hk-title {
  font-family: "Poppins-bold";
  font-size: 26px;
  line-height: 34px;
  font-weight: 700;
  text-align: left;
  color: #32457B;
  margin: 95px 0 0 80px;
  max-width: 391px;
}
.hk-login .half .contents .heading {
  font-family: "Poppins-semibold";
  font-size: 33.54px;
  line-height: 40px;
  font-weight: 600;
  text-align: left;
  color: #434343;
}
.hk-login .half .contents p.text {
  font-family: "Poppins-regular";
  font-size: 16.36px;
  line-height: 19.63px;
  font-weight: 400;
  text-align: left;
  color: #606060;
  margin-top: 10px;
}
.hk-login .half .contents p.text.forget-p {
  font-family: "Poppins-medium";
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}
.hk-login .half .contents form .input label {
  font-family: "Poppins-semibold";
  font-size: 18.14px;
  line-height: 21.77px;
  font-weight: 600;
  color: #606060;
}
.hk-login .half .contents form .input .forget-input {
  height: 66px;
}
.hk-login .half .contents form .input .forget-input::-moz-placeholder {
  font-family: "Poppins-regular";
  font-size: 16px;
  color: #707070;
}
.hk-login .half .contents form .input .forget-input::placeholder {
  font-family: "Poppins-regular";
  font-size: 16px;
  color: #707070;
}
.hk-login .half .contents form .form-check {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 0;
}
.hk-login .half .contents form .form-check .form-check-input {
  height: 10px;
  width: 10px;
  margin-right: 10px;
}
.hk-login .half .contents form .form-check .form-check-label {
  font-family: "Poppins-regular";
  font-size: 13.6px;
  line-height: 10px;
  font-weight: 400;
  color: #606060;
}
.hk-login .half .contents form .forgot-pass {
  font-family: "Poppins-regular";
  font-size: 13.6px;
  line-height: 10px;
  font-weight: 400;
  text-decoration: none;
  color: #777777;
}
.hk-login .half .contents .reset-pass-btn {
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: auto;
  padding: 12px 60px;
  margin-inline: auto;
}
.hk-login .half .contents .btn-blue {
  font-family: "Poppins-semibold";
  font-size: 18px;
  font-weight: 600;
  background: #334679;
  color: #FFFFFF;
}
.hk-login .half .contents p.inst-txt {
  font-size: 13.6px;
}
.hk-login .half .contents p.inst-txt a {
  color: #2F7BEA;
  text-decoration: none;
}
.hk-login a {
  cursor: pointer;
}
.hk-login a.blue {
  color: #2F7BEA;
}

@media (max-width: 1536px) {
  .hk-login.hk-np-view .half .contents .hk-t-img {
    margin-bottom: 20px;
  }
  .hk-login.hk-np-view .container .col-md-7 {
    min-width: 70%;
  }
  .hk-login.hk-np-view .container.mt-5 {
    margin-top: 20px !important;
  }
  .hk-login.hk-np-view .container .form-group {
    margin-bottom: 15px !important;
  }
  .hk-login .half .bg img {
    width: 379px;
    height: auto;
  }
  .hk-login .half .contents .hk-title {
    margin: 20px 0 0 20px;
  }
  .hk-login .half .contents .hk-form-t {
    margin-top: 20px;
  }
  .hk-login .half .contents p.inst-txt {
    margin-top: 30px !important;
  }
}
@media (max-width: 1199.98px) {
  .hk-login {
    overflow: auto;
  }
  .hk-login .half {
    height: auto;
  }
  .hk-login .half .contents {
    width: 100%;
    border-radius: 0;
  }
  .hk-login .half .contents .hk-title {
    margin: 0 auto;
    padding-top: 30px;
    padding-inline: 15px;
  }
  .hk-login .half .contents .hk-form-t {
    margin-top: 60px;
  }
}
@media (max-width: 992px) {
  .hk-login .half .bg img {
    width: 179px;
    height: auto;
  }
}
/******************************************/
.hk-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.hk-grid .hk-grid-col {
  height: 100%;
}

@media (max-width: 1536px) {
  .hk-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) {
  .hk-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 576px) {
  .hk-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
/******************************************/
/* .hk-fields-v1 .hk-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background-color: #F3F2F8;
  padding-inline: 16px;
  min-height: 54px;
}
.hk-fields-v1 .hk-top-bar .hk-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hk-fields-v1 .hk-top-bar .hk-heading h5 {
  font-family: "Poppins-medium";
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: #434343;
  margin: 0;
}
.hk-fields-v1 .hk-top-bar .hk-heading .circle-word {
  width: 29px;
  height: 29px;
  border-radius: 50px;
  background-color: #80ADBC;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  font-family: "Poppins-bold";
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
}
.hk-fields-v1 .hk-top-bar .hk-top-btn {
  font-family: "Poppins-semibold";
  font-size: 15px;
  font-weight: 600;
  color: #FFFFFF;
  background-color: #344777;
  border-radius: 0;
  height: auto;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 3px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hk-fields-v1 .hk-top-bar .hk-top-btn i {
  margin-right: 6px;
  font-size: 10px;
  padding: 6px;
  background: #fff;
  border-radius: 50px;
}
.hk-fields-v1 .form-group label {
  font-family: "Poppins-regular";
  font-size: 12px;
  line-height: 26px;
  font-weight: 400;
  color: #707070;
}
.hk-fields-v1 .form-group input::-moz-placeholder, .hk-fields-v1 .form-group textarea::-moz-placeholder {
  font-family: "Poppins-regular";
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: #707070;
  font-style: italic;
}
.hk-fields-v1 .form-group input::placeholder, .hk-fields-v1 .form-group textarea::placeholder {
  font-family: "Poppins-regular";
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: #707070;
  font-style: italic;
}
.hk-fields-v1 .form-group .MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 12px;
}
.hk-fields-v1 .form-group .MuiInputBase-root.MuiOutlinedInput-root .MuiSelect-select {
  padding: 11px 14px;
}
.hk-fields-v1 .hk-min-h {
  height: 768px;
  overflow-y: scroll;
}
.hk-fields-v1 .hk-NH-block {
  margin-inline: 0;
  padding: 20px 16px 16px;
  border-bottom: 1px solid #959595;
}
.hk-fields-v1 .hk-NH-block p {
  font-family: "Poppins-regular";
  font-size: 17px;
  line-height: 23px;
  font-weight: 400;
  margin-bottom: 0;
  color: #707070;
}
.hk-fields-v1 .hk-NH-block p span {
  font-family: "Poppins-medium";
  font-weight: 500;
  color: #434343;
}
.hk-fields-v1 .hk-NH-block .hk-NH-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; 
  margin-bottom: 15px;
  padding-inline: 0;
}
.hk-fields-v1 .hk-NH-block .hk-NH-flex .hk-fix-w {
  width: 118px;
} */

/* .hk-radios {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0;
  margin-inline: -1.2rem;
}
.hk-radios .form-check {
  padding-left: 0;
  margin-inline: 1.2rem;
}
.hk-radios .form-check .form-check-input {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 5px;
}
.hk-radios .form-check .form-check-input:checked {
  background-image: none;
  background-color: #FD8458;
}
.hk-radios .form-check .form-check-input:checked + label {
  font-family: "Poppins-semibold";
  font-weight: 600;
  color: #434343;
} */

@media (max-width: 576px) {
  .hk-fields-v1 .hk-top-bar .hk-top-btn {
    font-size: 12px;
  }
  .hk-fields-v1 .hk-top-bar .hk-top-btn i {
    font-size: 8px;
    padding: 4px;
  }
  .hk-fields-v1 .hk-min-h {
    height: 100%;
    overflow-y: scroll;
  }
  .hk-fields-v1 .hk-NH-block .hk-NH-flex {
    flex-direction: column;
  }
}
.hk-NH-circle {
  width: 26px;
  height: 26px;
  display: grid;
  place-items: center;
  background: #9F7B65;
  font-family: "Poppins-bold";
  font-size: 15px;
  font-weight: 700;
  border-radius: 50px;
  color: #fff !important;
  margin-right: 10px;
}

/******************************************/
/* .hk-exp-table {
  background: transparent;
}
.hk-exp-table div[role=row] {
  background: white !important;
}
.hk-exp-table div[role=row]:nth-child(odd) {
  background: #f7f7f7 !important;
} */
/* .hk-exp-table div[role=row] div[role=columnheader].rdg-cell {
  background: #F2F7FF;
} */

/******************************************/
/* .hk-password-field {
  position: relative;
}
.hk-password-field input {
  padding-right: 36px !important;
}
.hk-password-field .hk-eye-btn {
  position: absolute;
  right: 12px;
  top: 24px;
}
.hk-password-field .hk-eye-btn i {
  font-size: 15px;
  color: #777777;
} */

/******************************************/
.hk-expand-table {
  position: relative;
  max-height: 318px;
  overflow-y: scroll;
  overflow-x: scroll;
}
.hk-expand-table table thead, .hk-expand-table table tbody, .hk-expand-table table tfoot, .hk-expand-table table tr, .hk-expand-table table td, .hk-expand-table table th {
  border: 0.5px solid #959595;
  padding: 5px 10px;
}
.hk-expand-table table tr .hk-expandTable-btn {
  cursor: pointer;
}
.hk-expand-table table tr td {
  font-size: 14px;
  padding-inline: 6px;
}
.hk-expand-table table tr td:nth-child(1) {
  border: 0;
}
.hk-expand-table table tr td:nth-child(2) {
  border: 0;
}
.hk-expand-table table tr th {
  min-width: 80px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hk-expand-table table tr th:nth-child(1) {
  min-width: 0;
  max-width: 10px;
}
.hk-expand-table table tr input {
  font-size: 14px;
  padding-inline: 6px;
}
.hk-expand-table table tr select {
  font-size: 14px;
  padding-inline: 6px;
  min-width: 114px;
}
.hk-expand-table table tr .hk-table-flex {
  display: flex;
  align-items: center;
  gap: 5px;
}
.hk-expand-table table tr .hk-table-flex input[variant=outlined] {
  width: 52px;
  height: 26px;
  background: #FFFFFF;
  border: 0.5px solid #959595;
  border-radius: 5px;
}
.hk-expand-table table tr .hk-table-flex .MuiAutocomplete-root {
  height: 26px;
  width: 100%;
  border-radius: 5px;
}
.hk-expand-table table tr .hk-table-flex .MuiAutocomplete-root input {
  max-height: 39px !important;
  min-width: auto !important;
}
.hk-expand-table table tr .hk-table-flex.hk-input-groups {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr auto;
}
.hk-expand-table table tr .hk-table-flex.hk-input-groups .input-group {
  width: 52px;
  height: 26px;
}
.hk-expand-table table tr .hk-table-flex.hk-input-groups .input-group .MuiAutocomplete-root {
  border-radius: 5px;
}
.hk-expand-table table tr .hk-table-flex .input-group-text {
  padding: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.hk-expand-table table tr .hk-table-flex .input-group-text i {
  font-size: 13px;
}
.hk-expand-table table tr .hk-table-flex .MuiButtonBase-root {
  width: 30px !important;
}
.hk-expand-table table tr .calender-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}
.hk-expand-table table tr .hk-datepicker-field {
  width: 100px;
  padding-inline: 0;
}
.hk-expand-table table tr#hk-expandRow-table {
  transition: 0.3s all;
}
.hk-expand-table table tr#hk-expandRow-table table {
  position: relative;
}
.hk-expand-table table tr#hk-expandRow-table table tr th {
  padding: 10px;
  min-width: 138px;
  background: #D7FAE2;
}
.hk-expand-table table tr#hk-expandRow-table table tr th:nth-child(1) {
  min-width: 138px;
  background: #D7FAE2;
}
.hk-expand-table table tr#hk-expandRow-table table tr td {
  padding: 10px;
}
.hk-expand-table table tr#hk-expandRow-table table tr td:nth-child(1) {
  border: 0.5px solid #959595;
}
.hk-expand-table table tr#hk-expandRow-table table tr td:nth-child(2) {
  border: 0.5px solid #959595;
}
.hk-expand-table table tr#hk-expandRow-table table tbody {
  background: #f2fff6;
}
.hk-expand-table table tr#hk-expandRow-table table .hk-table-fixedRow {
  position: absolute;
  background: #d7fae2;
  width: 100%;
  bottom: 0px;
  padding: 0px !important;
  border-top: 1px solid #707070 !important;
  border-bottom: 1px solid #707070 !important;
}
.hk-expand-table table tr#hk-expandRow-table table .hk-table-fixedRow td {
  min-width: 138px;
  border: 0 !important;
  padding: 6px 12px !important;
  font-family: "Poppins-semibold";
  font-weight: 500;
  color: #434343;
}

/******************************************/
.MuiAutocomplete-popper {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

/* Hide the default calendar icon */
.hk-date::-webkit-calendar-picker-indicator {
  display: none;
}
.hk-date:after {
  content: "\e934";
  font-family: "icomoon" !important;
  color: #4b4a4a;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

/* Hide the default clock icon */
.hk-time::-webkit-calendar-picker-indicator {
  display: none;
}
.hk-time:after {
  font-family: "icomoon" !important;
  content: "\e935";
  color: #344777;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 0px;
}

.hk-date-ranger i {
  margin-left: -35px;
}

/******************************************/
.hk-check-modal {
  max-width: 665px;
}
.hk-check-modal .modal-content .modal-body {
  position: relative;
  padding-bottom: 30px;
}
.hk-check-modal .modal-content .modal-body::after {
  content: "";
  position: absolute;
  width: 80%;
  height: 0.5px;
  bottom: 0;
  inset-inline: 0;
  margin-inline: auto;
  background: #959595;
}
.hk-check-modal .modal-content .hk-check-content {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.hk-check-modal .modal-content .hk-check-content > div {
  max-width: 50%;
  text-align: left;
}
.hk-check-modal .modal-content .hk-check-content img {
  width: 260px;
  padding-bottom: 35px;
  border-bottom: 0.5px solid #959595;
}
.hk-check-modal .modal-content .hk-check-content h3 {
  font-family: "Poppins-semibold";
  font-size: 18px;
  font-weight: 600;
  color: #434343;
  margin-bottom: 0;
}
.hk-check-modal .modal-content .hk-check-content p {
  font-family: "Poppins-regular";
  font-size: 17px;
  font-weight: 400;
  color: #707070;
  margin-top: 9px;
  padding-right: 40px;
}
.hk-check-modal .modal-content .hk-check-content button {
  margin-bottom: 25px;
  width: 190px;
  height: 39px;
}
.hk-check-modal .modal-content .hk-check-content input {
  margin-top: 9px;
  height: 34px;
}
.hk-check-modal .modal-content .hk-check-content span {
  font-family: "Poppins-semibold";
  font-size: 14px;
  font-weight: 600;
  color: #434343;
  display: flex;
  align-items: center;
}
.hk-check-modal .modal-content .hk-check-content span i {
  font-size: 18px;
  margin-right: 5px;
}
.hk-check-modal .modal-content .hk-check-content label {
  font-family: "Poppins-semibold";
  font-size: 14px;
  font-weight: 600;
  color: #434343;
}
.hk-check-modal .modal-content .hk-check-suggestions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding-inline: 23px;
  margin-top: 20px;
}
.hk-check-modal .modal-content .hk-check-suggestions h4 {
  font-size: 14px;
  font-weight: 400;
  color: #707070;
  background-color: #F6F6F9;
  border: 0.5px solid #959595;
  padding: 5px 8px;
  margin-bottom: 5px;
  text-align: center;
  cursor: pointer;
}
.hk-check-modal .modal-content .btn-orange {
  background-color: #FD8458;
  height: 30px;
  min-width: 81px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .hk-check-modal .modal-content .hk-check-content {
    flex-direction: column-reverse;
  }
  .hk-check-modal .modal-content .hk-check-content > div {
    max-width: 100%;
  }
  .hk-check-modal .modal-content .hk-check-content img {
    width: 100%;
    margin-bottom: 20px;
  }
  .hk-check-modal .modal-content .hk-check-content p {
    padding-right: 0;
  }
  .hk-check-modal .modal-content .hk-check-suggestions {
    grid-template-columns: 1fr;
  }
}
/******************************************/
.hk-ems-v1 {
  /* For Tabs */
} 
.hk-ems-v1 ul[role=tablist] {
  display: none;
}
.hk-ems-v1 div[role=tabpanel] {
  padding-inline: 0;
}
.hk-ems-v1 .fade:not(.show) {
  opacity: 1;
  display: none;
} 
.hk-ems-v1 .hk-ems-datePicker {
  margin-bottom: 0;
}
.hk-ems-v1 .hk-ems-datePicker input {
  background: transparent;
  border: 0px !important;
  width: 291px;
  height: 23px !important;
}
.hk-ems-v1 .hk-ems-datePicker i {
  font-size: 12px;
  margin-left: 0;
}
.hk-ems-v1 .autocomplete-group {
  width: 207px;
  height: 29px;
  background: #FFF;
}
.hk-ems-v1 .autocomplete-group .MuiAutocomplete-root {
  border-radius: 15px;
}
.hk-ems-v1 .autocomplete-group .MuiAutocomplete-root .MuiAutocomplete-endAdornment [class*=popupIndicator] {
  background: white !important;
}
.hk-ems-v1 .autocomplete-group .MuiAutocomplete-root .MuiAutocomplete-endAdornment [class*=popupIndicator] svg {
  fill: #A7A7A7 !important;
  width: 1rem !important;
  height: 1rem !important;
}
.hk-ems-v1 .autocomplete-group .MuiChip-root {
  height: 20px;
}
.hk-ems-v1 .autocomplete-group .MuiChip-root .MuiChip-label {
  font-size: 14px;
  color: #434343;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
  max-width: 80px;
}
/* .hk-ems-v1 .hk-timeline-btns {
  display: flex;
  align-items: end;
  background-color: #F3F2F8;
} */
/* .hk-ems-v1 .hk-timeline-btns a {
  text-decoration: none;
  position: relative;
  font-family: "Poppins-semibold";
  font-size: 16px;
  font-weight: 600;
  color: #707070;
  padding: 6px 15px;
  border: 0px solid #707070;
  transition: 0.3s all;
  cursor: pointer;
}
.hk-ems-v1 .hk-timeline-btns a:hover {
  background-color: #FD8458;
  color: #FFFFFF;
  border-width: 0.5px;
}
.hk-ems-v1 .hk-timeline-btns a.btn-active {
  background-color: #FD8458;
  color: #FFFFFF;
  border-width: 0.5px;
}
.hk-ems-v1 .hk-timeline-btns a:nth-child(2)::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 21px;
  background: #707070;
  inset-block: 0;
  left: 0;
  margin-block: auto;
}
.hk-ems-v1 .hk-timeline-btns a:nth-child(2)::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 21px;
  background: #707070;
  inset-block: 0;
  right: 0;
  margin-block: auto;
} */
/* .hk-ems-v1 table.hk-attendance-table {
  background-color: #BFBFBF;
  width: 100%;
}
.hk-ems-v1 table.hk-attendance-table thead {
  background-color: #F3F2F8;
}
.hk-ems-v1 table.hk-attendance-table thead th {
  border: 1px solid #959595;
  padding: 6px 12px;
  font-family: "Poppins-semibold";
  font-size: 17px;
  font-weight: 600;
  color: #434343;
  text-align: center;
}
.hk-ems-v1 table.hk-attendance-table thead th span {
  font-family: "Poppins-regular";
  font-size: 16px;
  font-weight: 400;
  display: block;
} */
/* .hk-ems-v1 table.hk-attendance-table tbody tr td {
  padding: 6px;
  border: 1px solid #959595;
}
.hk-ems-v1 table.hk-attendance-table tbody tr td:first-child {
  min-width: 256px;
  width: 256px;
}
.hk-ems-v1 table.hk-attendance-table tbody tr td:has(div) {
  background-color: white;
}
.hk-ems-v1 table.hk-attendance-table tbody tr td div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hk-ems-v1 table.hk-attendance-table tbody tr td img {
  width: 32px;
  height: 32px;
  border-radius: 50px;
}
.hk-ems-v1 table.hk-attendance-table tbody tr td .heading {
  font-family: "Poppins-regular";
  font-size: 16px;
  font-weight: 400;
  color: #434343;
  margin-left: 20px;
}
.hk-ems-v1 table.hk-attendance-table tbody tr td .hk-tabel-status {
  width: 30px;
  height: 30px;
  margin-inline: auto;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins-bold";
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  cursor: pointer;
  margin-right: 0;
}
.hk-ems-v1 .hk-attendance-controller {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #F3F2F8;
  min-height: 64px;
  padding: 6px;
  border-bottom: 1px solid #959595;
}
.hk-ems-v1 .hk-attendance-controller .hk-controller-btn {
  background: #E5E5E5;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 0;
  outline: 0;
  border-radius: 7px;
  padding: 3px 13px;
}
.hk-ems-v1 .hk-attendance-controller .hk-controller-btn i {
  font-size: 16px;
}
.hk-ems-v1 .hk-attendance-controller .hk-controller-status {
  background: #E5E5E5;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 7px;
  padding: 6px 30px;
  font-family: "Poppins-semibold";
  font-size: 16px;
  font-weight: 600;
  color: #434343;
} */
.hk-ems-v1 .green-bg {
  background-color: #5AB461;
}
.hk-ems-v1 .pink-bg {
  background-color: #F59AE3;
}
.hk-ems-v1 .brown-bg {
  background-color: #9F7B65;
}
/* .hk-ems-v1 .gray-bg {
  background-color: #BFBFBF;
} */
.hk-ems-v1 .liteBlue-bg {
  background-color: #80ADBC;
}
.hk-ems-v1 .litePink-bg {
  background-color: #FC9E9E;
}
.hk-ems-v1 .liteOrnage-bg {
  background-color: #EBA889;
}

.MuiAutocomplete-popper li {
  font-size: 12px;
  color: #434343;
}

/******************************************/
.hk-EA .action-row {
  padding: 15px 20px;
}
.hk-EA .hk-ems-datePicker {
  width: -moz-fit-content;
  width: fit-content;
}
.hk-EA .hk-ems-datePicker span {
  font-family: "Poppins-regular";
  font-size: 20px;
  font-weight: 400;
  color: #707070;
  margin-right: 8px;
}
.hk-EA .hk-ems-datePicker input {
  width: -moz-fit-content;
  width: fit-content;
}
.hk-EA .hk-EA-table {
  padding: 30px;
  background: #F3F2F8;
  overflow: scroll;
}
.hk-EA .hk-EA-table table {
  width: 100%;
}
.hk-EA .hk-EA-table table th {
  font-family: "Poppins-medium";
  font-size: 18px;
  font-weight: 500;
  color: #575757;
  padding-right: 32px;
}
.hk-EA .hk-EA-table table th div {
  padding-bottom: 8px;
  border-bottom: 1px solid #959595;
}
.hk-EA .hk-EA-table table td {
  padding: 20px 37px 20px 0;
  height: 100px;
  width: 142px;
}
.hk-EA .hk-EA-table table td > div {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.hk-EA .hk-EA-table table td > div:has(> p + .EA-status) {
  flex-direction: column;
}
.hk-EA .hk-EA-table table td p {
  font-family: "Poppins-medium";
  font-size: 18px;
  font-weight: 500;
  color: #A7A7A7;
  cursor: pointer;
}
.hk-EA .hk-EA-table table td a {
  font-family: "Poppins-medium";
  font-size: 18px;
  font-weight: 500;
  color: #344777;
  padding-left: 6px;
}
.hk-EA .hk-EA-table table td .EA-status {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  margin-top: 16px;
  font-family: "Poppins-medium";
  font-size: 16px;
  font-weight: 500;
  color: #A7A7A7;
}
.hk-EA .hk-EA-table table td .EA-status.present {
  background: #DEEDBB;
}
.hk-EA .hk-EA-table table td .EA-status.absent {
  background: #FEC9C9;
}
.hk-EA .hk-EA-TopBar {
  display: flex;
  align-items: center;
  justify-content: start;
  background: #344777;
  padding: 8px 14px;
}
.hk-EA .hk-EA-TopBar span {
  font-family: "Poppins-semibold";
  font-size: 15px;
  font-weight: 600;
  color: #FFFFFF;
  margin-left: 6px;
}

.icon-certificate-icon {
  font-size: 26px;
  margin-right: 10px;
}

/******************************************/
/* .hk-Editor-modal {
  max-width: 1131px;
}
.hk-Editor-modal .modal-body {
  padding: 0 !important;
}

.hk-EmailFormat-box {
  padding-left: 15px;
}
.hk-EmailFormat-box .hk-EmailFormat-Editor {
  padding-bottom: 115px;
}
.hk-EmailFormat-box .hk-EmailFormat-Editor .ql-container {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.hk-EmailFormat-box .hk-EmailFormat-Editor .ql-container .ql-editor {
  background-color: #fff;
  height: 390px;
  border-radius: 10px;
} */
/* .hk-EmailFormat-box .hk-EmailFormat-Editor .ql-toolbar {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.hk-EmailFormat-box .hk-EmailFormat-Editor .ql-toolbar button {
  background-color: #fff !important;
  height: 30px !important;
  padding: 5px !important;
  width: 30px !important;
  border: 1px solid #ccc !important;
}
.hk-EmailFormat-box .hk-EmailFormat-Editor .ql-toolbar .ql-picker {
  background-color: #fff;
  border: 1px solid #ccc;
} */
/* 
.hk-EmailFormat-Tags {
  padding: 40px 10px 40px 20px;
  border-top-right-radius: 50px;
}
.hk-EmailFormat-Tags .chips {
  font-size: 14px;
  font-weight: 400;
  color: #707070;
  background-color: #F6F6F9;
  border: 0.5px solid #959595;
  padding: 5px 8px;
  margin-bottom: 15px;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  transition: 0.3s all;
}
.hk-EmailFormat-Tags .chips.active {
  background-color: #344777;
  color: #FFFFFF;
}
.hk-EmailFormat-Tags .chips:hover {
  background-color: #344777;
  color: #FFFFFF;
} */

@media (max-width: 786px) {
  .hk-EmailFormat-box .hk-EmailFormat-Editor {
    padding-bottom: 10px;
  }
  .hk-Editorchips-stack {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    gap: 10px;
  }
}
/******************************************/
.rbc-date-cell {
  z-index: -1;
}
/* 
.hk-EA-calender {
  padding-inline: 30px;
  background: #F3F2F8;
} */
/* .hk-EA-calender .rbc-row-segment .rbc-event {
  background-color: transparent;
}
.hk-EA-calender .rbc-row-segment .rbc-event .EA-status {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  margin-top: 16px;
  font-family: "Poppins-medium";
  font-size: 16px;
  font-weight: 500;
  color: #A7A7A7;
}
.hk-EA-calender .rbc-row-segment .rbc-event .EA-status.present {
  background: #DEEDBB;
}
.hk-EA-calender .rbc-row-segment .rbc-event .EA-status.absent {
  background: #FEC9C9;
}
.hk-EA-calender .rbc-day-bg {
  background: transparent;
} */
/* .hk-EA-calender .rbc-header {
  position: relative;
  font-family: "Poppins-medium";
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #575757;
  padding-right: 32px;
  padding-left: 0;
  padding-bottom: 8px;
}
.hk-EA-calender .rbc-header::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 80%;
  left: 0;
  bottom: 0;
  background: #959595;
} */
/* .hk-EA-calender .rbc-date-cell {
  text-align: left;
  padding: 10px 20px;
}
.hk-EA-calender .rbc-event-content a {
  font-family: "Poppins-medium";
  font-size: 18px;
  font-weight: 500;
  color: #344777 !important;
  padding-left: 6px;
} */

.rbc-month-view,
.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row {
  border: 0 !important;
}

.rbc-header + .rbc-header {
  border-left: 0 !important;
  border-bottom: 0;
}

/******************************************/
.hk-imgHandler {
  position: relative;
}
.hk-imgHandler input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.hk-fields-v1 .StartTime {
  margin-inline: 10px;
}
.hk-fields-v1 .StartTime label {
  margin-left: -10px;
}
.hk-fields-v1 .EndTime {
  margin-inline: -10px;
}
.hk-fields-v1 .EndTime label {
  margin-left: -10px;
}

@media (max-width: 1400px) {
  .hk-fields-v1 input[type=time] {
    min-width: 90px;
  }
  .hk-fields-v1 select[name=ShiftID] {
    min-width: 130px;
  }
}
@media (max-width: 1200px) {
  .hk-fields-v1 .StartTime {
    margin-inline: 0px;
  }
  .hk-fields-v1 .StartTime label {
    margin-left: 0px;
  }
  .hk-fields-v1 .EndTime {
    margin-inline: 0px;
  }
  .hk-fields-v1 .EndTime label {
    margin-left: 0px;
  }
}
/******************************************/
.ym-doc .hk-docs {
  width: -moz-fit-content;
  width: fit-content;
}
.ym-doc .hk-docs .icon-pdf {
  font-size: 60px;
  color: red;
}
.ym-doc .hk-docs .hk-close-btn {
  margin-left: auto;
}
.ym-doc .hk-docs .hk-close-btn i {
  color: #000;
}/*# sourceMappingURL=app.css.map */
