@import '../variables';

.btn{
    font-size: 12px;
    font-family: $font-sb; 
    text-decoration: none;
    border-radius: 50px;
    min-width: 80px;
    padding: 4px;
}
.no-width{
    min-width: auto;
}
.btn-md{
    height: $h-md;
    padding-block: 4px;
    min-width: 125px
}
.btn-sm{
    height: $h-sm;
    padding-top: 4px;
    padding-bottom: 4px;
}
.btn-xs{
    height: $h-xs;
    padding-top: 4px;
    padding-bottom: 4px;
}
.btn-orange{
    background: $orange;
    border-color: $orange;
    color: #fff;
    &:hover{
        background: $orange;
        border-color: $orange;
        color: #fff;
    }
}
.btn-blue{
    background: #32457B;
    border-color: #32457B;
    color: #fff;
    &:hover{
        background: #32457B;
        border-color: #32457B;
        color: #fff;
    }
}
.btn_orange{
    background: #FD8458;
    border-color: #FD8458;
    color: #fff;
    &:hover{
        background: #FD8458;
        border-color: #FD8458;
        color: #fff;
    }
}
.btn-success{
    background: $success;
    border-color: $success;
    color: #fff;
    &:hover{
        background: $success;
        border-color: $success;
        color: #fff;
    }
}
.btn-theme{
    background: $theme;
    border-color: $theme;
    color: #fff;
    &:hover{
        background: $theme;
        border-color: $theme;
        color: #fff;
    }
}
.btn-gray{
    background: #F8FAFB;
    border-color: #E0E0E0;
    color: #616060;
    &:hover{
        background:#F8FAFB ;
        border-color: #E0E0E0;
        color: #616060;
    }
}
.btn-outline{
    background: transparent;
    border-color: #A7A7A7;
    color: $text;
    &:hover{
        background: transparent;
        border-color: #A7A7A7;
        color: #616060;
    }
}
.btn-danger{
    background: $danger;
    border-color: $danger;
    color: #fff;
    &:hover{
        background: $danger;
        border-color: $danger;
        color: #fff;
    }
}
.btn-submit{
    background: #4BD2EA;
    border-color: #4BD2EA;
    color: #fff;
    &:hover{
        background: #4BD2EA;
        border-color: #4BD2EA;
        color: #fff;
    }
}
.btn-view{
    background: #968EF7;
    border-color: #968EF7;
    color: #fff;
    &:hover{
        background: #968EF7;
        border-color: #968EF7;
        color: #fff;
    }
}
.btn-icon-circle{
    width: 25px;
    min-width: 25px;
    height: 25px;
    border: 1px solid #898989;
    background: #E5E5E5;
    color: #326060;
    border-radius: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.btn-icon-transparent{
    width: 25px;
    min-width: 25px;
    height: 25px;
    border: none;
    background: transparent;
    color: #326060;
    border-radius: 50px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    &.active{
        color: $orange;
    }
    // &.btn-xs{
    //     width: $h-xs;
    //     min-width: $h-xs;
    //     height: $h-xs;
    // }
}
.btn-icon-transparent-add{
    width: 23px;
    min-width: 23px;
    height: 23px;
    border: none;
    background: transparent;
    color: #326060;
    border-radius: 50px;
    margin-left: 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-image: url('../../images/add-circle.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  
  
}
.btn-icon-transparent-file-export{
    width: 25px;
    min-width: 25px;
    height: 25px;
    border: none;
    background: transparent;
    color: #326060;
    border-radius: 50px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-image: url('../../images/Icon awesome-file-export.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  
  
}


/*Button Utilities*/
.btns-group>.btn:not(:first-child) {
    margin-left: 10px;  
  }
  
  .btns-group-flex  {
    display: flex;
    gap: 10px;
    row-gap: 5px;
    flex-wrap: wrap;
    .line-border{
        width: 2px;
        background: #959595;
    }
  }
  