// @font-face {
//     font-family: "Inter-regular";
//     src: url("../fonts/Inter/static/Inter-Regular.ttf");
//   }
//   @font-face {
//     font-family: "Inter-medium";
//     src: url("../fonts/Inter/static/Inter-Medium.ttf");
//   }
//   @font-face {
//     font-family: "Inter-semibold";
//     src: url("../fonts/Inter/static/Inter-SemiBold.ttf");
//   }
//   @font-face {
//     font-family: "Inter-bold";
//     src: url("../fonts/Inter/static/Inter-Bold.ttf");
//   }
//   @font-face {
//     font-family: "Inter-light";
//     src: url("../fonts/Inter/static/Inter-Light.ttf");
//   }

@font-face {
    font-family: "Poppins-regular";
    src: url("../fonts/Poppins/Poppins-Regular.ttf");
  }
  @font-face {
    font-family: "Poppins-medium";
    src: url("../fonts/Poppins/Poppins-Medium.ttf");
  }
  @font-face {
    font-family: "Poppins-semibold";
    src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
  }
  @font-face {
    font-family: "Poppins-bold";
    src: url("../fonts/Poppins/Poppins-Bold.ttf");
  }
