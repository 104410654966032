@import "../variables";

.form-group {
  margin-bottom: 9px;
}

.input {
  display: flex;
  flex-direction: column;
  gap: 3px;

  label {
    color: #707070;
    font-size: 12px;
    font-family: $font-r;

    .req {
      color: $danger;
    }
  }

  .form-control {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    font-family: $font-r;
    font-size: 12px;
    color: $text;
    height: $h-lg;
    transition: none;
    appearance: auto;
    padding: 2px 6px;

    &.fc-xs {
      height: 28px;
    }

    &.fc-sm {
      height: $h-sm;
      padding: 0px 15px;
    }

    &.fc-md {
      height: $h-md;
    }

    &.fc-lg {
      height: $h-lg;
    }

    &.fc-xl {
      height: $h-xl;
    }

    &.rounded {
      border-radius: 50px !important;
    }

    &:focus {
      box-shadow: none;
      //   border-color: #7895ff;
    }

    &[disabled] {
      background: #f5f7fc;
      border-color: #f5f7fc;
      color: #333;
    }

    &::placeholder {
      font-family: $font-r;
      font-size: 12px;
      font-style: italic;
      color: #a7a7a7;
    }
  }

  // select.form-control {
  //   -webkit-appearance: none !important;
  //   -moz-appearance: none !important;
  //   appearance: none !important;
  //   background-image: url(../../images/caret.svg) !important;
  //   background-repeat: no-repeat !important;
  //   background-position: 94% 50% !important;
  //   background-size: 12px 12px !important;
  //   width: 100%;
  // }

  textarea.form-control {
    height: auto;
  }

  .input-group {
    position: relative;

    .form-control {
      height: $h-lg;
      // border-color: #959595;
      border-radius: 15px;
      border-right: none;
    }

    .input-group-text {
      background: $orange;
      border-radius: 15px;
      border-color: $orange;
      color: #fff;

      i {}
    }

    &.with-icon-start {
      .form-control {
        padding-left: 30px;
      }

      &::before {
        content: "\F52A";
        font-family: bootstrap-icons !important;
        position: absolute;
        top: 5px;
        left: 10px;
        z-index: 99999;
      }
    }
  }

  &.error {
    // label {
    //   color: #fc0000 !important;
    // }

    .form-control {
      border-color: #fc0000 !important;
    }

    .text-error {
      color: #fc0000;
      font-family: $font-r;
      font-size: 12px !important;
      margin-bottom: 0 !important;
    }
  }

  .MuiFormControl-root {
    .MuiInputBase-root {
      .MuiInputBase-input {
        height: $h-sm !important;
        box-sizing: border-box;
      }

      fieldset {
        height: $h-xl;
        box-sizing: border-box;
        top: 0;
        border-radius: 6px;

        // border-color: #959595;
        legend {
          display: none;
        }
      }
    }
  }
}

.form-check {
  label {
    color: #707070;
    font-size: 12px;
    font-family: $font-r;

    .req {
      color: $danger;
    }
  }

  .form-check-input {
    border: 1px solid #707070;
    border-radius: 2px;
  }

  .form-check-label {
    font-weight: 500;
  }
}

.field-icon {
  position: relative;

  .last {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translate(0px, -50%);
  }
}

.app-mui-select {
  border: 0.5px solid #959595;
  border-right: none;
  border-radius: 6px !important;
  height: $h-xl !important;
  position: relative;
  background: #fff;

  .MuiSelect-select {
    border: none !important;
    font-family: $font-r;
    font-size: 14px;
    color: $text;
    height: 100% !important;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 15px;
  }

  .MuiSelect-icon {
    width: 2.5rem;
    height: 2.5rem;
    z-index: 999;
    fill: #fff;
    right: 0px;
    top: 4px;
  }

  fieldset {
    height: 100%;
    padding: 0;
    border: none !important;
    box-sizing: border-box;
    top: 0;
    position: relative;

    // border-color: #959595;
    legend {
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    background: $orange;
    width: 40px;
    // top: -0.6px;
    // bottom: -0.6px;
    height: 48px;
    right: 0;
    border-radius: 0 12px 12px 0;
  }
}

.app-mui-select-no-ui {
  border: none;
  border-right: none;
  border-radius: 0px !important;
  height: unset !important;
  position: relative;
  background: transparent;
  width: 115px;

  .MuiSelect-select {
    border: none !important;
    font-family: $font-r;
    font-size: 14px;
    color: $text;
    height: 100% !important;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 0;
  }

  .MuiSelect-icon {
    width: 2rem;
    height: 2rem;
    z-index: 999;
    fill: #434343;
    right: 0px;
    top: -5px;
    line-height: 1;
  }

  fieldset {
    height: 100%;
    padding: 0;
    border: none !important;
    box-sizing: border-box;
    top: 0;
    position: relative;

    // border-color: #959595;
    legend {
      display: none;
    }
  }

  // &::after {
  //   content: '';
  //   position: absolute;
  //   background: $orange;
  //   width: 10%;
  //   // top: -0.6px;
  //   // bottom: -0.6px;
  //   height: 48px;
  //   right: 0;
  //   border-radius: 0 12px 12px 0;
  // }
}

.MuiAutocomplete-root {
  border: 1px solid #C6C6C6;
  border-radius: 6px;
  font-family: $font-r;
  font-size: 14px;
  color: #434343;
  height: $h-md;
  transition: none;
  appearance: auto;
  overflow: hidden;

  .MuiFormControl-root {
    padding-left: 10px;
    height: 100%;
    background: #fff;

    .MuiInputBase-root {
      display: flex;
      gap: 5px;
      align-items: center;
      flex-wrap: nowrap;
      overflow: hidden;
      // overflow: overlay;
      height: 100%;
      padding-right: 3px !important;

      &::-webkit-scrollbar {
        height: 2px;
      }

      &::before {
        border: none !important;
      }

      &::after {
        border: none !important;
      }

      .MuiChip-root {
        margin: 0 !important;
      }

      .MuiAutocomplete-endAdornment {
        margin-right: 0;
        top: 0px;
        bottom: 0px;
        border-radius: 0 12px 12px 0;
        right: 0px;
        display: flex;
        align-items: center;

        [class*="popupIndicator"] {
          background: #344777;
          height: 100%;
          border: 1px solid #344777 !important;
          border-radius: 0 5px 5px 0;
          width: 35px;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;

          svg {
            fill: #fff;
            width: 1.5rem;
            height: 1.5rem;
          }

          &.MuiAutocomplete-popupIndicatorOpen {
            transform: rotate(0deg) !important;

            path {
              transform: rotate(180deg);
              transform-origin: center;
            }
          }
        }
      }
    }
  }
}

.css-6od3lo-MuiChip-label {
  padding-right: 6px !important;
  padding-left: 6px !important;
  font-size: 11px !important;
  font-family: $font-m;
}

.MuiChip-deleteIcon {
  font-size: 16px !important;
  color: #32457b !important
}

.css-1q79v3g-MuiButtonBase-root-MuiChip-root {
  height: 18px !important;
  background-color: rgba(194, 210, 250, 31%) !important;
}

.autocomplete-group {
  .input-group {
    display: flex;
    flex-wrap: nowrap;

    .MuiAutocomplete-root {
      flex-grow: 1;
      overflow: hidden;

      &::-webkit-scrollbar {
        height: 2px;
      }

      .MuiFormControl-root {
        overflow: hidden;
        overflow-x: auto;

        &::-webkit-scrollbar {
          height: 2px;
        }

        .MuiInputBase-root {
          // padding-right: 0;
        }
      }

      .MuiAutocomplete-endAdornment {
        position: unset;

        [class*="popupIndicator"] {
          display: none;
        }
      }
    }

  }

  &.autocomplete-group-xs {
    .input-group {
      .MuiAutocomplete-root {
        height: $h-xs;

        .MuiFormControl-root {
          .MuiInputBase-root {
            .MuiChip-root {

              height: 20px;

              .MuiChip-label {
                font-size: 12px;
                padding-left: 10px;
                padding-right: 10px;
              }

              .MuiChip-deleteIconMedium {
                width: 0.6em;
                height: 0.6em;
              }
            }
          }
        }

      }
    }
  }

  &.v2 {
    .input-group-text {
      background: #fff;
      border-color: #959595;
      border-left: none;

      i {
        color: #6F6E6E;
      }
    }
  }
}

/******************************/
.rdrCalendarWrapper {
  overflow: hidden;
  font-size: 10px;

  .rdrDateDisplayWrapper {
    background: transparent;

    .rdrDateDisplay {
      .rdrDateInput {
        box-shadow: none;
        border: 1px solid #959595;
        border-radius: 0 !important;
        color: $text;
        font-family: $font-r;
        font-size: 14px;
      }
    }
  }

  .rdrMonthAndYearWrapper {
    padding-top: 0;
    height: 35px;

    .rdrNextPrevButton {
      background: transparent;

      &::before {
        font-family: bootstrap-icons !important;
        font-weight: bold;
      }

      i {
        display: none;
      }

      &.rdrPprevButton {
        &::before {
          content: '\F284';
        }
      }

      &.rdrNextButton {
        &::before {
          content: '\F285';
        }
      }
    }

    .rdrMonthAndYearPickers {

      .rdrMonthPicker,
      .rdrYearPicker {
        select {
          font-family: $font-r;
          font-size: 12px;
          color: $text;
        }
      }
    }
  }

  .rdrMonths {
    .rdrMonth {
      padding: 0 10px 10px 10px;
      width: 23.667em;

      .rdrMonthName {
        display: none;
      }

      .rdrWeekDays {
        .rdrWeekDay {
          font-family: $font-r;
          font-size: 12px;
          color: $text;
        }
      }

      .rdrDays {
        .rdrDay {
          line-height: unset;
          height: 2.5em;

          .rdrDayStartPreview,
          .rdrDayEndPreview,
          .rdrDayInPreview {
            border: none !important;
          }

          /********************/
          &.rdrDayToday {
            .rdrDayNumber {
              span {
                &:after {
                  background: $orange;
                }
              }
            }
          }

          /********************/
          .rdrSelected {
            background: $orange;
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 0;
          }

          .rdrSelected~.rdrDayNumber {
            span {
              color: #fff;
            }
          }

          /********************/

          &.rdrDayHovered {
            background: #fde7d6;

            .rdrDayStartPreview,
            .rdrDayEndPreview {
              border: none !important;
            }

            .rdrDayNumber {
              span {}

              &:after {
                border: none !important;
              }
            }
          }

          /********************/

          .rdrStartEdge,
          .rdrEndEdge {
            background: $orange;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            border-radius: 0 !important;

            &+.rdrDayNumber {
              span {
                color: #fff !important;
              }
            }
          }

          .rdrInRange {
            background: #fde7d6;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            border-radius: 0 !important;
          }

          /********************/
          .rdrDayNumber {
            span {
              font-family: $font-r;
              font-size: 12px;
              color: $text-light2;
            }
          }
        }
      }
    }
  }
}

.rdrDateRangePickerWrapper {
  overflow: hidden;
  display: inline-flex;
  flex-direction: column-reverse;

  .rdrDefinedRangesWrapper {
    padding: 10px 10px;
    border-top: 1px solid #959595;
    width: 100%;

    .rdrStaticRanges {
      flex-direction: row;
      gap: 5px;

      .rdrStaticRange {
        border: 1px solid #959595;

        .rdrStaticRangeLabel {
          font-family: $font-r;
          font-size: 12px;
          color: $text;
          padding: 6px 10px;
        }

        &.rdrStaticRangeSelected {
          background: $orange;
          border-color: $orange;

          .rdrStaticRangeLabel {
            color: #fff;
          }
        }
      }
    }

    .rdrInputRanges {
      padding: 10px 0;
      display: flex;
      padding-bottom: 0;
      gap: 10px;
      display: none;

      .rdrInputRange {
        padding: 0;

        span {
          font-family: $font-r;
          font-size: 12px;
          color: $text;
          white-space: nowrap;

        }
      }
    }
  }

  .rdrCalendarWrapper {
    border: none;
    border-radius: 0;
  }
}

/******************************/
.calender {
  position: relative;

  .calender-icon {
    position: relative;

    .input-icon {
      position: absolute;
      right: 0.5px;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #6F6E6E;

    }

    .form-control.fc-xs+.input-icon {
      font-size: 12px;
    }

  }

  .rdrDateRangePickerWrapper {}

  .rdrCalendarWrapper:not(.rdrDateRangeWrapper) {

    // width: 300px;
    .rdrDateDisplayWrapper {
      display: none;
    }
  }

  .rdrDateRangeWrapper {
    width: 600px;
  }

  &.transparent {
    .form-control {
      background: transparent;
      border: none;
      padding: 0;
    }
  }
}

.calender-menu {
  .MuiPaper-root {
    box-shadow: none;
    border: 1px solid #959595;
    border-radius: 12px;
    background: #fff;

    .MuiList-root {
      padding: 0;

      .rdrDateDisplayWrapper {
        display: none;
      }
    }
  }
}

/******************************/
.DateRangePicker-Wrapper {
  .rdrDateRangePickerWrapper {
    border: none;
  }

  .DateRangePicker-Action {
    text-align: end;
    display: flex;
    gap: 15px;
    justify-content: end;
    padding: 0 10px 10px 10px;
  }

  .btn {
    min-width: 75px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: center;
  }
}

/******************************/