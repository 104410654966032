body {
    @import "variables";

    /********************************/
    // .text-container {
    //     >* {
    //         margin-bottom: 0px;
    //     }

    //     > :last-of-type {
    //         margin: 0;
    //     }
    // }

    /********************************/


    font-family: 'Poppins', sans-serif;

    .heading {
        color: $text-dark;
        font-size: 12px;
        font-family: $font-sb;
        // font-family: 'Poppins', sans-serif; 
        margin: 0;

        &.xsm {
            font-size: 10px;
        }

        &.sm {
            font-size: 14px;
        }

        &.md {
            font-size: 18px;
        }

        &.lg {
            font-size: 22px;
        }

        &.xl {
            font-size: 24px;
        }
    }

    .gry_heading {
        color: #707070;
        @extend.heading;
    }

    .white_heading {
        color: #FFF;
        @extend.heading;
    }

    .app_border {
        border: 0.2px solid #A7A7A7;
    }

    .bottom_border {
        border-bottom: 0.2px solid #A7A7A7;
    }

    .left_border {
        border-left: 0.2px solid #A7A7A7;
    }

    .right_border {
        border-right: 0.2px solid #A7A7A7;
    }

    .top_border {
        border-top: 0.2px solid #A7A7A7;
    }

    .text {
        color: $text;
        font-size: 12px;
        font-family: $font-r;
        // font-family: 'Montserrat', sans-serif;   
        margin: 0;

        &.sm {
            font-size: 11px;
        }
    }

    .xsm_text {
        font-size: 10px !important;
        @extend .text;
    }

    .text-link {
        color: $theme;
        font-size: 14px;
        cursor: pointer;

        font-family: $font-m;
        margin: 0;

        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }

    .clr_white {
        color: #FFF;
    }

    .clr_gry {
        color: #707070;
    }

    .clr_light {
        color: #A7A7A7 !important;
    }

    .clr-light {
        color: #E0E0E0 !important;
    }

    .clr_blue {
        color: #3D8DEC !important;
    }

    .clr_drk-blue {
        color: #344777 !important;
    }

    .link-text {
        color: rgba(61, 141, 236, 1) !important;
    }

    /********************************/
    .font-r {
        font-family: $font-r;
    }

    .font-m {
        font-family: $font-m !important;
    }

    .font-sb {
        font-family: $font-sb;
    }

    .font-b {
        font-family: $font-b;
    }

    /********************************/
    .clr-text {
        color: $text;
    }

    .clr-muted {
        color: $text-muted;
    }

    .clr-light {
        color: $text-light;
    }

    .clr-light2 {
        color: $text-light2;
    }

    .clr-dark {
        color: $text-dark;
    }

    .clr-theme {
        color: $theme;
    }

    .clr-danger {
        color: $danger;
    }

    .clr-red {
        color: #FF6262 !important;
    }

    .clr-green {
        color: #5AB461 !important;
    }

    .clr-orange {
        color: #FD8458 !important;
    }


    /********************************/
    .bg-light-blue {
        background: $bg-light-blue;
    }

    .bg-light-blue2 {
        background: $bg-light-blue2;
    }

    .bg-orange {
        background: $orange;
    }

    .bg-gray {
        background: $bg-gray;
    }

    .bg-blue {
        background: $blue;
    }
    .bg-pink{
        background: #F7E8FF;
    }
    /********************************/

    .br-none {
        border: none;
    }

    .br-top-1 {
        border-top: 0.5px solid;
    }

    .br-bottom-1 {
        border-bottom: 0.5px solid;
    }

    .ar-br-bottom-1 {
        border-bottom: 0.25px solid;
    }


    .br-right-1 {
        border-right: 0.5px solid;
    }

    .br-left-1 {
        border-left: 0.5px solid;
    }

    .br-clr {
        border-color: $br-clr;
    }

    .br-clr-orange {
        border-color: $orange !important;
    }

    /********************************/

    @mixin font-size($size) {
        .fs-#{$size} {
            font-size: unquote($size+px);
        }
    }

    @include font-size(8);
    @include font-size(10);
    @include font-size(12);
    @include font-size(14);
    @include font-size(16);
    @include font-size(18);
    @include font-size(20);
    @include font-size(22);
    @include font-size(24);
    @include font-size(26);
    @include font-size(28);
    @include font-size(30);
    @include font-size(50);
    @include font-size(70);


    /********************************/

    @mixin gap($gapsize) {
        .gap-#{$gapsize} {
            gap: unquote($gapsize+px);
        }
    }

    @include gap(0);
    @include gap(5);
    @include gap(10);
    @include gap(15);
    @include gap(20);
    @include gap(25);
    @include gap(30);
    @include gap(35);
    @include gap(40);

    .row-gap-5 {
        row-gap: 5px;
    }

    .row-gap-10 {
        row-gap: 10px;
    }

    .row-gap-15 {
        row-gap: 15px;
    }

    .row-gap-30 {
        row-gap: 30px;
    }

    /********************************/

    @mixin font-weight($size) {
        .fw-#{$size} {
            font-weight: $size;
        }
    }

    @include font-weight(400);
    @include font-weight(500);
    @include font-weight(600);
    @include font-weight(700);
    @include font-weight(800);
    @include font-weight(900);



    /********************************/
    @mixin imgsizes($size) {
        &.s-#{$size} {
            width: unquote($size + px);
            min-width: unquote($size + px);
            height: unquote($size + px);
        }
    }

    .img-container {
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        display: block;
        background-size: cover !important;
        background-position: center !important;
        @include imgsizes(20);
        @include imgsizes(25);
        @include imgsizes(30);
        @include imgsizes(35);
        @include imgsizes(40);
        @include imgsizes(45);
        @include imgsizes(50);
        @include imgsizes(55);
        @include imgsizes(60);
        @include imgsizes(65);
        @include imgsizes(70);
        @include imgsizes(75);
        @include imgsizes(77);
        @include imgsizes(80);
        @include imgsizes(85);
        @include imgsizes(90);
        @include imgsizes(95);
        @include imgsizes(100);
        @include imgsizes(120);
        @include imgsizes(150);
        @include imgsizes(175);
        @include imgsizes(200);
        @include imgsizes(250);

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &.circle {
            border-radius: 50% !important;
            // border: 1px solid #fff;
            // box-shadow: 0 0 0 2px #65d3c7;
        }
    }

    /********************************/
    .bi {
        line-height: 1;
    }

    .lh-1 {
        line-height: 1;
    }

    .br-50 {
        border-radius: 50px;
    }

    .vertical-line {
        width: 2px;
        background: $br-clr;
        align-self: stretch;
        border-radius: 50px;
    }

    .w-fit-content {
        width: fit-content;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    /********************************/
    .row-half {
        margin-right: -7.5px;
        margin-left: -7.5px;
    }

    .row-half>.col,
    .row-half>[class*=col-] {
        // padding-right: 5px;
        // padding-left: 5px;

    }

    /********************************/
    @media (min-width: 992px) {
        .col-md-1-5 {
            width: 20%;
        }

        .col-md-2-5 {
            width: 40%;
        }

        .col-md-3-5 {
            width: 60%;
        }

        .col-md-4-5 {
            width: 80%;
        }

        .col-md-5-5 {
            width: 100%;
        }
    }

    @media (min-width: 1200px) {
        .col-lg-1-5 {
            width: 20%;
        }

        .col-lg-2-5 {
            width: 40%;
        }

        .col-lg-3-5 {
            width: 60%;
        }

        .col-lg-4-5 {
            width: 80%;
        }

        .col-lg-5-5 {
            width: 100%;
        }
    }

    /********************************/
    .column-gap-30 {
        margin-right: -20px;
        margin-left: -20px;
    }

    .column-gap-30>.col,
    .column-gap-30>[class*=col-] {
        padding-right: 20px;
        padding-left: 20px;
    }

    /********************************/
    .NPI-R-circle {
        width: 20px;
        height: 20px;
        background: #477EE6;
        border-radius: 50px;
        text-align: center;
        line-height: 20px;
        color: #fff;
        font-family: $font-m !important;
        font-size: 12px;
        cursor: pointer;
    }

    /********************************/
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    // / Track /
    ::-webkit-scrollbar-track {
        background: #E8EAED;
        border-radius: 15px;
    }

    // / Handle /
    ::-webkit-scrollbar-thumb {
        background: #CDCCCC;
        border-radius: 15px;
    }

    // / Handle on hover /
    ::-webkit-scrollbar-thumb:hover {
        background: #CDCCCC;
    }

    select::-webkit-scrollbar-track {
        background: #BFBDBD;
    }
}

button {
    cursor: pointer !important;
}

a {
    cursor: pointer !important;
}

i {
    cursor: pointer !important;
}

.pe-10 {
    padding-right: 10px !important;
}

.me-10 {
    margin-right: 10px !important;
}

.s-265 {
    height: 175px;

    img {
        object-fit: unset !important;
    }
}

.MuiTypography-body1 {
    @extend.heading;
}

.fs-15 {
    font-size: 15px !important;
}