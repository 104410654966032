$slim:80px;
$expanded:250px;
$header-height:60px;
$footer-height:40px;

$ipad:811px;
$ipadmini:769px;
$mobile:601px;

$h-xl:30px;
$h-lg:25px;
$h-md:24px;
$h-sm:22px;
$h-xs:30px;

/*Typography*/
/*Inter*/
$font-r: '';
$font-m: '';
$font-sb: '';
$font-b: ' ';

/*Typography Color*/
$text:#333333;
$text-dark:#333333;

$text-muted:#6F6E6E;
$text-light: #C6C6C6;
$text-light2:#888888;


/*Theme*/
$theme: #344777;
$orange: #FD8458;
$danger: #FC5050;
$blue: #344777;
$success: #4AB595;


$bg-light-blue: #F2F7FF;
$bg-light-blue2: #D6E5FF;
$bg-gray: #F3F2F8;
$br-clr: #E0E0E0;

$font-r: 'Poppins-regular';
$font-m: 'Poppins-medium';
$font-sb: 'Poppins-semibold';
$font-b: 'Poppins-bold';