@import '../public/assets/styles/variables';
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.layout {
  background: $theme;
  height: 100%;

  .sidebar {
    height: 100%;
    background: $theme;
    position: fixed;
    z-index: 99;
    transition: all 0.3s;

    .sidebar-header {
      padding: 16px;
      position: relative;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: center;

      .toggle-sidebar {
        background: #DBDBDB;
        border: none;
        padding: 0;
        border-radius: 50px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -12px;
        top: 37px;

        i {
          font-size: 15px;

          &::before {
            color: $theme !important;
          }
        }
      }

    }

    .menu-list-container {
      // height: calc(100% - 150px);
      // display: flex;
      // flex-direction: column;
      // overflow: hidden;
      // overflow-y: auto;

      .menu-lists {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin: 0;
        padding: 0;
        list-style: none;

        .check_in {
          border-radius: 25px;
          border: 1px solid #FFF;
          background: linear-gradient(230deg, rgba(255, 255, 255, 0.2) 2.11%, rgba(52, 71, 119, 0.47) 102.63%);
          box-shadow: 0px 4px 33px 0px rgba(0, 0, 0, 0.25);

          .icon-Icon-time {
            font-size: 45px;
            cursor: default !important;
          }
        }

        li {
          a {
            text-decoration: none;
            font-size: 14px;
            color: $text-light;
            display: flex;
            align-items: center;
            gap: 12px;
            height: 35px;
            border-left: 4px solid transparent;
            padding: 0 25px;
            font-family: $font-m;

            .angle-right-bottom {
              font-size: 25px;
              transform: rotate(45deg);
              margin-left: auto;
              position: relative;
              right: -15px;
            }

            &:hover,
            &.active {
              color: #fff;
            }

            &.active {
              border-color: #FD8458;
              background: rgba(194, 210, 250, 0.12);
              border-radius: 6px;
            }

            img {
              height: 16px;
              width: 16px;
            }

            i {
              font-size: 16px;
            }

            .icon-Vector-21 {
              font-size: 14px;
            }

            .icon-Icon-ionic-ios-wallet {
              font-size: 14px;
            }

            .icon-setup {
              &::before {
                color: white !important;
              }
            }

            .icon-daily-track {
              font-size: 16px;
            }

            .icon-payroll {
              font-size: 14px;
            }
          }

          &.submenu {
            .sub-menu-list {
              margin: 0 0 0 33px;
              display: none;

              ul {
                position: relative;
                margin: 0;
                padding: 0;
                list-style: none;
                height: 0;
                visibility: hidden;
                opacity: 0;
                transition: all 0.3s;
                border-left: 1px solid #fff;

                li {
                  a {
                    height: 30px;
                    border-radius: 15px;
                    padding: 0 24px;
                    gap: 10px;
                    padding-right: 0;
                    border: none;
                    font-size: 12px;

                    .dot {
                      width: 5px;
                      height: 5px;
                    }

                    &.active {
                      background: rgb(211 211 211 / 53%);
                      border: none;

                    }
                  }
                }
              }
            }

            &.open {
              .sub-menu-list {
                display: block;

                ul {
                  height: auto;
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
          }
        }

        //     &.bottom {
        //       flex-direction: row;
        //       flex-wrap: nowrap;
        //       gap: 5px;
        //       margin-top: auto;
        //       justify-content: space-evenly;
        //       padding-bottom: 15px;
        //       > li {
        //         gap: 10px;
        //         border-right: none !important;
        //         padding: 0 5px;
        //         flex-grow: 1;
        //         width: 50%;
        //         a {
        //           padding: 0;
        //           padding: 0 10px;
        //           border-right: none !important;
        //         }

        //         &.submenu {
        //           .sub-menu-list {
        //             background: #326060;
        //             box-shadow: 0px 3px 6px rgb(0 0 0 / 69%);
        //             border-radius: 14px;
        //             margin: 0;
        // position: absolute;
        // margin-top: -135px;
        // padding: 15px;
        // width: 230px;
        //             ul {

        //               li {

        //                 a {
        //                   padding-left: 15px;
        //                   &.active {
        //                     background: rgb(211 211 211 / 53%);
        //                     border: none;
        //                   }
        //                 }
        //               }
        //             }
        //           }

        //           &.open {
        //             ul {
        //               height: auto;
        //               visibility: visible;
        //               opacity: 1;
        //             }
        //           }
        //         }
        //       }
        //     }
      }
    }
  }

  .app-container {
    background: #FCFCFC;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 50px 0 0 50px;
    transition: all 0.3s;

    // @media (max-width: 1200px) {
    //   margin-left: 0;
    // }

    .header {
      height: 50px;
      min-height: 50px;
      padding: 0rem 5px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #959595;
      box-sizing: border-box;
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 9;

      @media (max-width: 1200px) {
        padding: 0rem 1rem;
      }

      .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
        height: 100%;

        // @media (min-width:$mobile) {
        //   .mobile-header{
        //     display: none;
        //   }
        // }
        .btn-header-toggle {
          background: transparent;
          border: none;
          padding: 0;
          width: 20px;
          height: 20px;
          min-width: 20px;
          font-size: 20px;
          line-height: 1;

          @media (min-width:600px) {
            display: none;
          }
        }
      }

      .form-group {
        &.input {
          position: relative;

          .form-control {
            padding-left: 20px;
            padding-top: 5px;
            border-radius: 15px;
          }

          .icon-search-2 {
            position: absolute;
            top: 7px;
            font-size: 12px;
            left: 6px;

            &::before {
              color: #828282;
            }
          }
        }
      }

      .icon-Notifications {
        font-size: 18px;
      }

      .mode {
        border: 1px solid #4F4F4F;
        border-radius: 15px;
        padding: 1px 5px;
      }

      .user_image {
        width: 38px;
        height: 38px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50px;
          object-fit: cover;
        }
      }

      .icon-down-direction {
        font-size: 8px;

        &::before {
          color: #6E6B7B;
        }
      }
    }

    .app-content {
      // margin-top: $header-height;
      // overflow: hidden;
      // overflow-y: auto;
      // height: calc(100% - 86px);

      .tab-content {
        height: 100%;

        .active {
          height: 100%;
        }
      }

      @media (max-width: 1200px) {
        // .app-content {
        //   padding: 1rem 1rem 1rem;
        // }
      }

      // .app-padding{
      //   overflow: hidden;
      //   overflow: auto;
      // }
    }

    .footer {
      text-align: center;
      position: absolute;
      bottom: 0px;
      padding: 12px;
      width: 100%;
      background-color: white;
    }
  }
}

/*************************/

.desktop {
  .layout {
    .sidebar {
      width: 225px;
    }

    .app-container {
      margin-left: 225px;
    }
  }

  &.sidebar-slim {
    .layout {
      .sidebar {
        width: $slim;

        .sidebar-header {
          .img-container {
            &.s-77 {
              width: 50px;
              min-width: 50px;
              height: 50px;
            }
          }
        }
      }

      .app-container {
        margin-left: $slim;

        .app-content {
          .fix-tabs {
            >ul {
              margin-left: $slim;

            }

            .app-tabs {

              >ul,
              >.nav-tabs {
                margin-left: $slim;
              }

            }

          }

          .fix-tabs-v2 {
            .tabs-header {
              margin-left: $slim;
            }
          }

          .tabs-action-bottom {
            // margin-left: $slim;
            left: 80px;
          }
        }

      }
    }
  }
}

.tablet {
  .layout {
    .sidebar {
      width: $expanded;
    }

    .app-container {
      margin-left: $slim;
    }

  }

  &.sidebar-slim {
    .layout {
      .sidebar {
        width: $slim;
      }

      .app-container {
        margin-left: $slim;

        .app-content {
          .fix-tabs {
            >ul {
              margin-left: $slim;

            }

            .app-tabs {

              >ul,
              .nav-tabs {
                margin-left: $slim;
              }

            }
          }

          .tabs-action-bottom {
            // margin-left: $slim;
          }
        }
      }
    }
  }
}

.mobile {
  .layout {
    .sidebar {
      left: -$expanded;
      width: $expanded;
    }

    .app-container {
      margin-left: 0;
      border-radius: 0;

      .app-content {
        .fix-tabs {
          >ul {
            margin-left: 0;

          }

          .app-tabs {

            >ul,
            .nav-tabs {
              margin-left: 0;
            }

          }
        }

        .tabs-action-bottom {
          margin-left: 0;
        }
      }
    }

  }

  &.sidebar-mobile-open {
    .layout {
      .sidebar {
        left: 0;
      }
    }

  }
}

.sidebar-slim {
  .sidebar {
    .sidebar-header {
      justify-content: center;
      padding-left: 0;
      padding-right: 0;

      .user-view {
        .user-info {
          display: none;
        }
      }

    }

    .menu-list-container {
      .menu-lists {
        &.bottom {
          display: none;
        }

        li {
          a {
            text-align: center;
            justify-content: center;

            i {
              margin: 0 !important;
            }

            span {
              display: none;
            }

            .angle-right-bottom {
              display: none;
            }
          }

          &.submenu {
            .sub-menu-list {
              position: absolute;
              background: #344777;
              box-shadow: 0px 3px 6px #000000AF;
              border-radius: 14px;
              margin-top: -50px;
              left: 45px;
              width: 230px;
              top: 136px;

              ul {
                li {
                  a {
                    justify-content: start;
                  }
                }
              }
            }

            >a {
              position: relative;

              .dot {
                position: absolute;
                right: 7px;
              }

              i.angle-right-bottom {
                display: block;
                position: absolute;
                right: 8px;
                bottom: 6px;
                font-size: 20px;
              }
            }

            &.open {
              .sub-menu-list {
                padding: 15px;
              }
            }
          }
        }

        &.bottom {
          flex-direction: column;
          align-items: center;

          li {
            a {}
          }

          .submenu {
            &.open {
              .sub-menu-list {
                margin-top: -50px;
                left: 77px;
                width: 230px;
              }
            }
          }
        }
      }
    }

  }
}

/*************************/


/******************************************/
.border-2 {
  border-style: solid;
  border-width: 2px;
}

/******************************************/
.app-padding {
  padding: 0.7rem 0.5rem;
}

.action-row {
  padding: 4px 12px 4px 20px;
}

.ar_action-row {
  padding: 10px 12px 10px 16px;
  border-bottom: 0.26px solid #a7a7a7 !important;
}

/******************************************/
.alert-icon {
  position: relative;

  i {
    color: $theme;
    font-size: 16px;
  }

  .count {
    width: 10px;
    height: 10px;
    font-size: 8px;
    text-align: center;
    border-radius: 50px;
    color: #fff;
    position: absolute;
    z-index: 999;
    background: $danger;
    right: -4px;
    top: -4px;
    line-height: 11px;
  }
}

/******************************************/
.user-logo {
  width: 70px;
  height: 35px;

  // background: $theme;
  img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

/******************************************/

.sr-heading {
  font-family: $font-sb;
  font-size: 13px;
  font-weight: 600;
  color: #434343;
  margin: 0;
}

.modal-overflow-hidden {
  .modal-content {
    overflow: hidden;

    .modal-body {
      padding-block: 15px 30px;
    }
  }
}

.circle-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .cs-circle {
    width: 54px;
    height: 54px;
    padding: 10px;
    border: 1px solid #707070;
    border-radius: 50%;
    background-color: #E1C45A;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: $font-sb;
    font-size: 15px;
    letter-spacing: 0px;
    color: #FFFFFF;
  }

  .cs-text {
    font-family: $font-r;
    font-size: 12px;
    color: #434343;
    margin-block: 5px 0;
  }
}

.sr-table {

  .rdg-header-sort-name,
  .rdg-cell {
    font-size: 12px !important;
    letter-spacing: 0px;
    color: #434343;
  }

  .rdg-header-sort-name {
    font-weight: 600;
  }

  .rdg-cell {
    font-weight: 400;
  }

  div[role="row"] {
    div[role="columnheader"] {
      background-color: #FCC1C0;
    }
  }
}

/******************************************/

.hk-dropdown-menu {

  // for dropdown icons
  svg.MuiSvgIcon-root {
    width: 2rem;
    top: 0;
  }

  .MuiInputBase-root {
    width: 100%;
  }
}

.hk-form-fields {
  label {
    font-family: $font-r;
    font-size: 12px;
    letter-spacing: 0px;
    color: #707070;
  }

  .hk-checkbox {
    display: flex;
    align-items: flex-end;

    .input {
      flex-direction: row;

      label {
        font-family: $font-sb;
        font-size: 12px;
        letter-spacing: 0px;
        color: $text;
      }

      input[type="checkbox"] {
        width: 14px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }
}

/******************************************/

.hk-opt-bar {
  .input label {
    font-size: 12px;
    font-family: $font-sb;
    color: #434343;
    margin-left: 15px;
  }
}

/******************************************/

.hk-popper {
  position: absolute;
  top: 60px;
  right: 40px;
  width: 500px;
  height: 92%;
  background: #CECECE;
  z-index: 9000;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease-in-out;

  overflow-y: scroll;

  &.popper-hidden {
    opacity: 0;
    visibility: hidden;
  }

  .hk-TopHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    padding-inline: 5px;

    .icon-Icon-metro-cross {
      font-size: 4.22px;
    }
  }

  .hk-TopHeader+.row {
    width: 100%;
    margin-left: 0;
  }

  .popper-searchbar {
    width: 174px;

    input[type="search"] {
      height: 23px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      padding-bottom: 12px;
    }

    input[type="search"]::placeholder {
      font-family: $font-r;
      font-size: 12px;
      font-style: italic;
      color: $text-dark;
    }

    .input-group-text {
      height: 23px;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;

      .bi-search::before {
        font-size: 12px;
      }
    }
  }

  .input-group-radio {
    display: flex;
    align-items: center;

    label {
      font-family: $font-sb;
      font-size: 12px;
      color: $text-dark;
      margin-right: 5px;
    }

    input {
      margin: 0 5px 0 0;
    }
  }

  /* Popper Tables */
  .popper-t-table {
    padding: 0;
    height: 100%;
    border: 0;

    div[aria-colspan="15"] {
      padding: 0;
      height: fit-content;
    }

    div[aria-colindex="2"]:not(.popper-detailed-table div[aria-colindex="2"]) {
      grid-column: 2/4;
      width: 488px;

      font-family: $font-r;
      font-size: 12px;
      letter-spacing: 0px;
      color: $text-dark;
    }

    div[role="gridcell"]:not(.popper-detailed-table div[role="gridcell"]) {
      border-right: 0;
    }
  }
}

.popper-detailed-table {
  div[role="columnheader"] {
    font-family: $font-sb;
    font-size: 12px;
    color: $text-dark;
  }

  div[role="gridcell"] {
    font-family: $font-r;
    font-size: 12px;
    letter-spacing: 0px;
    color: $text-dark;
  }
}

.hk-lt-popper {
  position: relative;

  .hk-popper {
    top: 40px;
    left: 0;
    width: 531px;
    height: 636px;
    overflow-y: auto;
  }
}

/******************************************/

.hk-tabs {
  .nav-item {
    position: relative;

    .nav-link {
      &::after {
        content: '';
        position: absolute;
        width: 80px;
        height: 4px;
        background: #EA9A4B;
        bottom: 0;
        inset-inline: 0;
        margin-inline: auto;
        opacity: 0;
        visibility: hidden;
      }

      &:hover {
        border-color: transparent;
      }

      &.active {
        border-color: transparent;

        &::after {
          border: 0;
          opacity: 1;
          visibility: visible;
        }

        &:hover {
          border-color: transparent;
        }
      }

      &:not(.nav-link.active):hover::after {
        opacity: 0.2;
        visibility: visible;
      }
    }
  }
}

/******************************************/

.hk-file-picker {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 0.5px solid #959595;
  border-radius: 12px;
  cursor: pointer;

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    opacity: 0;
    cursor: pointer;
  }

  label {
    font-family: $font-r;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0px;
    color: #707070;
  }
}

.input-group-search {
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  height: 25px;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.8rem;
}

.hk-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: -12px;

  .hk-btn {
    padding: 6px 12px;
    margin-inline: 12px;
    background-color: transparent;
    border: 0;
    font-family: $font-m;
    font-size: 12px;
    font-family: $font-m !important;

    &.grn-btn {
      color: $theme;
    }

    &.rd-btn {
      color: #A52A2A;
    }
  }
}

.hk-accordian {
  background: #e5e5e5;
  overflow-x: scroll;

  .MuiAccordionDetails-root {
    padding: 0 !important;
  }
}

.hk-employee-card {
  height: 560px;
  background-color: #F8F9F9;
  border-radius: 0;
  padding: 0;

  div[role="grid"] {
    height: 100%;
    width: fit-content;
    background-color: #FCFCFC;
    border-color: #959595;
    border-left: 0;

    div[role="row"] {

      &:hover {
        div[role="gridcell"] {
          background-color: #CCCCCC !important;
        }
      }

      .rdg-cell {
        font-family: $font-r;
        font-size: 14px !important;
        color: #606060;
        background-color: #F8F9F9 !important;
        border-right: 0;
        border-color: #959595 !important;

        .rdg-header-sort-name {
          font-family: $font-r;
          font-size: 14px !important;
          color: #848484;
        }
      }
    }
  }
}

.hk-doc-block {
  .hk-sd-heading {
    background: #e5e5e5;
    height: 47px;
    min-height: 47px;
    padding: 13px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hk-docs {
    img {
      width: 35px;
    }

    .hk-close-btn {
      position: relative;
      width: 22px;
      height: 22px;
      background-color: #326060;
      margin-bottom: -10px;
      margin-left: -5px;
      z-index: 1;

      &:hover {
        background-color: #569f9f;
      }

      i {
        font-size: 10px;
        color: #fff;
      }
    }

    .icon-pdf {
      color: $danger;
      font-size: 30px;
    }
  }
}

.hk-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
}

.hk-dlt-modal {
  .heading {
    font-family: $font-m;
    font-size: 22px;
    font-family: $font-m !important;
    color: #333232;
  }

  .hk-dlt-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 35px;
    border: 3px solid $danger;
    border-radius: 100%;

    .icon-delete {
      font-size: 45px;
      color: $danger;
    }
  }
}

/******************************************/

.hk-org-card {
  background-color: $bg-gray;
  border: 0.5px solid #707070;
  border-radius: 14px;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 12px;
    bottom: -2px;
    background: #E0E0E0;
    border-radius: 0px 0px 12px 12px;
    transition: 0.3s all;
  }

  &:hover {
    &::after {
      background: #F4C23A;
    }
  }

  &.star-card-active {
    &::after {
      background: #F4C23A;
    }

    .org-card-actions {
      .hk-starbtn {
        .icon-star-full {
          display: block;
        }

        .icon-star-empty {
          display: none;
        }
      }
    }
  }

  .org-card-actions {
    position: absolute;
    width: 100%;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px 0;

    .hk-iconMenu {
      cursor: pointer;
      padding: 20px;
      transition: 0.3s all;

      &:hover {
        background-color: #e3e3e6;
      }
    }

    .hk-starbtn {
      i {
        font-size: 20px;
        font-weight: 700;
        color: #F4C23A;
      }

      .icon-star-full {
        display: none;
      }
    }

    .hk-submenu {
      position: absolute;
      width: 207px;
      left: 95%;
      top: 30px;
      background: $bg-gray;
      border: 0.5px solid #707070;
      border-radius: 9px;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      transition: opacity, 0.3s all;

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          transition: 0.3s all;

          &:hover {
            background: #eaeaea;
          }

          &:not(:last-child) {
            border-bottom: 1px solid #888888;
          }

          a {
            display: block;
            padding: 10px 16px;
            font-family: $font-r;
            font-size: 14px;
            color: #888888;
            text-decoration: none;
            color: #888888;
            cursor: pointer;
          }
        }
      }
    }

    &.active-submenu {
      .hk-submenu {
        opacity: 1;
        visibility: visible;
      }
    }

    &.active-submenu-revert {
      .hk-submenu {
        top: 70px;
        left: 20%;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 24px;

    .org-card-content {
      .heading {
        min-height: 60px;
      }
    }
  }

  .btn.btn-orange {
    background-color: #FDA269;
    height: 30px;

    .bi-arrow-up-right::before {
      font-weight: 700;
    }
  }
}

@media (max-width: 786px) {
  .hk-org-card .org-card-actions .hk-submenu {
    left: 10%;
    top: 70px;
  }
}

/******************************************/

.hk-login {
  overflow: hidden;
  background: linear-gradient(45deg, #2F7BEA, #3a637c);
  height: 100%;

  .row {
    height: 100%;
    min-height: 100%;
  }

  .half {
    height: 100%;
    min-height: 100%;

    .container {
      height: 100%;
      min-height: 100%;
    }

    .bg {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 679px;
        height: 679px;
        object-fit: contain;
      }
    }

    .contents {
      background-color: #FCFCFC;
      width: 50%;
      border-radius: 0px 137px 137px 0px;

      .hk-t-img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 35px;
      }

      .hk-form-t {
        margin-top: 134px;
      }

      .hk-title {
        font-family: $font-sb;
        font-size: 22px;
        line-height: 30px;
        text-align: left;
        color: #32457B;

        margin: 70px 0 0 70px;
        max-width: 395px;
      }

      .heading {
        font-family: $font-sb;
        font-size: 22px;
        line-height: 25px;
        text-align: left;
        color: $text;
      }

      p {
        &.text {
          font-family: $font-r;
          font-size: 15px;
          line-height: 20px;
          text-align: left;
          color: #606060;
          margin-top: 12px;

          &.forget-p {
            font-family: $font-m;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }


      form {
        .input {
          label {
            font-family: $font-sb;
            font-size: 12px;
            line-height: 21.77px;
            color: #606060;
          }

          .forget-input {
            height: 66px;

            &::placeholder {
              font-family: $font-r;
              font-size: 16px;
              color: #707070;
            }
          }
        }

        .form-check {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          min-height: 0;

          .form-check-input {
            height: 10px;
            width: 10px;
            margin-right: 10px;
          }

          .form-check-label {
            font-family: $font-r;
            font-size: 13.6px;
            line-height: 10px;
            color: #606060;
          }
        }

        .forgot-pass {
          font-family: $font-r;
          font-size: 13.6px;
          line-height: 10px;
          text-decoration: none;
          color: #777777;
        }
      }

      .reset-pass-btn {
        width: fit-content !important;
        height: auto;
        padding: 12px 60px;
        margin-inline: auto;
      }

      .btn-blue {
        font-family: $font-sb;
        font-size: 18px;
        background: #334679;
        color: #FFFFFF;
      }

      p.inst-txt {
        font-size: 13.6px;

        a {
          color: #2F7BEA;
          text-decoration: none;
        }
      }
    }
  }

  a {
    cursor: pointer;
    text-decoration: none;

    &.blue {
      color: #2F7BEA;
    }
  }
}

@media (max-width: 1536px) {
  .hk-login {

    &.hk-np-view {
      .half {
        .contents {
          .hk-t-img {
            margin-bottom: 20px;
          }
        }
      }

      .container {
        .col-md-7 {
          min-width: 70%;
        }

        &.mt-5 {
          margin-top: 20px !important;
        }

        .form-group {
          margin-bottom: 10px !important;
        }
      }
    }

    .half {
      .bg {
        img {
          width: 379px;
          height: auto;
        }
      }

      .contents {
        .hk-title {
          margin: 40px 0 35px 20px;
        }

        .hk-form-t {
          margin-top: 70px;
        }

        p.inst-txt {
          margin-top: 30px !important;
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .hk-login {
    overflow: auto;

    .half {
      height: auto;

      .contents {
        width: 100%;
        border-radius: 0;

        .hk-title {
          margin: 0 auto;
          padding-top: 30px;
          padding-inline: 15px;
        }

        .hk-form-t {
          margin-top: 60px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .hk-login {
    .half {
      .bg {
        img {
          width: 179px;
          height: auto;
        }
      }
    }
  }
}

/******************************************/

.hk-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;

  .hk-grid-col {
    height: 100%;
  }
}

@media (max-width: 1536px) {
  .hk-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (max-width: 768px) {
  .hk-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .hk-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

/******************************************/

.hk-fields-v1 {
  .hk-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    background-color: rgba(194, 210, 250, 0.31);
    padding-inline: 16px;
    min-height: 45px;

    .hk-heading {
      display: flex;
      justify-content: center;
      align-items: center;

      h5 {
        font-family: $font-m;
        font-size: 14px;
        line-height: 20px;
        font-family: $font-m !important;
        color: $text;
        margin: 0;
      }

      .circle-word {
        width: 29px;
        height: 29px;
        border-radius: 50px;
        background-color: #80ADBC;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;

        font-family: $font-b;
        font-size: 16px;
        font-weight: 700;
        color: #FFFFFF;
      }
    }

    .hk-top-btn {
      font-size: 12px;
      font-family: $font-m !important;
      color: #FFFFFF;
      background-color: #344777;
      border-radius: 0;
      height: auto;
      min-width: fit-content;
      padding: 3px 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        margin-right: 6px;
        font-size: 9px;
        padding: 4px;
        background: #fff;
        border-radius: 50px;
      }
    }
  }

  .hk-NH-block {
    margin-inline: 0;
    padding: 10px 20px;
    display: flex;
    position: relative;
    justify-content: space-between;
    border-bottom: 1px solid $br-clr;

    .hk-NH-flex {
      display: flex;
      column-gap: 15px;
      align-items: center;
      padding-inline: 0;
    }

    .actions_popup {
      top: 33px;
      right: 12px;
      padding: 3px 0px;
    }

    &:hover {
      background-color: rgba(194, 210, 250, 0.12);
    }
  }
}

.hk-radios {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0;
  margin-inline: -1.2rem;

  .form-check {
    padding-left: 0;
    margin-inline: 1.2rem;

    .form-check-input {
      border-radius: 50px;
      margin-right: 5px;

      &:checked {
        background-image: none;
        outline: 1px solid transparent;
        outline-offset: -2px;
        border: 2px solid #E0E0E0;
        background-color: #32457B;

        +label {
          font-family: $font-sb;
          color: $text;
        }

        &:focus {
          box-shadow: unset !important;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .hk-fields-v1 {
    .hk-top-bar {
      .hk-top-btn {
        font-size: 12px;

        i {
          font-size: 8px;
          padding: 4px;
        }
      }
    }

    .hk-min-h {
      height: 100%;
      overflow-y: scroll;
    }

    .hk-NH-block {
      .hk-NH-flex {
        flex-direction: column;
      }
    }
  }
}

.hk-NH-circle {
  width: 26px;
  height: 26px;
  display: grid;
  place-items: center;
  background: #9F7B65;
  font-family: $font-b;
  font-size: 15px;
  font-weight: 700;
  border-radius: 50px;
  color: #fff !important;
  margin-right: 10px;
}

/******************************************/
.hk-exp-table {
  background: transparent;
  border: unset;

  label {
    display: flex !important;
  }

  div[role="row"] {
    background: #fcfcfc !important;

    &:nth-child(odd) {
      background: #F1F3F9 !important;
    }

    div[role="columnheader"] {
      &.rdg-cell {
        background: #fcfcfc;
        font-size: 13px;
        outline: unset;
        color: #333;
        border-inline-end: unset;
        border-block-end: 1px solid #E0E0E0;
      }
    }

    div[role="gridcell"] {
      &.rdg-cell {
        outline: unset;
        border-inline-end: unset;
        border-block-end: unset;
        overflow: visible;
        font-size: 12px;

        .w-50 {
          i {
            padding-left: 21px;
          }
        }
      }
    }
  }
}

/******************************************/

.hk-password-field {
  position: relative;

  input {
    padding-right: 36px !important;
  }

  .hk-eye-btn {
    position: absolute;
    right: 12px;
    top: 23px;

    i {
      font-size: 15px;
      color: #777777;
    }
  }
}

/******************************************/
// Epandable Table at /patients/add-claims

.hk-expand-table {
  position: relative;
  max-height: 318px;
  overflow-y: scroll;
  overflow-x: scroll;

  table {

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      border: 0.5px solid #959595;
      padding: 5px 10px;
    }

    tr {
      .hk-expandTable-btn {
        cursor: pointer;
      }

      td {
        font-size: 14px;
        padding-inline: 6px;

        &:nth-child(1) {
          border: 0;
        }

        &:nth-child(2) {
          border: 0;
        }
      }

      th {
        min-width: 80px;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:nth-child(1) {
          min-width: 0;
          max-width: 10px;
        }
      }

      input {
        font-size: 14px;
        padding-inline: 6px;
      }

      select {
        font-size: 14px;
        padding-inline: 6px;
        min-width: 114px;
      }

      .hk-table-flex {
        display: flex;
        align-items: center;
        gap: 5px;

        input[variant="outlined"] {
          width: 52px;
          height: 26px;
          background: #FFFFFF;
          border: 0.5px solid #959595;
          border-radius: 5px;
        }

        .MuiAutocomplete-root {
          height: 26px;
          width: 100%;
          border-radius: 5px;

          input {
            max-height: 39px !important;
            min-width: auto !important;
          }
        }

        &.hk-input-groups {
          display: grid !important;
          grid-template-columns: 1fr 1fr 1fr 1fr auto;

          .input-group {
            width: 52px;
            height: 26px;

            .MuiAutocomplete-root {
              border-radius: 5px;
            }
          }
        }

        .input-group-text {
          padding: 5px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;

          i {
            font-size: 13px;
          }
        }

        .MuiButtonBase-root {
          width: 30px !important;
        }
      }

      .calender-icon {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      .hk-datepicker-field {
        width: 100px;
        padding-inline: 0;
      }

      &#hk-expandRow-table {
        transition: 0.3s all;

        table {
          position: relative;

          tr {
            th {
              padding: 10px;
              min-width: 138px;
              background: #D7FAE2;

              &:nth-child(1) {
                min-width: 138px;
                background: #D7FAE2;
              }
            }

            td {
              padding: 10px;

              &:nth-child(1) {
                border: 0.5px solid #959595;
              }

              &:nth-child(2) {
                border: 0.5px solid #959595;
              }
            }
          }

          tbody {
            background: #f2fff6;
          }

          .hk-table-fixedRow {
            position: absolute;
            background: #d7fae2;
            width: 100%;
            bottom: 0px;
            padding: 0px !important;
            border-top: 1px solid #707070 !important;
            border-bottom: 1px solid #707070 !important;

            td {
              min-width: 138px;
              border: 0 !important;
              padding: 6px 12px !important;
              font-family: $font-sb;
              font-family: $font-m !important;
              color: #434343;
            }
          }
        }
      }
    }
  }
}

/******************************************/
.MuiAutocomplete-popper {
  width: 285px !important;
}

/* Hide the default calendar icon */
.hk-date {
  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  &:after {
    content: "\e934";
    font-family: "icomoon" !important;
    color: #4b4a4a;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

/* Hide the default clock icon */
.hk-time {
  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  &:after {
    font-family: "icomoon" !important;
    content: "\e935";
    color: #344777;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 0px;
  }
}

.hk-date-ranger {
  i {
    margin-left: -35px;
  }
}

/******************************************/

.hk-check-modal {
  max-width: 600px;

  .modal-content {

    .modal-body {
      position: relative;
      padding-bottom: 30px;

      &::after {
        content: '';
        position: absolute;
        width: 80%;
        height: 0.5px;
        bottom: 0;
        inset-inline: 0;
        margin-inline: auto;
        background: #959595;
      }
    }

    .hk-check-content {
      display: flex;
      align-items: start;
      justify-content: space-between;
      padding-inline: 20px;

      >div {
        max-width: 50%;
        text-align: left;
      }

      img {
        width: 200px;
        padding-bottom: 35px;
        border-bottom: 0.5px solid #959595;
      }

      h3 {
        font-family: $font-sb;
        font-size: 14px;
        font-family: $font-m !important;
        color: #434343;
        margin-bottom: 0;
      }

      p {
        font-family: $font-r;
        font-size: 12px;
        color: #707070;
        margin-top: 9px;
        padding-right: 40px;
      }

      button {
        margin-bottom: 25px;
        width: 190px;
        height: 39px;
      }

      input {
        margin-top: 10px;
        height: 28px;
      }

      span {
        font-family: $font-sb;
        font-size: 14px;
        color: #434343;
        display: flex;
        align-items: center;

        i {
          font-size: 18px;
          margin-right: 5px;
        }
      }

      label {
        font-family: $font-sb;
        font-size: 12px;
        color: #434343;
      }
    }

    .hk-check-suggestions {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 5px;
      padding-inline: 20px;
      margin-top: 20px;

      h4 {
        font-size: 12px;
        font-weight: 400;
        color: #707070;

        background-color: #F6F6F9;
        border: 0.5px solid #959595;
        padding: 5px 5px;
        margin-bottom: 5px;
        text-align: center;
        cursor: pointer;
      }
    }

    .btn-orange {
      background-color: #FD8458;
      height: 30px;
      min-width: 81px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}


@media (max-width:768px) {
  .hk-check-modal {
    .modal-content {
      .hk-check-content {
        flex-direction: column-reverse;

        >div {
          max-width: 100%;
        }

        img {
          width: 100%;
          margin-bottom: 20px;
        }

        p {
          padding-right: 0;
        }
      }

      .hk-check-suggestions {
        grid-template-columns: 1fr;
      }
    }
  }
}

/******************************************/

.hk-ems-v1 {

  /* For Tabs */
  ul[role="tablist"] {
    display: none;
  }

  div[role="tabpanel"] {
    padding-inline: 0;
  }

  .fade {
    &:not(.show) {
      opacity: 1;
      display: none;
    }
  }

  .icon-filter {
    &::before {
      color: #A7A7A7;
    }
  }

  .hk-attendance-status {
    font-family: $font-sb;
    font-size: 12px;
    color: #FCFCFC;
    padding: 5px 11px;
    height: 28px;
    cursor: default;
  }

  .checkOutBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    height: 21px;
    background-color: white;
    font-size: 18px;
    font-weight: bold;
    color: black;
    cursor: pointer;
    padding-top: 1px;
  }

  .checkoutPopupParent {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .7);
    // opacity:0.5;
    z-index: 1000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .checkoutPopup {
    width: 450px;
    height: 450px;
    border-radius: 18px;
    border: 1px solid gray;
    background-color: #f4f4f4;
    opacity: 1;
    visibility: visible;
  }

  .btnOrange {
    background-color: #EA9A4B;
    color: white;
  }

  .hk-ems-datePicker {
    margin-bottom: 0;

    input {
      background: transparent;
      border: 0px !important;
      width: 291px;
      height: 23px !important;
    }

    i {
      font-size: 12px;
      margin-left: 0;
    }
  }

  .autocomplete-group {
    width: 207px;
    height: 22px;
    background: #FFF;

    .MuiAutocomplete-root {
      border-radius: 15px;

      input {
        &::placeholder {
          font-family: $font-r;
          font-size: 12px;
          color: #A7A7A7;
        }


        &.MuiInputBase-inputAdornedStart {
          &::placeholder {
            opacity: 0;
          }
        }
      }

      .MuiAutocomplete-endAdornment [class*=popupIndicator] {
        background: white !important;

        svg {
          fill: #A7A7A7 !important;
          width: 1rem !important;
          height: 1rem !important;
        }
      }
    }

    .MuiChip-root {
      height: 20px;

      .MuiChip-label {
        padding-inline: 8px;
        font-size: 11px;
        color: #434343;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 12px;
        padding-right: 12px;
        white-space: nowrap;
        max-width: 80px;
      }

      .MuiChip-deleteIcon {
        font-size: 16px;
      }
    }
  }



  .hk-ems-searchBar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-block: 5px;
  }

  .hk-ems-searchBar .btn {
    height: 25px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hk-ems-searchBar .input select {
    border: 0;
    // height: auto;
    padding-right: 24px;
  }

  .hk-timeline-btns {
    display: flex;
    align-items: end;
    background-color: #F3F2F8;

    // a{
    //   text-decoration: none;
    //   position: relative;
    //  font-family: $font-r;
    //   font-size: 13px;
    //   color: #333;
    //   padding: 5px 15px;
    //   border: 0px solid #707070;
    //   transition: 0.3s all;
    //   cursor: pointer;

    //   &:hover{
    //     background-color: #FD8458;
    //     color: #FFFFFF;
    //     border-width: 0.5px;
    //   }

    //   &.btn-active{
    //     background-color: #FD8458;
    //     color: #FFFFFF;
    //     border-width: 0.5px;
    //   }

    //   &:nth-child(2){
    //     &::before{
    //       content: "";
    //       position: absolute;
    //       width: 1px;
    //       height: 21px;
    //       background: #707070;
    //       inset-block: 0;
    //       left: 0;
    //       margin-block: auto;
    //     }

    //     &::after{
    //       content: "";
    //       position: absolute;
    //       width: 1px;
    //       height: 21px;
    //       background: #707070;
    //       inset-block: 0;
    //       right: 0;
    //       margin-block: auto;
    //     }        
    //   }

    // }
  }

  // table horizontal scroll
  table.hk-attendance-table {
    background-color: #FCFCFC;
    width: 100%;

    thead {
      background: #eaeffb;

      th {
        border: 0.5px solid #a7a7a7;
        padding: 2px 5px;
        font-family: $font-sb;
        font-size: 12px;
        color: #434343;
        text-align: center;

        &:nth-child(1) {
          background-color: #eaeffb;
        }

        &:nth-child(2) {
          background-color: #eaeffb;
          text-align: start;
          padding-left: 20px;
        }

        .at-right {
          text-align: right;
        }

        span {
          font-family: $font-r;
          font-size: 12px;
          display: block;
        }
      }
    }

    tbody {
      tr {
        td {
          position: relative;
          padding: 6px;
          border: 0.5px solid #a7a7a7;

          &:nth-child(1) {
            background-color: white;
          }

          &:nth-child(2) {
            background-color: white;
          }

          &:has(.ar_weakly) {
            background: #A7A7A7;
            opacity: 0.5;
          }

          .hk-table-input {
            min-width: 32px;
          }

          &.hk-table-UserCol {
            width: 230px !important;

            div {
              justify-content: start;
              padding-left: 10px;
            }

            .office_name_truncate {
              max-width: 163px !important;
            }
          }

          &.hk-table-MonthCol {
            min-width: auto;

            div {
              justify-content: start;
              padding-left: 10px;
            }
          }

          &:first-child {
            min-width: auto;
            width: auto;
          }

          &:has(div) {
            background-color: white;
          }

          >div {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          img {
            width: 30px;
            height: 30px;
            border-radius: 50px;
          }

          // .heading {
          //   font-weight: 400;
          // }

          .hk-tabel-status {
            position: relative;
            width: 25px;
            height: 25px;
            margin-inline: auto;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-sb;
            font-size: 12px;
            color: #FFFFFF;
            cursor: default;

            .astrik-circle {
              position: absolute;
              bottom: -3px;
              top: auto;
              right: -4px;
              height: 12px;
              width: 12px;
              background: #FF2C2C;
              color: #fff;
              display: flex;
              justify-content: center;
              border-radius: 50px;
              font-size: 12px;
              padding-left: 0.5px;
            }

            &.outline {
              .astrik-circle {
                top: -6px;
                bottom: auto;
              }
            }
          }


          .table-infobox {
            position: absolute;
            transform: translatex(-20px);
            display: flex;
            flex-direction: column;
            align-items: stretch !important;
            width: 177px;
            top: 36px;
            background: #4A4A4A;
            border-radius: 6px;
            z-index: 2;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s all;

            &__arrow {
              width: 0;
              height: 0;
              border-bottom: 15px solid #4A4A4A;
              border-left: 15px solid transparent;
              margin-top: -10px;
              margin-left: 30px;
            }

            &__header {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 7px 0;
              border-bottom: 1px solid #959595;

              .hk-tabel-status {
                width: 13px !important;
                height: 13px !important;
                font-size: 9px !important;
                margin-inline: 0 !important;
                margin-right: 10px !important;
              }

              span {
                font-family: $font-m;
                font-size: 9px;
                font-weight: 400;
                color: #ffffff;
              }
            }

            &__body {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              padding: 10px;

              &__item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                &:not(:last-child) {
                  margin-bottom: 6px;
                }

                span {
                  font-family: $font-m;
                  font-size: 9px;
                  font-weight: 400;
                  color: #ffffff;

                  i {
                    font-size: 8px;
                    margin-right: 3px;

                    &::before {
                      color: inherit;
                    }
                  }
                }

                .infobox__time {
                  font-family: $font-m;
                  font-size: 9px;
                  font-weight: 400;
                  color: #ffffff;
                  width: 55px;
                  padding-left: 7px;
                  border-left: 1px solid white;
                }
              }
            }

            &:hover {
              opacity: 1;
              visibility: visible;
            }

            &.flip {
              // transform: translatex(-30px) scaleY(-1);
              backface-visibility: hidden;
              will-change: transform;
              transform: translateX(-20px) scaleX(1.3) scaleY(-1.3) translateZ(0);
              top: -135px;

              .table-infobox__header {
                transform: scaleY(-1);
                border-bottom: 0;
                border-top: 1px solid #959595;
              }

              .table-infobox__body {
                transform: scaleY(-1);
              }
            }

            &.flipX {
              transform: translatex(-120px) scaleX(-1.3) scaleY(1.3) translateZ(0);
              backface-visibility: hidden;
              will-change: transform;
              top: 60px;

              .table-infobox__header {
                transform: scaleX(-1);
                border-bottom: 0;
                border-top: 1px solid #959595;
              }

              .table-infobox__body {
                transform: scaleX(-1);
              }
            }

            &.flipXY {
              transform: translatex(-120px) scaleX(-1.3) scaleY(-1.3) translateZ(0);
              top: -130px;
              backface-visibility: hidden;
              will-change: transform;

              .table-infobox__header {
                transform: scaleY(-1) scaleX(-1);
                border-bottom: 0;
                border-top: 1px solid #959595;
              }

              .table-infobox__body {
                transform: scaleY(-1) scaleX(-1);
              }
            }
          }

          .hk-tabel-status:hover+.table-infobox {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  .hk-attendance-controller {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #F3F2F8;
    min-height: 64px;
    padding: 6px;
    border-bottom: 1px solid #959595;

    .hk-controller-btn {
      background: #E5E5E5;
      box-shadow: 0px 3px 6px #00000029;
      border: 0;
      outline: 0;
      border-radius: 7px;
      padding: 3px 8px;

      i {
        font-size: 12px;
      }
    }

    .hk-controller-status {
      background: #E5E5E5;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 7px;
      padding: 6px 15px;

      font-family: $font-sb;
      font-size: 12px;
      color: #434343;
    }
  }

  .green-bg {
    background-color: #5AB461;

    &.outline {
      background-color: #fff;
      border: 3px solid #5AB461;
      color: #5AB461 !important;
    }
  }

  .pink-bg {
    background-color: #F59AE3;

    &.outline {
      background-color: #fff;
      border: 3px solid #F59AE3;
      color: #F59AE3 !important;
    }
  }

  .brown-bg {
    background-color: #9F7B65;

    &.outline {
      background-color: #fff;
      border: 3px solid #9F7B65;
      color: #9F7B65 !important;
    }
  }

  .gray-bg {
    background: rgba(191, 191, 191);

    .icon-check-circle {
      &::before {
        color: #80ADBC;
      }
    }

    &.outline {
      background-color: #fff;
      border: 3px solid #BFBFBF;
      color: #BFBFBF !important;
    }
  }

  .gray-bgc {
    background: rgba(191, 191, 191, 0.13);
  }

  .liteBlue-bg {
    background-color: #80ADBC;

    &.outline {
      background-color: #fff;
      border: 3px solid #80ADBC;
      color: #80ADBC !important;
    }
  }

  .Blue-bg {
    background: rgba(3, 1, 128, 0.6);

    &.outline {
      background-color: #fff;
      border: 3px solid #80ADBC;
      color: #80ADBC !important;
    }
  }

  .litePink-bg {
    background-color: #FC9E9E;

    &.outline {
      background-color: #fff;
      border: 3px solid #FC9E9E;
      color: #FC9E9E !important;
    }
  }

  .liteOrnage-bg {
    background-color: #EBA889;

    &.outline {
      background-color: #fff;
      border: 3px solid #EBA889;
      color: #EBA889 !important;
    }
  }

  .liteRed-bg {
    background-color: #fc7171;

    &.outline {
      background-color: #fff;
      border: 3px solid #EBA889;
      color: #EBA889 !important;
    }
  }



  // Tabular View Screen
  .hk-btnContainer {
    font-size: 14px;
    display: flex;
    align-items: center;

    .btn {
      background: #344777;
      color: #fff;
      margin-inline: 15px;
      border-radius: 6px;
      min-width: 106px;
      height: 35px;
    }
  }

  .hk-tabView-innerbtns {
    border: 0;
    margin-left: 20px;

    .nav-item {
      border: 0;
      padding-inline: 15px;
      border-left: 1px solid #959595;

      &:last-child {
        padding-right: 0;
      }

      .nav-link {
        &.active {
          font-family: $font-r;
          background: #FD8458;
          border: 1px solid #707070;
        }
      }
    }
  }
}

.MuiAutocomplete-popper li {
  font-size: 12px;
  color: #434343;
}

/******************************************/

.hk-EA {
  .action-row {
    padding: 1px 20px;
  }

  .hk-ems-datePicker {
    width: fit-content;

    span {
      font-family: $font-r;
      font-size: 20px;

      color: #707070;
      margin-right: 8px;
    }

    input {
      width: fit-content;
    }
  }

  .hk-EA-table {
    padding: 30px;
    background: #F3F2F8;
    overflow: scroll;

    table {
      width: 100%;

      th {
        font-family: $font-m;
        font-size: 18px;
        color: #575757;
        padding-right: 32px;

        div {
          padding-bottom: 8px;
          border-bottom: 1px solid #959595;
        }
      }

      td {
        padding: 20px 37px 20px 0;
        height: 100px;
        width: 142px;

        >div {
          display: flex;
          justify-content: space-between;
          height: 100%;

          &:has(> p + .EA-status) {
            flex-direction: column;
          }
        }

        p {
          font-family: $font-m;
          font-size: 18px;

          color: #A7A7A7;
          cursor: pointer;
        }

        a {
          font-family: $font-m;
          font-size: 18px;

          color: #344777;
          padding-left: 6px;
        }

        .EA-status {
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 5px 10px;
          margin-top: 16px;

          font-family: $font-m;
          font-size: 16px;

          color: #A7A7A7;

          &.present {
            background: #DEEDBB;
          }

          &.absent {
            background: #FEC9C9;
          }
        }
      }
    }
  }

  .hk-EA-TopBar {
    display: flex;
    align-items: center;
    justify-content: start;
    background: #344777;
    padding: 8px 14px;

    span {
      font-family: $font-sb;
      font-size: 15px;
      color: #FFFFFF;
      margin-left: 6px;
    }
  }
}

.icon-certificate-icon {
  font-size: 26px;
  margin-right: 10px;
}

/******************************************/

.hk-Editor-modal {
  max-width: 1131px;

  .modal-body {
    padding: 0 !important;
  }
}

.hk-EmailFormat-box {

  .hk-EmailFormat-Editor {
    // padding-bottom: 15px;
    // padding-left: 15px;

    .ql-container {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;

      .ql-editor {
        background-color: #fff;
        height: 290px;
        border-radius: 10px;
      }
    }

    .ql-toolbar {
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      .ql-formats {
        margin-right: 0;
      }

      button {
        background-color: #fff !important;
        height: 30px !important;
        padding: 5px !important;
        width: 30px !important;
        border: 1px solid #ccc !important;
      }

      .ql-picker {
        background-color: #fff;
        border: 1px solid #ccc;
      }
    }
  }
}

.hk-EmailFormat-Tags {
  padding: 40px 10px 0px;
  border-top-right-radius: 10px;

  .chips {
    font-size: 12px;
    font-weight: 400;
    color: #333;
    background-color: #FFFFFF;
    border: 1px solid #E0E0E0;
    padding: 5px 8px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s all;

    &.active {
      background-color: #344777;
      color: #FFFFFF;
    }

    &:hover {
      background-color: #344777;
      color: #FFFFFF;
    }
  }
}

@media (max-width: 786px) {
  .hk-EmailFormat-box {
    .hk-EmailFormat-Editor {
      padding-bottom: 10px;
    }
  }

  .hk-Editorchips-stack {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    gap: 10px;
  }
}

// Attendance Screen Style
.hk-Stats-sidebar {
  border: 1px solid #E0E0E0;
  background: #FFF;

  &__header {
    background: #344777;
    padding: 4px 10px;

    .heading {
      font-family: $font-sb;
      font-size: 13px;
      color: #FFFFFF;
    }
  }

  &__content {
    padding: 12px 14px;
    overflow-y: auto;
    height: calc(100vh - 241px);

    &__item {

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .title {
        font-family: $font-sb;
        font-size: 12px;
        margin: 0;
        color: #333;
      }

      input {
        font-family: $font-sb;
        font-size: 15px;
        height: 30px;
        width: 100%;
        background: transparent;
        color: #707070;
        border: 1px solid #344777;
        border-radius: 6px;
        padding-inline: 10px;
      }

      &__Stat {
        display: grid;
        grid-template-columns: 1fr 1fr;

        div {
          display: flex;
          flex-direction: column;

          span {

            &.count {
              font-family: $font-sb;
              font-size: 12px;
              text-align: center;
              font-family: $font-m !important;
              padding-block: 5px;
              border-bottom-right-radius: 50px;
              border-top-right-radius: 50px;
            }

            &:not(span.count) {
              font-family: $font-sb;
              font-size: 11px;
              color: #707070;
              margin-bottom: 2px;
              margin-left: 13px;
            }
          }

          &:nth-child(1) {
            margin-right: -15px;
            z-index: 1;

            span.count {
              border-top-left-radius: 50px;
              border-bottom-left-radius: 50px;
            }
          }
        }
      }
    }
  }

  .bg-yel {
    background: #FDDF9C;
    color: #333;
  }

  .bg-org {
    background: #FD8458;
    color: #fff;
  }

  .bg-grn {
    background: #549F4E;
    color: #fff;
  }

  .bg-red {
    background: #FC5E5E;
    color: #fff;
  }

  .bg-prp {
    background: #D79DF5;
    color: #fff;
  }

  .bg-tea {
    background: #44BFC4;
    color: #fff;
  }

  .bg-blu {
    background: #78A8F4;
    color: #fff;
  }

  .bg-gry {
    background-color: #BFBFBF;
    color: #fff;
  }
}

// Stats Table
.hk-Stats-table {
  width: 100%;

  th {
    background: #e7e6ec;
    font-family: $font-sb;
    font-size: 14px;
    color: #434343;
    padding-block: 10px 10px;
    padding-inline: 8px;
  }

  td {
    font-size: 12px;
    font-family: $font-sb;
    color: #434343;
    padding-block: 10px 8px;
    padding-inline: 10px;
  }

  &,
  th,
  td {
    border: 1px solid rgb(149 149 149 / 50%);
  }

  tr:nth-child(even) {
    background: #f0f1f7af;
  }
}

// Weekly View Screen
.weeklyview-screen {

  .weekly-statusbar {
    padding-block: 15px;
    padding-inline: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;

    &__tiles {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;

      &__pill {
        display: flex;
        align-items: center;

        position: relative;
        background: #F3F2F8;
        border: 1px solid hsla(0, 1%, 84%, 0.5);
        border-radius: 10px;
        padding: 6px 14px 6px 14px;

        font-family: $font-sb;
        font-size: 12px;

        &::before {
          content: "";
          position: relative;
          top: 0;
          bottom: 0;
          right: 5px;
          width: 8px;
          height: 8px;
          border-radius: 50px;
          background-color: #fff;
        }

        &.available {
          color: #549F4E;

          &::before {
            background-color: #549F4E;
          }
        }

        &.absent {
          color: #FF7777;

          &::before {
            background-color: #FF7777;
          }
        }

        &.weekend {
          color: #707070;

          &::before {
            background-color: #707070;
          }
        }

        &.shortbreak {
          color: #EFBF20;

          &::before {
            background-color: #EFBF20;
          }
        }

        &.leave {
          color: #D79DF5;

          &::before {
            background-color: #D79DF5;
          }
        }

        &.holiday {
          color: #78A8F4;

          &::before {
            background-color: #78A8F4;
          }
        }

        &.latecount {
          color: #EBA889;

          &::before {
            background-color: #EBA889;
          }
        }
      }
    }

    &__checkInStatus {
      background: #4FB557;
      border: 1px solid hsla(0, 1%, 84%, 0.5);
      border-radius: 11px;

      font-family: $font-b;
      font-size: 16px;
      color: #FFFFFF;
      padding: 9px 25px;
    }
  }

  .listview-content {

    &__header {
      display: grid;
      padding-bottom: 8px;
      border-bottom: 0.5px solid #A7A7A7;

      &__left {
        grid-column: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;

        span {
          font-family: $font-sb;
          font-size: 13px;
          color: #333;
        }
      }

      &__right {
        grid-column: 12/15;
        display: grid;
        grid-template-columns: 1fr 1fr;

        span {
          font-family: $font-sb;
          font-size: 13px;
          color: #333;
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      padding-block: 13px;
      padding-inline: 6px;

      .statprogress__row {
        display: grid;
        grid-template-columns: repeat(14, 1fr);
        align-items: center;

        &__left {
          grid-column: 1/4;
          display: grid;
          grid-template-columns: 1fr 1fr;

          span {
            font-family: $font-r;
            font-size: 13px;
            color: #333;
            font-family: $font-m !important;

            &.check_in {
              color: #5AB461;
            }
          }
        }

        &__right {
          grid-column: 10/15;
          display: flex;
          justify-content: space-around;

          span {
            font-family: $font-r;
            font-size: 13px;
            color: #333;
            font-family: $font-m !important;

            &.check_out {
              color: #FF6262;
            }
          }
        }

        &__main {
          grid-column: 4/10;

          .WorkProgress-container {
            width: 95%;
            height: 4px;
            background-color: #BFBFBF;

            &__bar {
              height: 100%;
              position: relative;

              &__fill {
                position: absolute;
                left: 10%;
                right: 30%;
                top: 0;
                bottom: 0;
                margin-block: auto;
                border-radius: 3px;
                height: 3px;
                background-color: green;
                transition: left 0.5s ease-in-out, right 0.5s ease-in-out;
              }

              &__weekend {
                position: absolute;
                width: fit-content;
                height: 24px;
                top: 0;
                inset-block: 0;
                inset-inline: 0;
                margin-inline: auto;
                padding-inline: 15px;
                transform: translateY(-11px);
                display: flex;
                align-items: center;
                background: #BFBFBF;
                border: 1px solid #BFBFBF;
                font-family: $font-sb;
                font-size: 12px;
                color: #707070;

                // &::before{
                //   content: "";
                //   position: relative;
                //   top: 0;
                //   bottom: 0;
                //   right: 5px;
                //   width: 8px;
                //   height: 8px;
                //   border-radius: 50px;
                //   background-color: #707070;
                // }
              }
            }
          }
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    &__footer {
      display: grid;
      grid-template-columns: repeat(14, 1fr);
      align-items: center;

      &__middle {
        grid-column: 3/14;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        align-items: center;
        background: rgba(194, 210, 250, 0.12);
        margin-right: 38px;
        z-index: 9;

        span {
          font-family: $font-sb;
          font-size: 12px;
          color: #707070;
        }
      }
    }
  }
}

/******************************************/

.rbc-date-cell {
  z-index: -1;
}

// React Big Calendar Custom Style
.hk-EA-calender {
  padding-inline: 6px;

  .rbc-calendar {
    position: relative;
    z-index: 1;
    height: unset !important;
    margin-bottom: unset !important;
  }

  .rbc-month-view {
    border: 0;

    .rbc-month-row:last-child {
      .rbc-day-bg {
        border-bottom: 0;
      }
    }
  }

  .rbc-row-segment {
    .rbc-event {
      background-color: transparent;
      cursor: default !important;

      .EA-status {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 4px 5px;
        margin-top: 5px;
        font-family: $font-sb;
        font-size: 12px;
        font-family: $font-m !important;
        color: #333;
        width: 85%;

        &.present {
          background: #DEEDBB;
        }

        &.absent {
          background: #FEC9C9;
        }
      }
    }
  }

  .rbc-toolbar {
    // display: none;
    font-size: 14px;
    margin-block: 10px;
    flex-direction: row-reverse;

    .rbc-toolbar-label {
      font-family: 'Poppins-medium';
      font-size: 12px;
      text-align: start !important;
      color: #4F4F4F;
      margin-left: 120px;

    }

    .rbc-btn-group {
      border-radius: 25px;

      button {
        border-radius: 25px;
      }
    }
  }

  .rbc-toolbar button:hover {
    background-color: #FD8458;
    color: white;
    font-weight: 600;
  }

  .rbc-day-bg {
    background: transparent;
    border-color: #959595 !important;
  }

  .rbc-header {
    position: relative;
    font-family: $font-sb;
    font-size: 12px;
    text-align: left;
    color: #333;
    border: unset;

    span[role="columnheader"] {
      padding-inline: 10px;
    }
  }

  .rbc-date-cell {
    text-align: left;
    padding: 10px 20px;
    padding-bottom: 0px;
  }

  .rbc-header+.rbc-header {
    border-left: 0 !important;
  }

  .rbc-row {
    font-size: 14px;
  }

  .rbc-month-header {
    background: rgba(194, 210, 250, 0.31);
    padding: 10px 0;
  }

  .rbc-event-content {
    a {
      font-family: $font-sb;
      font-size: 12px;
      font-family: $font-m !important;
      color: #333;
      padding-left: 5px;
    }
  }
}

/******************************************/

.hk-filter-panel {
  height: 100% !important;
  top: 0 !important;
  bottom: 0 !important;
}

// Stylize Toastify Notification

.Toastify__toast-container {
  width: fit-content !important;

  .Toastify__toast {
    min-height: 46px;

    .hk-toast {
      font-family: $font-m;
      font-size: 14px;
      font-family: $font-m !important;
      color: #373736;

      i {
        margin-right: 10px;

        &.icon-delete {
          color: #ff0000;
        }
      }
    }

    .Toastify__close-button {
      color: #363636;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  .Toastify__toast--success {
    background-color: #C4EDCD !important;
  }

  .Toastify__toast--warning {
    background-color: #F8EFBE !important;
  }

  .Toastify__toast--error {
    background-color: #ffc6c6 !important;
  }
}

@media (max-width: 1607px) {
  .hk-ems-v1 {
    .autocomplete-group {

      width: 156px;

      width: 200px;

    }
  }
}

@media (max-width: 1300px) {
  .hk-fields-v1 {
    input[type="time"] {
      min-width: 90px;
    }
  }
}

/******************************************/

// modal style update

.hk-modal-v1 {

  .modal-content {
    border-radius: 24px;
  }

  .bi-trash-fill {
    color: #ff0000;
    transition: 0.3s all;

    &:hover {
      color: #fd7474;
    }
  }

  .modal-body {
    padding: 15px 20px !important;

    label {
      font-size: 12px;
    }

    input {
      font-size: 12px;
      padding: 2px 7px;
    }

    .MuiAutocomplete-root {
      width: 100%;
      height: 25px;
      border-radius: 4px;

      button.MuiButtonBase-root {
        width: 30px !important;
      }
    }

    .MuiInputBase-root {
      height: 25px !important;
      border-radius: 4px !important;

      div[aria-haspopup="listbox"] {
        font-size: 12px;
      }

      .MuiButtonBase-root {
        font-size: 12px;
        border-radius: 0 4px 4px 0 !important;
      }

      svg.MuiSvgIcon-root {
        height: 25px;
      }

      &.app-mui-select {
        &::after {
          height: 25px;
          border-radius: 0 4px 4px 0;
        }
      }
    }

    .MuiAccordionSummary-root {
      height: 25px;
      min-height: 25px;

      .heading {
        font-size: 12px;
      }
    }

    .MuiAccordion-root:not(:last-of-type) {
      margin-bottom: 10px;
    }

    .input {
      margin-bottom: 12px;
      gap: 0;

      &:last-of-type {
        margin-bottom: 0;
      }

      .NPI-R-circle {
        width: 17px;
        height: 17px;
      }
    }

    .form-control {
      height: 25px;
      border-radius: 4px;
    }

    +.btns-group {
      padding-top: 0.4rem !important;
      padding-bottom: 0.4rem !important;
      display: flex;
      justify-content: end;
      align-items: center;

      button {
        font-size: 12px;
        min-width: 80px;
        height: 28px;
        padding: 5px;
      }
    }
  }

  &.hk-dropdown-menu {
    .form-control {
      height: 18px;
    }

    .MuiCollapse-root {
      label {
        font-size: 9px;
      }
    }
  }
}

@media (max-width: 440px) {
  .hk-modal-v1 {

    .form-col.w-35,
    .accordian-col.w-65 {
      flex: 0 0 100% !important;
      border-right: 0;
    }

    .accordian-col.w-65 {
      margin-top: 20px;
    }
  }
}

// Card style update
.hk-card-v1 {
  &.app-padding {
    padding: 0.5rem 1rem;
  }

  .card-body {
    padding: 5px;

    .heading {
      color: #434343;
      font-family: $font-sb;
      font-size: 12px;
      margin: 0;
    }

    hr {
      border: 0;
      border-top: 1px solid;
      color: inherit;
      margin: 0.5rem 0;
      opacity: .25;
    }

    .btn {
      border-radius: 50px;
      font-family: $font-sb;
      font-size: 12px;
      height: 30px;
      min-width: 100px;
      text-decoration: none;
      padding: 5px 10px;
    }
  }
}

// sidebar menu update

.hk-sidebar-v1 {
  .menu-lists.top {
    gap: 10px !important;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
}

/******************************************/
.hk-link {
  cursor: pointer;
}

.submenu {

  >a {
    cursor: pointer;
  }

  .sub-menu-list {
    a {
      cursor: pointer;
    }
  }
}


// Table tabs style update
.hk-tabView-btns {
  justify-content: center;
  background: #FFFFFF;
  width: fit-content;
  border: 1px solid #E0E0E0 !important;
  padding: 4px 13px;
  margin-inline: auto;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 23px;
  border: 0;
  gap: 20px;


  .nav-item {
    border: 0;

    .nav-link {
      border-radius: 20px;
      padding: 2px 15px;
      font-size: 14px;
      color: #333;
      transition: 0.3s all;

      &.active {
        color: #FFFFFF;
        font-family: $font-sb;
        background-color: #FD8458;
      }

      &:hover {
        isolation: unset;
        border-color: #FFFFFF;
      }
    }
  }
}

.all_employee {
  .app-datagrid {
    height: calc(100vh - 216px) !important;
  }

  .MuiDataGrid-virtualScroller {
    height: calc(100vh - 262px) !important;
  }
}

.product,
.vendor,
.stock {
  .app-datagrid {
    height: calc(100vh - 276px) !important;
  }
}

.fix-tabs {
  ul {
    margin-left: 213px;
  }

  .app-tabs {
    ul {
      margin-left: 213px;
    }
  }
}

// .tabs-action-bottom{
//   // margin-left: 213px;
// }
.fix-tabs-v2 {
  .tabs-header {
    margin-left: 213px;
  }
}

.add_new_edu {
  height: 100vh;
}

.add_new_office,
.add_new_shift,
.add_new_orgination {
  .MuiAccordionSummary-root {
    min-height: 30px !important;
    height: 30px !important;
  }
}

.add_new_shift {
  .form-control {
    // padding: 2px !important;
  }

  .MuiAccordionDetails-root {
    padding: 6px 15px !important;
  }
}

.add_new_orgination {
  max-width: 750px;

  .emp-profile-img {
    &:hover {
      .bi-camera {
        display: block;
      }
    }

    .img-container.s-150 {
      width: 75px !important;
      min-width: 75px !important;
      height: 75px !important;
      margin: auto;
      position: relative;
    }

    .bi-camera {
      position: absolute;
      top: 26%;
      display: none;
      left: 40%;
      color: white;
    }
  }
}

.log_in_btn {
  height: unset;
}

.all_office {
  .app-datagrid {
    height: calc(100vh - 276px) !important;
  }
}

.leave_type {
  .app-datagrid {
    height: calc(100vh - 226px) !important;
  }
}

.expense {
  .app-datagrid {
    height: calc(100vh - 205px) !important;
  }
}

.cat_name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
  white-space: nowrap;
}

.rbc-row {
  align-items: center;
}

.remark_truncate {
  max-width: 220px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.name_truncate {
  max-width: 110px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.office_name_truncate {

  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pro_name {
  padding-right: 30px !important;
}

.app_border {
  border: 0.5px solid #959595;
}

.dashboard_screen {
  overflow-y: auto;
  height: calc(100vh - 108px);
  overflow-x: hidden;

  .dashboard {
    .orange {
      color: #FD8458 !important;
    }

    .blue-clr {
      color: #344777 !important;
    }

    .ar_teams_hiring {
      padding: 17px 40px 17px 16px !important;
    }

    .ar_team_member {
      padding: 12px 16px 25px 16px !important;
      height: 233px;

      .ar_content {
        padding: 10px 8px;
        border-radius: 6px;
        border: 0.5px solid #E0E0E0;
        background: #FFF;

        .border-2 {
          border: 3px solid #E0E0E0;
        }
      }
    }

    .ar_new_request {
      padding: 0 !important;
      padding-top: 15px !important;

      .validation_message {
        max-width: 111px;
        white-space: nowrap;
      }

      .pee-10 {
        padding-inline: 10px !important;
      }

      .ar_teams-content {
        max-height: 105px;
        overflow-y: auto;
        overflow-x: hidden;

        .mb-3 {
          &:nth-last-child(1) {
            margin-bottom: 0 !important;
          }
        }
      }

      .img-tooltip {
        &.team-fst {
          .tool_tip {
            right: 0;
            left: -3px;
          }
        }

        .img-container {
          &:hover {
            +.tool_tip {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }

    .new_request {
      width: 310px;
      padding: 15px 0px !important;

      .scroll {
        height: 180px;
        overflow: hidden;
        overflow-y: auto;

        &.ar_scroll {
          height: 163px;
        }
      }

      .aprroval-box {
        border-radius: 8px;
        border: 1px solid #E0E0E0;
        background: #FFF;
        padding: 10px 8px;

        .deny {
          color: #FF6262;
          border-radius: 4px;
          padding: 3px 21px;
          background: rgba(255, 98, 98, 0.10);
        }

        .icon-x-circle {
          &::before {
            color: #FF6262;
          }
        }

        .approve {
          color: #5AB461;
          border-radius: 4px;
          padding: 3px 21px;
          background: rgba(90, 180, 97, 0.10);
        }

        .icon-check-circle1 {
          &::before {
            color: #5AB461;
          }
        }

      }

      .validation_message {
        width: 111px;
        white-space: nowrap;
      }

      .top-head {
        padding-bottom: 14px;
        border-bottom: 0.26px solid #A7A7A7;
        margin-bottom: 22px;
      }

      .grey {
        color: #4F4F4F !important;
      }
    }

    .calender-pop {
      .img_bgc {
        background-color: #c0c6d4;
        border-radius: 13px;
        height: 200px;
      }

      .content {
        padding: 22px 19px;
        background-color: #fff;
        z-index: 9;
        position: absolute;
        top: 162px;

        .icons_head {
          .box {
            background-color: #e6e6e6;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;

            i {
              color: #4F4F4F;
            }
          }
        }

        .ar_co-box {
          padding: 3px 10px;
          background-color: rgba(194, 210, 250, 25%);
        }
      }
    }

    .celebration_pop {
      padding: 12px !important;
      padding-right: unset !important;

      .br_bottom {
        border-bottom: 0.2px solid #A7A7A7;
      }

      .date-text {
        background: rgba(194, 210, 250, 0.12);
        padding: 1px 8px;

        .text {
          color: #32457B !important;
        }
      }

      .content {
        height: 201px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 12px;
      }
    }

    .view_report {
      padding: 13px 16px !important;
    }

    .attendence {
      padding: 0 29px 0 0 !important;
      overflow: hidden;

      .btn {
        min-width: 100%;
      }

      img {
        margin-left: -50px;
        margin-bottom: -3px;
      }
    }

    @media screen and (max-width: 1240px) {
      .card_tabs {
        &.attendence {
          padding-right: 4px !important;

          .fs-14 {
            font-size: 12px;
          }

          .fs-20 {
            font-size: 16px;
          }
        }

        &.ar_dashborad {
          padding-inline: 12px 0 !important;

          .ps-2 {
            padding-left: 0 !important;
          }

          .fs-20 {
            font-size: 14px;
          }

          .pb-3 {
            padding-bottom: 8px !important;
          }

          .active_time-bar {
            gap: 5px !important;
          }
        }

        &.za-leave-balance {
          .fs-20 {
            font-size: 16px;
          }

          .apexcharts-legend-text {
            font-size: 9px !important;
          }
        }

        &.attendence_overview {
          .apexcharts-text tspan {
            font-size: 10px !important;
          }
        }
      }

    }

    .attendence_overview {
      padding: 16px 18px !important;
      height: 273px;
    }

    .overlay_act {
      padding: 12px 15px !important;
      height: 100%;
    }

    .payroll_sum {
      height: 102px;
      padding: 9px 16px;
    }

    .card_tabs {
      padding: 13px 13px 0px 13px;
      border: 1px solid #E0E0E0;
      border-radius: 14px;
      background-color: #fff;

      .input select.form-control {
        background-position: 92% 50% !important;
      }

      .sun {
        color: #f2c94c;
      }

      .btn {
        position: relative;

        i {
          vertical-align: middle;
        }

        .overly {
          position: absolute;
          top: -1px;
          width: 103%;
          height: 108%;
          left: -1px;
          border: 1px solid #e7d2ca;
          border-radius: 6px;
          opacity: 0.8;
          background: #e7d2ca;
        }
      }

      .app_border {
        height: 25px;
        margin-top: 10px;
      }

      .more_btn {
        width: 18px;
        height: 18px;
        margin: auto;
        margin-right: 0;
        background-color: #D9D9D9;
        border-radius: 50px;
        justify-content: center;
        display: flex;
        align-items: center;

        i {
          font-size: 9px;
          margin: 1px 0px 0px 2px;

          &::before {
            color: #fd8458;
          }
        }
      }

      .total_leaves {
        background: #5AB461;
        width: 36px;
        height: 36px;
        display: flex;
        border-radius: 50px;
        margin: auto;
        justify-content: center;
        align-items: center;

        &.heading {
          color: #ffffff;
        }
      }

      .leaves_used {
        background: #FFCA87;
        width: 36px;
        margin: auto;
        height: 36px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.heading {
          color: #ffffff;
        }
      }

      .announs {
        padding: 7px 12px;
        border-radius: 13px;
        background: rgba(194, 210, 250, 0.12);

        .heading {
          color: #606060;
        }

        .text {
          color: #707070;
        }
      }

      &.leave_section {
        .text {
          &.xsm {
            color: #707070;
          }
        }
      }
    }
  }

  .active_employe {
    padding: 15px 14px 10px 11px !important;
    padding-bottom: 0 !important;
    height: 100%;

    .red {
      color: #ff5252 !important;
      gap: 6px;

      i {
        &::before {
          color: #ff5252 !important;
        }
      }
    }

    .green {
      color: #6fcf97 !important;
      gap: 6px;

      i {
        &::before {
          color: #6fcf97 !important;
        }
      }
    }
  }

  .employe_stetus {
    padding: 17px 0px 0px 12px !important;
    height: 279px;

    .line {
      border-bottom: 0.26px solid #A7A7A7;
      margin: 12px 10px;

    }

    .scroll {
      padding-right: 12px;
      padding-bottom: 17px;
      height: 100%;
      overflow-y: auto;
    }
  }

  .ar-sm-card {
    padding: 4px 12px !important;
    border-radius: 8px !important;
  }

  .box {
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }

  .Permanent {
    background-color: #FD8458;
  }

  .Consultant {
    background-color: #E04CFF;
  }

  .PartTime {
    background-color: #724CFB;
  }

  .Contract {
    background-color: #FF6262;
  }

  .Intern {
    background-color: #4391ED;
  }
}

.PayrollSummary {
  padding: 15px 12px !important;
  height: 300px;
}

.employe_hiring {
  padding: 9px 16px !important;

  .designation {
    max-width: 86px;
  }
}

.new_hiring {
  padding: 15px 0px 0px 16px !important;
  overflow: hidden;

  .content {
    height: 209px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 12px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    .actions_popup {
      width: auto;
      right: 5px;
    }
  }

  .hired-box {
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    padding: 10px 8px;

    .validation_message {
      max-width: 157px;
    }

    .icon {
      i {
        &::before {
          color: #707070;
        }
      }
    }
  }
}

.gender-diversity {
  height: 240px;
  padding: 15px 16px !important;
}

// .whosOff_pop {
//   padding: 15px 16px !important;
//   width: 310px !important;

//   .header-txt {
//     border-bottom: 0.26px solid #A7A7A7;
//     padding-bottom: 14px;

//     .box {
//       width: 4px;
//       height: 4px;
//       background-color: #FD8458;
//       border-radius: 50%;
//     }
//   }
// }

.basic_info {
  margin-top: 10px;

  .no_data {
    top: 60%;
    left: 59%;
  }

  &.ar_leave_screen {
    height: calc(100vh - 149px) !important;
    overflow-y: auto !important;
    margin-top: 10px !important;

    .no_data {
      top: 65%;
    }
  }

  &.leave_screen {
    height: calc(100vh - 105px);

    .leave_right {
      .content {
        padding: 18px 7px;
        border: 1px solid #E0E0E0;
        background: rgba(194, 210, 250, 0.12);

        .heading {
          line-height: normal;
        }

        .left_border {
          border-left: 6px solid #FD8458;
          height: 30px;
        }

        .pending {
          .heading {
            &.num {
              color: #5AB461;
              font-size: 20px;
            }
          }
        }

        .used {
          .heading {
            &.num {
              color: #FC9E9E;
              font-size: 20px;
            }
          }
        }

        .due {
          .heading {
            &.num {
              color: #80ADBC;
              font-size: 20px;
            }
          }
        }
      }

      .ins {
        background-color: #5AB461;
        padding: 3px 10px;
        color: #FFFFFF;
        margin: auto;
        width: fit-content;
        border-radius: 15px;
      }

      .intern {
        p {
          background-color: #DEEDBB;
        }
      }

      .deduct {
        color: #FF6262;
      }

      .recevied {
        color: #5AB461;
      }

      .status {
        background-color: #DEEDBB;
        padding: 1px 8px;
        border-radius: 15px;
        margin: auto;
        width: fit-content;
      }

      .month {
        &::before {
          border: 5px solid #bbdfbe;
          border-radius: 50px;
          content: "";
          height: 15px;
          left: 3px;
          position: absolute;
          top: 2px;
          width: 15px;
        }

        &::after {
          background-color: #5ab461;
          content: "";
          height: 26px;
          left: 10px;
          position: absolute;
          top: 17px;
          width: 2px;
        }

        &.completed {
          &::before {
            border: 5px solid #5ab461;
          }
        }
      }
    }
  }

  .col-md-3 {
    .loading {
      transform: translateY(-10%);
      top: unset;
      left: unset;
      bottom: 20%;
      right: 2%;
      position: absolute;
    }

    .content {
      border-radius: 8px;
      border: 1px solid #E0E0E0;
      background: #FFF;
      padding: 15px 10px;

      .ar_employee-info {
        overflow-x: hidden;
        overflow-y: auto;
        height: calc(100vh - 470px);
        padding-right: 10px;
        padding-bottom: 4px;
      }

      .range {
        background-color: #f3f2f8;
        border-radius: 15px;
        height: 12px;

        .fill {
          height: 12px;
          background-color: #5AB461;
          border-radius: 15px;
        }
      }

      .heading {
        &.precentage {
          font-family: $font-m !important;
          color: #5AB461;
        }
      }

      .hk-imgHandler {
        // &:hover + .upload-emp-img{
        //   display: block;
        // }
        border: 2px solid #E0E0E0;
      }

      .edit {
        position: absolute;
        cursor: pointer;
        right: 4px;
        top: 8px;
        background-color: #d0d0cd;
        border-radius: 50px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-edit-pencil {
          font-size: 11px;

          &::before {
            color: #344777;
          }
        }
      }

      .skill_content {
        .add_icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          opacity: 0;
          visibility: hidden;
          border-radius: 50px;
          border: 1px solid #344777;

          i {
            &.icon-plus-icon {
              font-size: 10px;

              &::before {
                color: #344777;
              }
            }
          }
        }

        &:hover {
          .add_icon {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .skills {
        height: 24px;

        .icons {
          display: none;

          .icon-edit-pencil {
            &::before {
              color: #4F4F4F;
            }
          }
        }

        &:hover {
          .icons {
            display: block;
          }
        }

        .btn {
          min-width: max-content !important;
        }
      }

      .skill-0 {
        background-color: #FE528C;
        color: #FFFFFF;
      }

      .skill-1 {
        background-color: #467FFF;
        color: #FFFFFF;
      }

      .skill-2 {
        background-color: #F2C94C;
        color: #FFFFFF;
      }

      .skill-3 {
        background-color: #344777;
        color: #FFFFFF;
      }

      .skill-4 {
        background-color: #28C76F;
        color: #FFFFFF;
      }
    }
  }

  // .upload-emp-img{
  //   top: 36%;
  //   position: absolute;
  //   display: none;
  //   label{
  //     color: white;
  //   }
  // }
  .MuiPaper-rounded {
    border-radius: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .MuiButtonBase-root {
    min-height: 36px;
    background: rgba(194, 210, 250, 0.31);

    .MuiAccordionSummary-content {
      margin: 0;
      cursor: default;
    }
  }

  .MuiButtonBase-root {
    min-height: 30px !important;
    padding-top: 4px;
    margin: 0 !important;
  }

  .MuiAccordionDetails-root {
    background-color: #FCFCFC;
  }

  .current_status {
    .MuiButtonBase-root {
      background: #344777;
    }
  }
}

.doc {
  color: #344777;
  background: rgba(194, 210, 250, 0.31);
  font-size: 14px !important;
  font-family: $font-sb;
}

.academics_screen {
  .education {
    .card_header {
      background: #FFF;
      border-left: 4px solid #49902A;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      span {
        width: 35px;
        height: 35px;
        display: inline-block;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #959595;
        background-color: #d5ffc3;

        i {
          cursor: default !important;
        }

        .icon-Icon-awesome-graduation-cap {
          font-size: 13px;

          &::before {
            color: #49902A;
          }
        }
      }
    }

    .card_content {
      background: #FFF;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      .status {
        width: fit-content;
        background: rgba(213, 255, 195, 0.72);
      }

      .status_content {
        height: 29px;

        .icons {
          display: none;

          .icon-Group-3994 {
            &::before {
              color: red;
            }
          }
        }
      }

      .content {
        &:hover {
          .icons {
            display: block;
          }
        }
      }
    }

    &.certification {
      .card_header {
        border-left: 4px solid #75A1B7;

        span {
          background-color: #d5f0ff;

          .icon-Icon-awesome-certificate {
            font-size: 13px;

            &::before {
              color: #75A1B7;
            }
          }
        }
      }

      .card_content {
        .status {
          width: fit-content;
          background: #e1f4ff;
        }
      }
    }

    &.experience {
      .card_header {
        border-left: 4px solid #BFAA68;

        span {
          i {
            font-style: normal;
          }

          background-color:#f0dfa9;
        }
      }

      .card_content {
        .status {
          width: fit-content;
          background: #f0dfa9;
        }
      }
    }

    &.skills {
      .card_header {
        border-left: 4px solid #9BA4C5;

        span {
          background-color: #e3e8f9;

          .icon-Icon-material-work {
            &::before {
              color: #9BA4C5;
            }
          }
        }
      }

      .card_content {
        .status {
          width: fit-content;
          background: #e3e8f9;
        }
      }
    }
  }
}

.pic_edit {
  position: absolute;
  width: 221px;
  top: 30px;
  background-color: #FFFFFF;
  right: 40px;
  border-radius: 6px;
  border: 1px solid #E0E0E0;
  z-index: 9;

  .icon-Icon-metro-cross {
    font-size: 8px;

    &::before {
      color: #707070;
    }
  }

  .del_item {
    color: #FF6262;
  }

  .hk-imgHandler {
    border: unset !important;
  }
}

.education_popup {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 328px;
  border-radius: 13px;
  border: 1px solid #E0E0E0;
  background: #FCFCFC;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 15px 14px;

  &.filter_popup {
    top: 42px;
    left: unset;
    right: 4px;
    transform: unset;
  }

  [type="file"]::-webkit-file-upload-button {
    display: none;
  }

  img {
    position: absolute;
    right: 15px;
  }

  .icon {
    height: 48px;
    margin: auto;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #344777;
    border-radius: 10px;
    border: 1px solid #C6C6C6;
    box-shadow: (0px 1px 2px rgba(16, 24, 40, 0.05));

    i {
      cursor: default !important;
    }

    .icon-Icon-awesome-graduation-cap {
      &::before {
        color: #FFFFFF;
      }
    }

    .certification_icon {
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #959595;
      border-radius: 50px;
      background-color: #c2c8d6;
    }

    .icon-Icon-material-work,
    .icon-Icon-ionic-md-document,
    .icon-Icon-awesome-certificate {
      &::before {
        color: #344777;
      }
    }

    .icon-Group-2694-1 {
      font-size: 15px;
      margin-right: 2px;
    }

    .path1 {
      &::before {
        color: #344777;
      }
    }

    .path2,
    .path3,
    .path4 {
      &::before {
        color: #c2c8d6;
      }
    }
  }

  .icon-Icon-metro-attachment {
    position: absolute;
    top: 26px;
    left: 5px;

    &::before {
      color: #A7A7A7;
    }
  }
}

.counter {
  background: #FD8458;
  border-radius: 15px;
  padding: 1px 8px;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-sb;
}

.employee_header {
  border: 0.26px solid #A7A7A7;
  background: rgba(194, 210, 250, 0.12);
  border-top: unset;

  span {

    // border-bottom: 4px solid #FD8458;
    // position: relative;
    // top: 1px;
    &.cross {
      background-color: #FD8458;
      width: 18px;
      height: 18px;
      display: inline-flex;
      border-radius: 50px;
      justify-content: center;
      align-items: center;
      padding-right: 1px;

      .icon-Icon-metro-cross {
        font-size: 8px;
        margin-left: 1px;

        &::before {
          color: #FFFFFF;
        }
      }
    }
  }

  .nav {
    &.nav-tabs {
      gap: 10px;
      border: unset;
    }
  }

  .nav-link {
    padding: 0;
    background: transparent;
    border: unset;
    display: flex;
    column-gap: 5px;
    align-items: center;

    .heading {
      font-family: $font-r !important;
    }

    &.active {
      background: transparent;
      border-bottom: 6px solid #FD8458;
      padding-bottom: 2px;

      .heading {
        font-family: $font-sb !important;
      }
    }
  }
}

.employee_screen {
  .att_summary {
    border-radius: 4px;
    background: #c9ffc8;
    width: 322px;
    padding: 5px 10px;

    i {
      font-size: 12px;

      &::before {
        color: #4F4F4F;
      }
    }
  }

  .gross_pay {
    border-radius: 4px;
    background: #FFF5C8;
    width: 250px;
    padding-inline: 5px 10px;

    i {
      font-size: 12px;

      &::before {
        color: #4F4F4F;
      }
    }
  }

  .deduction {
    border-radius: 4px;
    background: #CBFFFC;
    width: 200px;
    padding: 5px 10px;

    i {
      font-size: 12px;

      &::before {
        color: #4F4F4F;
      }
    }
  }

  .net-salery {
    border-radius: 4px;
    background: rgba(230, 76, 255, 0.18);
    width: 140px;
    padding: 5px 10px;

    i {
      font-size: 12px;

      &::before {
        color: #4F4F4F;
      }
    }
  }

  .icon-table-view,
  .icon-card-view,
  .icon-filter {
    &::before {
      color: #A7A7A7;
    }

    &.active {
      &::before {
        color: #FD8458;
      }
    }
  }

  .input-group-text {
    padding: 0 0.75rem;

    p {
      color: #FFFFFF;
      font-family: $font-m !important;
    }
  }

  .bottom_border {
    border-bottom: 0.26px solid #A7A7A7 !important;
  }
}

.actions_popup {
  position: absolute;
  top: 15px;
  z-index: 9;
  width: 92px;
  border-radius: 1px;
  border: 1px solid #E0E0E0;
  background: #FCFCFC;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  p {
    &:hover {
      background: rgba(194, 210, 250, 0.31);
      font-weight: 600;
    }

    .icon-edit-pencil {
      font-size: 11px;

      &::before {
        color: #344777;
      }
    }

    .icon-Group-3994 {
      font-size: 12px;

      &::before {
        color: #FF6262;
      }
    }
  }
}

.document_grid {
  .icon-arrow-right {
    &::before {
      color: #606060;
    }
  }
}

.employee_screen {
  .education_popup {
    top: 30px;
    transform: unset;
    left: unset;
    right: 10px;
    width: 280px;
    z-index: 9;
  }

  .card_content {
    border-radius: 15px;
    position: relative;
    border: 1px solid #E0E0E0;
    background: #F8FAFB;
    padding: 15px 10px;
    height: 100%;

    .icon-dots-menu {
      position: absolute;
      right: 10px;
      top: 20px;
      font-size: 6px;

      &::before {
        color: #707070;
      }
    }

    .img-container {
      img {
        border: 3px solid #E0E0E0;
        border-radius: 50px;
      }

      .status {
        width: 10px;
        display: block;
        height: 10px;
        border-radius: 50px;
        background-color: #5AB461;
        position: relative;
        left: 60px;
        top: -18px;
      }
    }

    .inner_content {
      border-radius: 15px;
      background: #FFF;
      padding: 12px 14px;
    }

    .actions_popup {
      right: 6px;
      top: 32px;
      width: 101px;
    }
  }
}

.data_grid {
  .MuiDataGrid-columnHeader {
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;
  }

  .MuiDataGrid-virtualScroller {
    // margin-top: 30px !important;
  }
}

.ems_modal {
  .modal-content {
    border-radius: 13px;
    border: 1px solid #E0E0E0;
    background: #FCFCFC;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .close {
      position: absolute;
      right: 15px;
      cursor: pointer;
    }

    .modal_header {
      .icon {
        height: 48px;
        margin: auto;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #344777;
        border-radius: 10px;
        border: 1px solid #C6C6C6;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

        i {
          cursor: default !important;
        }

        .icon_bg {
          height: 32px;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #959595;
          border-radius: 50px;
          background-color: #fff;

          .icon-expense {

            .path1,
            .path2 {
              &::before {
                color: #344777;
              }
            }

            .path3 {
              &::before {
                color: #fff;
              }
            }
          }

          .icon-Vector-1 {
            &::before {
              color: #344777;
            }
          }
        }
      }
    }

    .modal-body {
      padding: 15px 15px 5px 15px;
    }

    .modal_footer {
      padding: 8px 15px 15px 15px;
    }
  }
}

.custom_check_box {
  input[type="checkbox"] {
    width: 14px;
    min-width: 14px;
    height: 14px !important;
    border-radius: 2px;
    margin-top: 4px;
    box-shadow: unset;

    &:checked[type=checkbox] {
      background-color: #344777;
      accent-color: #344777;
      border-color: #344777;
      position: relative;
      border: unset !important;
    }
  }

  label {
    vertical-align: text-bottom;
  }
}

.expense_type {
  width: 247px;
}

.tabs-expenses {
  border: 0.26px solid #A7A7A7;
  border-top: 0;
  background: rgba(194, 210, 250, 0.12);
}

.setup_tabs {
  .rb-tabs {
    border: 0.26px solid #A7A7A7;
    border-top: 0;
    background: rgba(194, 210, 250, 0.12);

    li {
      .nav-link {
        border-bottom: unset;

        &.active {
          font-family: $font-sb;
        }
      }
    }
  }
}

.inner_tabs {
  .rb-tabs-v2 {
    height: 30px;
    min-height: 30px;

    li {
      .nav-link {
        border-bottom: unset;
        border-right: 0.5px solid #959595;

        &.active {
          font-family: $font-sb;
          color: #FFFFFF;
          background-color: #FD8458;
          border: 0;
        }
      }
    }
  }
}

.basic_info {
  .upload_image {
    width: 100px;
    height: 100px;
    border: 2px solid #E0E0E0;

    img {
      object-fit: unset !important;
    }
  }

  .edit_image {
    background-color: #344777;
    display: flex;
    align-items: center;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 0px;
    bottom: 11px;
    border-radius: 50px;
    justify-content: center;

    .icon-edit-pencil {
      font-size: 10px;

      &::before {
        color: #FFFFFF;
      }
    }
  }

  .left_side {
    position: relative;

    .pic_edit {
      right: unset;
    }
  }

  .right_said {
    border-left: .25px solid #A7A7A7;
  }

  .ar_row {
    height: calc(100vh - 212px) !important;
    overflow-y: auto;
  }
}

.app-accordian {
  .MuiAccordion-root {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    &:last-child {
      margin-bottom: 10px;
    }

    &::before {
      display: none;
    }

    box-shadow: none;
    border: none;
    margin-top: 0 !important;

    .MuiAccordionSummary-root {
      background-color: rgba(194, 210, 250, 0.31);
      height: 30px;
      min-height: 30px;
      padding: 13px 15px;
    }

    .MuiCollapse-root {
      .MuiAccordionDetails-root {
        padding: 15px;
      }
    }
  }
}

.office_grid,
.shift_grid,
.department_grid,
.team_grid,
.benefit_type_grid,
.file_grid {
  height: calc(100vh - 233px);
  overflow-y: auto;
}

.all_employee_grid {
  height: calc(100vh - 217px);
  overflow-y: auto;
}

.manage_employee_grid {
  height: calc(100vh - 205px);
  overflow-y: auto;
}

.role_and_right {
  height: calc(100vh - 235px);
  overflow-y: auto;
}

.add_new_office,
.add_new_shift,
.add_new_leave,
.add_benfit_type,
.add_new_file,
.add_new_des,
.add_new_vendor,
.add_new_team,
.add_new_dep,
.add_new_product {
  .modal-content {
    .modal_header {
      .icon {
        .icon-plus-icon {
          &::before {
            color: #344777;
          }
        }
      }
    }

    .app-accordian {
      .MuiAccordion-root {
        .MuiCollapse-root {
          .MuiAccordionDetails-root {
            background-color: #FCFCFC;
          }
        }
      }
    }
  }
}

.add_new_dep,
.add_new_team,
.add_new_leave,
.add_benfit_type,
.add_new_file,
.add_new_product,
.add_new_des {
  width: 328px;

  .MuiAutocomplete-root {
    max-width: unset;
  }
}

.calendar_event {
  height: calc(100vh - 216px);
  overflow-y: auto;
}

.clander_modal {
  width: 750px;
}

.apply_leave {
  width: 330px;
}

.add_prog {
  max-width: 510px;
}

.view_leave_policy {
  --bs-modal-width: 660px;

  .modal-content {
    background-color: #F5F5F5;
    padding-bottom: 15px;
    border: 1px solid #F5F5F5;
  }

  .icon-Group-482426 {
    &::before {
      color: #667085 !important;
    }
  }
}

.payslip {
  width: 800px;
  max-width: unset;

  .modal-content {
    background-color: #F5F5F5 !important;
  }

  .ar_content {
    padding: 20px 17px 15px 17px;
    margin-top: 27px;
  }

  .cards {
    border-radius: 15px;
    border: 0.5px solid #E0E0E0;

    .cards_content {
      padding: 10px 16px;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      background: rgba(90, 180, 97, 0.21);

      .left_border {
        border-left: 5px solid #5ab461;
      }
    }

    .total {
      background: rgba(194, 210, 250, 0.12);
      padding: 9px 0px;
    }

    .net_payment {
      background: rgba(90, 180, 97, 0.21);
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
}

.time_adj {
  height: calc(100vh - 225px);

  .adj_req {
    color: #344777;
    background: rgba(194, 210, 250, 0.31);
    width: fit-content;
  }

  .ar_employee-info {
    height: calc(100vh - 526px) !important;
  }
}

.time_adj_grid {
  height: calc(100vh - 243px) !important;
  max-height: unset !important;
}

.adjust_time {
  width: 328px;
  max-width: unset;

  .icon-Vector-4 {
    font-size: 20px;
  }
}

.list_view {
  &.basic_info {
    height: unset;

    .col-md-3 {
      .content {
        height: calc(100vh - 185px);
        overflow-y: auto;
      }
    }

    .weeklyview-screen {
      .listview-content__body {
        height: calc(100vh - 241px);
        overflow-y: auto;
      }
    }
  }

  .status {
    width: 8px;
    height: 8px;
    display: inline-block;
    margin-right: 5px;

    &.present {
      background-color: #5AB461;
    }

    &.absent {
      background-color: #FC9E9E;
    }

    &.late_count {
      background-color: #EBA889;
    }

    &.holiday {
      background-color: #80ADBC;
    }

    &.weekend {
      background-color: #BFBFBF;
    }

    &.leave {
      background-color: #D79DF5;
    }
  }
}

.input {
  select {
    &.form-control {
      border-radius: 6px;
      background-color: #fff;
      border: 0.2px solid #E0E0E0;
      appearance: none !important;
      background-image: url(./assetsOld/img/caret.svg) !important;
      background-position: 98% 50% !important;
      background-repeat: no-repeat !important;
      background-size: 12px 12px !important;
      padding-right: 24px !important;
      transition: all .3s;
      width: 100%;
    }
  }
}

input[type="time"]::-webkit-calendar-picker-indicator {
  // filter: invert(48%) sepia(13%) saturate(307%) hue-rotate(130deg) brightness(95%) contrast(80%);
  background-image: url(./assetsOld/img/clock_icon.svg) !important;
  background-repeat: no-repeat !important;
  background-position: 98% 50% !important;
  background-size: 12px 12px !important;
  transition: 0.3s all;
}

.empolyee_attendance {
  .hk-timeline-btns {
    border-radius: 20px;
    background: #F5F5F5;
  }

  .nav-tabs {
    .nav-link {
      &:hover {
        background-color: unset;
        border-radius: unset;
        isolation: unset;
      }

      &.active {
        border-radius: 20px;
        background-color: #FD8458;
        font-family: $font-sb;
        color: #FFFFFF;
      }
    }
  }

  .employee_screen {
    width: 290px;
  }

  .hk-ems-searchBar {
    .Weekly {
      border-radius: 6px;
      border: 0.26px solid #E0E0E0;
      background: #F5F5F5;

      p {
        display: flex;
        padding: 5px;
        column-gap: 8px;

        .month-name {
          width: 100px;
          text-align: center;
        }

        span {
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          background-color: rgba(0, 0, 0, 0.31);

          i {
            &::before {
              color: #FFFFFF;
            }
          }

          .icon-angle-left {
            margin-right: 3px;
          }

          .icon-right-direction {
            margin-left: 3px;
          }
        }
      }
    }
  }
}

.tabs_view4 {
  border: unset;

  .nav-item {
    .nav-link {
      padding: 0;
      border: unset !important;

      &:hover {
        border: unset;
        isolation: unset;
      }

      &:checked {
        background-image: unset !important;
        background-color: transparent;
        outline: 1px solid transparent;
        outline-offset: -2px;
        border: 2px solid #E0E0E0;
      }

      &.active {
        border: unset;
        background-color: transparent;
        border-radius: 0;

        .radio_btn {
          background-color: #344777;
          outline: 1px solid white;
          outline-offset: -2px;
          border: 3px solid #E0E0E0;
          // &:checked{
          //   background-image: unset !important;
          //   background-color: transparent;
          //   outline: 1px solid transparent;
          //   outline-offset: -2px;
          //   border: 2px solid #E0E0E0;
          // }
        }
      }
    }
  }
}

.leave_req_screen {
  .leave_heading {
    padding: 4px;
    color: #344777;
    width: fit-content;
    background: rgba(194, 210, 250, 0.31);
  }

  .me-12 {
    margin-right: 12px;
  }

  .left_border {
    height: 27px;
    margin-top: 3px;

    &.all {
      border-left: 2px solid #E64CFF;
    }

    &.new {
      border-left: 2px solid #F6587E;
    }

    &.pending {
      border-left: 2px solid #FFB162;
    }

    &.approved {
      border-left: 2px solid #5AB461;
    }

    &.denied {
      border-left: 2px solid #FF6262;
    }
  }

  .all_req {
    .heading {
      span {
        color: #E64CFF;

        i {
          &::before {
            color: #E64CFF;
          }
        }
      }
    }
  }

  .new_req {
    .heading {
      span {
        color: #F6587E;

        i {
          &::before {
            color: #F6587E;
          }
        }
      }
    }
  }

  .pend {
    .heading {
      span {
        color: #FFB162;

        i {
          &::before {
            color: #FFB162;
          }
        }
      }
    }
  }

  .app {
    .heading {
      span {
        color: #5AB461;

        i {
          &::before {
            color: #5AB461;
          }
        }
      }
    }
  }

  .den {
    .heading {
      span {
        color: #FF6262;

        i {
          &::before {
            color: #FF6262;
          }
        }
      }
    }
  }

  .card {
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    padding: 11px 7px;

    .deny {
      color: #FF6262;
      border-radius: 4px;
      padding: 4px 5px;
      background-color: rgba(255, 98, 98, 0.10);
    }

    .approve {
      color: #5AB461;
      border-radius: 4px;
      padding: 4px 5px;
      background-color: rgba(90, 180, 97, 0.10);
    }

    .detail {
      color: #3D8DEC;
      padding-block: 4px;
    }

    .content {
      overflow: hidden;
      white-space: nowrap;
      height: 20px;
      border-radius: 6px;
      background: rgba(167, 167, 167, 0.1);
      display: flex;
      align-items: center;
    }

    .start_times {
      font-size: 12px;
      font-family: $font-m !important;

      color: #5AB461;
    }

    .end_times {
      font-size: 12px;
      font-family: $font-m !important;
      color: #FF6262;
    }
  }
}

.tabs_view2 {
  border: 0.26px solid #A7A7A7;
  border-top: unset;
  border-radius: unset;
  background-color: unset;
  width: unset;
  margin: unset;

  .nav-item {
    .nav-link {
      border: unset !important;
      padding: 0;
      border-bottom: 4px solid transparent;
      font-size: 13px;
      font-family: $font-r;
      color: #333;

      &.active {
        border-radius: unset;
        background-color: unset;
        font-weight: 600;
        color: #344777;
        border-bottom: 4px solid #344777 !important;
      }
    }
  }
}

.payroll_screen {
  .gross {
    border-left: 2px solid #F9DC59;
    height: 25px;
    margin-top: 6px;
  }

  .deu {
    border-left: 2px solid #93F7F1;
    height: 25px;
    margin-top: 6px;
  }

  .att {
    border-left: 2px solid #5AB461;
    height: 25px;
    margin-top: 6px;
  }
}

.icon-menuicon {
  &::before {
    color: #333;
  }
}

.screen_footer {
  position: absolute;
  bottom: 45px;
  width: 100%;
  background: #fcfcfc;
}

.organization {
  &.basic_info {
    height: calc(100vh - 215px);
  }
}

.late_count {
  &.basic_info {
    height: calc(100vh - 169px);
  }
}

.leave_policy {
  height: calc(100vh - 183px);
  overflow-y: auto;
}

.leave_policy_content {
  .gap-3 {
    .heading {
      background: rgba(194, 210, 250, 0.31);
      padding: 4px;
      color: #344777;
    }
  }

  .radio_btn {
    position: relative;
    top: 2px;
    border: 1.5px solid #E0E0E0;

    &:focus {
      box-shadow: unset;
    }

    &:checked {
      background-image: unset !important;
      background-color: #344777;
      outline: 1px solid white;
      outline-offset: -2px;
      border: 3px solid #E0E0E0;
    }
  }

  .table_section {
    border-radius: 8px;
    border: 0.5px solid #E0E0E0;

    .right_border {
      border-right: 0.5px solid #E0E0E0;
    }

    .bottom_border {
      border-bottom: 0.5px solid #E0E0E0 !important;
    }

    .form-check {
      &.custom_check_box {
        .form-check-label {
          font-weight: 600;
        }
      }
    }

    .num {
      padding-right: 21.5px !important;
    }

    .icon-edit-pencil {
      vertical-align: middle;

      &::before {
        color: #344777;
      }
    }
  }

  .tabs_view3 {
    border: 0;

    .nav-link {
      color: #707070;
      font-size: 13px;
      font-family: $font-m !important;
      padding: 4px 5px 4px 12px;
      background: #F5F5F5;
      border: unset;
      border-radius: unset;

      &.active {
        color: #344777;
        font-weight: 600;
        background: rgba(194, 210, 250, 0.31);
      }
    }
  }

  .list {
    border: 1px solid #E0E0E0;
    background: #F5F5F5;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    display: inline-block;
  }

  .my_input {
    margin: 0;

    input {
      margin: 0 3px;
      height: 18px;
      width: 21px;

      &:focus {
        border-color: #FD8458 !important;
      }
    }
  }

  .tabs_view4 {
    border: unset;

    .nav-item {
      .nav-link {
        padding: 0;
        border: unset !important;

        &:hover {
          border: unset;
          isolation: unset;
        }

        &:checked {
          background-image: unset !important;
          background-color: transparent;
          outline: 1px solid transparent;
          outline-offset: -2px;
          border: 2px solid #E0E0E0;
        }

        &.active {
          border: unset;
          background-color: transparent;
          border-radius: 0;


        }
      }
    }
  }
}

.validation_message {
  text-overflow: ellipsis;
  overflow: hidden;

}

.daily_tracking {
  &.basic_info {
    height: calc(100vh - 108px) !important;

    .no_data {
      top: 50%;
      left: 50%;
    }
  }

  .ar_daily_tracking_screnshot {
    height: calc(100vh - 265px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .today_task {
    .card_section {
      width: 396px;
      padding: 20px 18px;
      border-radius: 15px;
      border: 1px solid #E0E0E0;
      background: #FFF;

      .text-center {
        padding: 4px;
        border-radius: 6px;
        background: #F5F5F5;

        .icon-edit-pencil {
          vertical-align: middle;

          &::before {
            color: #344777;
          }
        }
      }

      .blocker {
        padding: 7px 11px;
        border-radius: 6px;
        background: rgba(255, 98, 98, 0.13);
      }
    }

    .ranges {
      width: 130px;

      .range {
        background-color: #D9D9D9;
        border-radius: 15px;
        height: 12px;

        .fill {
          height: 12px;
          background-color: #5AB461;
          border-radius: 15px;
        }
      }

      .precentage {
        font-family: $font-m !important;
        color: #5AB461;
      }
    }

    .task_timing {
      display: flex;
      padding: 8px 16px;
      justify-content: space-between;
      align-items: center;
      background: rgba(194, 210, 250, 0.12);
    }

    .active_time {
      background-color: #5AB461;
      width: 12px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      height: 12px;
      border-radius: 50px;
      margin-right: 6px;

      &::before {
        content: '';
        position: absolute;
        width: 0.5px;
        height: 264px;
        top: 12px;
        left: 6px;
        background: #5AB461;
      }
    }


    .screenshot {
      background: #F5F5F5;
      padding: 8px;
      width: 193px;
      position: relative;
      z-index: 9;

      .img-container {
        border-radius: 15px;

        &.s-175 {
          height: 110px;
        }
      }

      i {
        font-size: 14px;
      }

      .icon-Group-3994 {
        &::before {
          color: #FF6262;
        }
      }
    }
  }
}

.login {
  position: relative;
  height: calc(100vh - 0px);
  overflow-y: auto;

  .heading {
    .em {
      font-size: 28px;
      color: #FD8458;
    }

    .sy {
      font-size: 28px;
      color: #344777;
    }

    .Contact {
      color: #FD8458;
    }

    .num {
      color: #344777;
    }
  }

  .text {
    &.Platform {
      font-size: 20px;
      background-color: #344777;
      color: #FFFFFF;
      padding: 8px 6px;
      width: fit-content;
      margin: auto;
    }
  }

  .login_card {
    width: 400px;
    position: relative;
    z-index: 9;
    margin: 16px auto 100px auto;
    padding-top: 16px;
    border-radius: 15px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .wave {
      height: 65px;
    }

    .rep {
      position: absolute;
      bottom: 8%;
      left: 25%;

      span {
        color: #FD8458;
      }
    }
  }

  .screen_wave {
    height: 100px;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  .back_image {
    img {
      position: absolute;
      top: 29%;
      height: 90%;
    }
  }
}

.fs-13 {
  font-size: 13px !important;
}

// .user_profile {
//   position: absolute;
//   right: 11px;
//   top: 53px;
//   width: 260px;
//   padding: 15px 10px;
//   border-radius: 15px;
//   border: 1px solid #E0E0E0;
//   background: #FCFCFC;
//   z-index: 10;

//   .dot_icon {
//     padding-right: 12px;

//     .icon {
//       transform: rotate(90deg);

//       i {
//         &::before {
//           color: #707070;
//         }
//       }
//     }

//     .actions_popup {
//       top: 20px !important;
//       right: 0px;
//       padding: 3px 2px 3px 0px;

//       p {
//         height: 24px;
//       }

//       .icon-Group-icon {
//         &::before {
//           color: #707070 !important;
//         }
//       }
//     }
//   }

//   .ar_content {
//     padding: 14px 14px 12px;

//     .gap-22 {
//       gap: 22px;
//     }

//     .gap-6 {
//       gap: 6px;
//     }
//   }
// }

.ar_user_profile {
  position: absolute;
  right: 11px;
  top: 53px;
  padding: 10px;
  border-radius: 13px;
  border: 1px solid #E0E0E0;
  background: #FCFCFC;
  z-index: 10;
  width: 214px;

  .hk-imgHandler {
    img {
      border-radius: 4px;
      border: 2px solid #E0E0E0;
    }
  }

  .icon-Vector7 {
    font-size: 9px;
    margin-bottom: 2px;
  }

  .icon-edit-pencil {
    &::before {
      color: #ffffff;
    }
  }
}

.search_field {
  .input-group-text {
    padding: 0 5px;

  }
}

.setting {
  i {
    font-size: 18px;
  }
}

.ar_dashborad {
  padding: 13px 16px !important;
}

.ems_heading {
  color: #344777;
  padding: 3px 6px;
  background: rgba(194, 210, 250, 0.31);
}

.add_new_device {
  .icon-Vector-9 {
    font-size: 21px;
  }
}

.add_new_growth {
  .icon-Vector-10 {
    font-size: 21px;
  }
}

.fs-11 {
  font-size: 11px !important;
}

.ar-tabs-action-bottom {
  background: #fcfcfc;
  bottom: 0;
  margin-left: auto !important;
  transition: all .3s;
  width: 100%;
  z-index: 20;
  position: sticky;
}

.ar_organization {
  height: auto !important;

  .screen_footer {
    position: static !important;
  }
}

.add_new_office {
  .modal-body {
    padding-bottom: 0 !important;
  }
}

.add_new_shift {
  .modal-body {
    padding-bottom: 0 !important;
  }

  .ar-shift-header {
    background: rgba(194, 210, 250, 0.12);
    height: 20px;

    div {
      &:nth-child(1) {
        width: 140px;
        padding-left: 10px;
      }

      &:nth-child(2) {
        width: 97px;
        padding-right: 13px;
      }

      &:nth-child(4) {
        width: 100px;
      }

      &:nth-child(5) {
        width: 101px;
      }
    }
  }

  .w-50px {
    width: 50px;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .day {
    width: 28px;
    height: 28px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    background-color: #F8A588;
  }

  .tue {
    background-color: #78DBBE !important;
  }

  .wed {
    background-color: #F27792 !important;
  }

  .thu {
    background-color: #6D8AEF !important;
  }

  .fri {
    background-color: #6D8AEF !important;
  }

  .sat {
    background-color: #7D7978 !important;
  }

  .sun {
    background-color: #7D7978 !important;
  }

}

.rdg-row-even {
  div[role=gridcell]:has(.ar_sum-light) {
    background-color: #EFFFEF;
  }
}

.rdg-row-odd {
  div[role=gridcell]:has(.ar_sum-light) {
    background-color: #C9FFC8;
  }
}

.rdg-row-even {
  div[role=gridcell]:has(.ar_gros-light) {
    background-color: #FFFCEF;
  }
}

.rdg-row-odd {
  div[role=gridcell]:has(.ar_gros-light) {
    background-color: #FFF5C8;
  }
}

.rdg-row-even {
  div[role=gridcell]:has(.ar_detuct-light) {
    background-color: #F2FEFE;
  }
}

.rdg-row-odd {
  div[role=gridcell]:has(.ar_detuct-light) {
    background-color: #CBFFFC;
  }
}

.rdg-row-even {
  div[role=gridcell]:has(.ar_net-sallery) {
    background-color: #FCF5FF;
  }
}

.rdg-row-odd {
  div[role=gridcell]:has(.ar_net-sallery) {
    background-color: rgb(230, 76, 255, 18%);
  }
}

.ar_hide-top-checkbox {
  .rdg-header-row {
    div[role=columnheader]:has(.rdg-checkbox-label) {
      label {
        display: none !important;
      }
    }
  }

  .c1730fa47-0-0-beta-26 {
    box-shadow: none !important;
  }
}

.paid_leave {
  position: absolute;
  left: -47px;
  top: 29px;
  display: none;
  border-radius: 7px;
  border: 0.5px solid #E0E0E0;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 125px;
  z-index: 9;

  .ar_box {
    width: 5px;
    height: 5px;
    background-color: #707070;
    margin-right: 6px;
  }
}

.fs-9 {
  font-size: 9px !important;
}

.select_mounth {
  .modal-content {
    border-radius: 13px;
    border: 1px solid #E0E0E0;
    background: #FCFCFC;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.13);
    padding: 18px;
    width: 239px;
    position: absolute;
    left: 40%;
  }

  .gap-23 {
    gap: 23px;
  }

  .btn:disabled,
  .btn.disabled,
  fieldset:disabled .btn {
    color: #fff;
    pointer-events: none;
    background-color: #fd8458;
    opacity: 0.6;
  }

  .col-md-7 {
    width: 54.5% !important;
  }
}

.ar_payroll-icon {
  width: 28.528px;
  height: 28.528px;
  background-color: #F5F5F5;
  border: 0.5px solid #E0E0E0;
  border-radius: 50%;

  i {
    color: #344777;
    font-size: 15px;
  }
}

.create_payroll {
  padding: 12px 0px 11px 0px;
  width: 368px;
  border: 1px solid #E0E0E0;
  background: #FFF;
  top: 34px;
  right: 0;
  position: absolute;
  z-index: 9;
  z-index: 21;

  .scroll {
    height: 418px;
    overflow-y: auto;
    padding-right: 11px;
    overflow-x: hidden;
  }

  .ar_top-heading {
    padding-right: 11px;

    .content {
      width: 284px;
      background: rgba(194, 210, 250, 0.31);
      padding: 4px 17px 6px;

      .clr {
        color: #344777 !important;
      }
    }

    .icon-Icon-metro-cross {
      &::before {
        color: #667085 !important;
      }
    }
  }

  .Gross_pay {
    .ar_header {
      padding: 6px;
      margin-block: 6px 15px;
      width: 340px;
      background: rgba(194, 210, 250, 0.31);
    }

    .blue {
      background: #344777 !important;
      color: #fff;
    }

    .content {
      padding-inline: 7px;

      .inp-box {
        background-color: rgba(194, 210, 250, 0.12);
        padding-left: 8px;
        height: 25px;
        align-content: center;
      }
    }
  }

  .orange-line {
    width: 340px;
    padding: 5px 6px;
    border-radius: 6px;
    background: #FD8458;
  }
}

.ar_request_screen {
  .tabs_view {
    border-radius: 6px !important;
    background: #f5f5f5;
    border: .26px solid #e0e0e0;
    margin: auto;

    .nav-item {
      .nav-link {
        border: 0 solid #707070;
        color: #333;
        cursor: pointer;
        font-size: 13px;
        padding: 5px 15px;
        font-family: $font-r;
        position: relative;
        text-decoration: none;
        transition: all .3s;

        i {
          &::before {
            color: #4F4F4F !important;
          }
        }

        &.active {
          background-color: #32457b !important;
          border-radius: 6px !important;
          color: #fff;
          font-family: $font-sb;

          i {
            &::before {
              color: #FFFFFF !important;
            }
          }
        }
      }
    }
  }
}

.custom_checkboxes_gird {
  .rdg-checkbox {
    inline-size: 14px;
    block-size: 14px;
    border: 1px solid #707070;

    &:checked {
      background-color: red;
      outline: unset;
      outline-offset: -6px;
      position: relative;
    }

    &::after {
      content: "";
      position: absolute;
      left: 15.5px;
      top: 8px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .rdg-row[aria-selected="true"] {
    &:has(.rdg-checkbox) {
      background-color: #f7dfc7;

      input[type=checkbox] {
        &:checked {
          + {
            .rdg-checkbox {
              background-color: #344777;
              outline: unset;
              outline-offset: -6px;
              border: none;
            }
          }
        }
      }
    }
  }

  .rdg-header-row {
    div[aria-selected=true] {
      input[type=checkbox] {
        &:checked {
          +.rdg-checkbox {
            background: #EA9A4B;
            outline-color: #EA9A4B;
            border-color: #EA9A4B;
          }
        }
      }
    }
  }

  &.service_line_grid {
    .rdg-checkbox {
      &::after {
        top: 10px;
      }
    }
  }
}

.ar_sum-light {
  position: relative;

  .leave-hove {
    &:hover+.paid_leave {
      display: block;
    }
  }
}

.payroll_grid {
  height: calc(100vh - 275px) !important;
}

.payroll_screen_grid {
  height: calc(100vh - 215px) !important;
}

.aprroved_confirmation {
  border-radius: 8px;
  background: #FCFCFC;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 343px;
  min-height: unset;
  top: 237px;

  .modal-body {
    padding: 15px 10px;

    .icon-Icon-metro-cross {
      &::before {
        color: #667085 !important;
      }
    }

    .green_text {
      color: #5AB461 !important;
    }

    .red_text {
      color: #FF6262;
    }

    .approve {
      color: #5AB461;
      border-radius: 6px;
      padding: 4px 59px;
      background: rgba(90, 180, 97, 0.10);
    }

    .cancel {
      color: #707070;
      border-radius: 6px;
      padding: 4px 59px;
      background: rgba(248, 250, 251, 1);
    }

    .deny {
      color: #FF6262;
      border-radius: 6px;
      padding: 4px 59px;
      background: rgba(255, 98, 98, 0.10);
    }
  }
}

.expense_grid {
  height: calc(100vh - 184px) !important;
  max-height: unset !important;

}

.add_new_stock {
  .modal-content {
    border-radius: 13px;
    border: 1px solid #E0E0E0;
    background: #FCFCFC;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .modal-body {
    padding: 15px 14px 8px !important;
  }
}

.employee_tabs-grid {
  height: calc(100vh - 55px) !important;
  max-height: unset !important;
}

.leave_tabs-grid {
  height: calc(100vh - 269px) !important;
  max-height: unset !important;
}

.checkin_clr {
  color: #5AB461 !important;
}

.checkout_clr {
  color: #FF6262 !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.crear_inc {
  width: 50px;
  height: 20px;
  border-radius: 5px;
  background-color: rgba(48, 190, 130, 0.1);
  color: #5AB461;
  margin-top: 6px;
}

.Present,
.Approved {
  height: 25px;
  width: 80px;
  border-radius: 15px;
  background: rgba(90, 180, 97, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-m;
  color: #5AB461 !important;
}

.Absent,
.Dennied {
  width: 75px;
  height: 22px;
  border-radius: 15px;
  background: rgba(255, 98, 98, 0.1);
  color: #FF6262 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-m;
}

.Pending {
  width: 75px;
  height: 22px;
  border-radius: 15px;
  background: rgba(255, 177, 98, 0.1);
  color: #FFB162 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-m;
}

.Weekend {
  width: 75px;
  height: 22px;
  border-radius: 15px;
  background: rgba(191, 191, 191, 0.13);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-m;
}

.NH {
  width: 75px;
  height: 22px;
  border-radius: 15px;
  background: rgba(128, 173, 188, 0.13);
  color: #80ADBC !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-m;
}

.Leave {
  width: 75px;
  height: 22px;
  border-radius: 15px;
  background: rgba(245, 154, 227, 0.13);
  color: #F59AE3 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-m;
}

.rbc-now {
  button {
    width: 31px;
    height: 31px;
    background-color: #5AB461;
    border-radius: 50%;
    color: #fff;
  }

  span {
    color: #549F4E !important;
    font-family: 'Poppins-semibold' !important;
  }
}

.add_new_skill {
  .modal-content {
    border: 0 !important;
  }
}

.ar_mounth-name {
  position: absolute;
  top: 53px;
  left: 10px;
}

.rbc-month-row {
  flex: unset !important;
  flex-basis: unset !important;
  height: 15% !important;
}

.holiday_clr {
  background-color: rgba(128, 173, 188, 0.34);
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.daily_tracking_task_grid {
  height: calc(100vh - 264px) !important;
  max-height: unset !important;
}

.Toastify__close-button {
  padding-bottom: 4px;
}

.MuiInputBase-root {
  span {
    max-width: 91px;
    margin-right: 4px;
  }
}

.payroll_table {
  thead {
    position: sticky;
    top: 0;
    z-index: 9;
    border-block-end: 1px solid #E0E0E0;

    tr {
      height: 30px;

      th {
        white-space: nowrap;
        padding-inline: 8px;
        background: #fcfcfc;
        font-size: 13px;
        color: #333;
      }
    }
  }

  tbody {
    tr {
      background: #fcfcfc !important;
      height: 30px;

      .ateendance-summary {
        background-color: #EFFFEF !important;
        text-align: center;
      }

      .gross-pay {
        background-color: #FFFCEF !important;
      }

      .deduction {
        background-color: #F2FEFE !important;
      }

      .net-salary {
        background-color: #FCF5FF !important;
      }

      &:nth-child(even) {
        background: #F1F3F9 !important;

        .ateendance-summary {
          background-color: #C9FFC8 !important;
        }

        .gross-pay {
          background-color: #FFF5C8 !important;
        }

        .deduction {
          background-color: #CBFFFC !important;
        }

        .net-salary {
          background-color: rgb(230, 76, 255, 18%) !important;
        }
      }

      td {
        padding-inline: 8px;
        white-space: nowrap;
        font-size: 12px;
      }
    }

    .input {
      .form-control {
        height: 19px !important;
        width: 60px;
      }
    }
  }

  .office_name_truncate {
    max-width: 128px !important;
  }
}

.dropdown-bgc-position {
  .input select.form-control {
    background-position: 92% 50% !important;
  }
}

.overall_view {
  height: calc(100vh - 166px);

  .ar_calender-view {
    height: calc(100vh - 168px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.ar_tab-v2-custom {
  .nav-tabs {
    gap: 14px;
    padding: 0px 12px;
    height: 32px;
    background: #f5f5f5;

    .nav-item {
      .nav-link {
        padding-right: 0 !important;

        &.active {
          padding: 2px 15px !important;
        }
      }
    }
  }
}

.date-slect-bg {
  .input select.form-control {
    background-color: #F5F5F5 !important;
  }
}

.employee_filter_popup {
  position: absolute;
  top: 196px;
  right: -154px;
  left: unset;
}

.ar_img_avature {
  height: 200px;
  width: 200px;
}

.ar_img_avature_2 {
  height: 150px;
  width: 150px;
}

.atendance_employee_header {
  ul {
    gap: 10px;
  }
}

.allocated_devices {
  height: calc(100vh - 232px) !important;
  max-height: unset !important;
}

.ar_disable-popup {
  .input {
    user-select: none;

    .form-control {
      background: #f5f7fc;
      border-color: #f5f7fc;
      color: #333;
      pointer-events: none;
    }

    i {
      pointer-events: none;
    }
  }
}

.my_info_screen {
  height: calc(100vh - 180px) !important;

  .allocated_devices {
    height: calc(100vh - 211px) !important;
    max-height: unset !important;
  }

  .s-265 {
    height: 180px !important;
  }

  .ar_employee-info {
    height: calc(100vh - 512px) !important;
  }

  .col-md-9 {
    height: calc(100vh - 188px);
  }
}

.ar_daily_tracking {
  &.basic_info {
    height: calc(100vh - 34px) !important;
  }

  .ar_employee-info {
    height: calc(100vh - 432px) !important;
  }

  .daily_tracking_task_grid {
    height: calc(100vh - 190px) !important;
  }

  .ar_daily_tracking_screnshot {
    height: calc(100vh - 190px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.overflow-x-auto {
  overflow-x: auto !important;

  &::-webkit-scrollbar {
    height: 8px !important;
  }
}

.ar_resignation_popup {
  .modal-body {
    padding: 15px 14px !important;

    .modal_header {
      margin-bottom: 15px;
    }

    .input {
      margin-bottom: 0 !important;
    }
  }

  .modal_footer {
    padding: 14px !important;
    border-top: 0.25px solid #a7a7a7;

    .approve {
      background-color: rgba(90, 180, 97, 0.1);
      border-radius: 15px;
      padding: 3px 10px;
      color: #5AB461 !important;
    }

    .deny {
      border-radius: 15px;
      border: 1px solid #E0E0E0;
      padding: 3px 10px;
      background-color: #F8FAFB;
      color: #616060 !important;
    }
  }
}

.ar_leave_screen {
  .col-md-3 .content .ar_employee-info {
    height: calc(100vh - 497px) !important;
  }
}

.tim-adj-req_content {
  height: calc(100vh - 317px);
  overflow-y: auto;
}

.sidebar_heading {
  color: #C6C6C6 !important;
  gap: 6px;
  ;
}

.ar_document {
  background-color: rgba(194, 210, 250, 31%);
  border-radius: 15px;
  padding: 2px 6px;
  width: 100%;

  .validation_message {
    width: 87%;
  }
}

.ar_top_navbar {
  .icon-Group-icon {
    &::before {
      color: #4F4F4F !important;
    }
  }

  .ar_box {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;

    &.check_in {
      background-color: rgba(90, 180, 97, 0.7);

      .icon-check1 {
        &::before {
          color: #5AB461 !important;
        }
      }
    }

    &.check_out {
      background-color: rgba(255, 98, 98, 7%);
    }

    &.notification {
      background-color: rgba(224, 224, 224, 0.40);

      i {
        &::before {
          color: #4f4f4f;
        }
      }
    }
  }

  .dark-text {
    color: #4F4F4F !important;
  }

  .light-text {
    color: #828282 !important;
  }
}

.switch_organization {
  height: calc(100vh - 212px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 12px;
}

.no_data {
  position: absolute;
  top: 51%;
  left: 46%;
  transform: translate(-50%, -50%);

  // p {
  //   color: rgba(0, 0, 0, 0.50);
  //   font-size: 16px;
  //   font-family: $font-m !important;
  //   line-height: normal;
  //   width: fit-content;
  //   margin: auto;
  // }

  // span {
  //   margin: auto;
  //   height: 35px;
  //   display: flex;
  //   width: 35px;
  //   justify-content: center;
  //   align-items: center;
  //   border-radius: 50px;
  //   background: #DBDBDB;
  // }
  .icon-ar-nodata {
    font-size: 89px;
  }
}

.ar_user_avatar {
  .img-container {
    margin-left: -8px;

    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .right-img {
    +.tool_tip {
      right: -32px;
    }
  }

  .left-img {
    +.tool_tip {
      right: 40px;
    }
  }

  .center-img {
    +.tool_tip {
      right: -12px;
    }
  }
}

.ar_employee_card-view {
  height: calc(100vh - 212px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px !important
}

.ar_leave-req_content {
  height: calc(100vh - 326px);
  overflow-y: auto;
  overflow-x: hidden;
}

.attendenceScreen {
  height: calc(100vh - 236px);
  padding: 0;
  margin-top: 10px;
  max-height: unset !important;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
}

.ar_emp-position {
  background: #32457B;
  border-color: #32457B;
  color: #fff !important;
  border-radius: 15px;
  padding: 2px 6px;
}

.cursor-default {
  cursor: default !important;
}

.ar_delete-modal {
  width: 240px;

  .deny {
    width: 92px;
  }

  .cancel {
    width: 92px;
  }
}

.ar_de-activate-modal {
  .deny {
    width: 92px;
  }

  .cancel {
    width: 92px;
  }

  .approve {
    width: 92px;
  }
}

.px-14 {
  padding-inline: 14px;
}

textarea.form-control {
  height: auto !important;
  min-height: auto !important;
}

.ar_hov-scrollbar,
.my_info_screen,
textarea,
.employee_tabs-grid,
.hk-EA-calender,
.accademics_right-side {
  &::-webkit-scrollbar-track {
    background: unset;
  }

  &::-webkit-scrollbar-thumb {
    background: unset;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      background: #E8EAED;
    }

    &::-webkit-scrollbar-thumb {
      background: #CDCCCC;
    }
  }
}

.apexcharts-legend-text {
  font-size: 11px !important;
  font-family: 'Poppins-medium' !important;
  color: #434343 !important;
}

.ar_view-report-popup {
  border-radius: 13px;
  background: #FCFCFC;
  box-shadow: 3px -1px 8px 0px rgba(0, 0, 0, 0.07);
  padding: 15px 10px;
  z-index: 9;
  position: absolute;
  top: 28px;
  left: 62px;
  width: 261px;

  .br-bottom-1 {
    border-bottom: 0.5px solid #E0E0E0;
  }

  .red-color {
    color: #E16868 !important;
  }

  .late-count {
    color: #EBA889 !important;
  }

  .yelo-clr {
    color: #EFBF20 !important;
  }

  .ar_box {
    width: 8px;
    height: 8px;
    background: rgba(194, 210, 250, 0.31);
  }

  .blue-color {
    color: #32457B !important;
  }
}

.ar_roles_screen {
  .ar_content {
    height: calc(100vh - 202px);
  }

  .nav-tabs {
    .nav-link {
      border: 0;
      border-bottom: 0.5px solid #707070;
      margin-left: 6px;
      padding: 0;

      &:hover {
        color: unset;
      }

      &.active {
        border-radius: 0;
        color: #32457B;
        font-size: 15px !important;
        font-family: $font-sb;
        background-color: unset;
      }
    }
  }

  .roles-content {
    background-color: rgba(194, 210, 250, 0.12);
    padding-block: 15px;
    margin-top: 6px;
    height: calc(100vh - 251px);
    overflow-y: auto;
  }
}

.ar_menu_list {
  margin-block: 12px !important;

  ul {
    border-left: unset !important;
    display: flex;
    flex-direction: column;
    gap: 16px;

    li {
      &:hover {
        a {
          color: #fff !important;
          background-color: unset !important;
        }

        i {
          &::before {
            color: #fff !important;
          }
        }

        .ar_dot {
          background-color: #fff !important;
        }
      }

      a {
        font-family: $font-m;
        color: #C6C6C6;
        padding-left: unset !important;
        height: unset !important;

        .ar_dot {
          width: 5px;
          height: 5px;
          background-color: #C6C6C6;
          border-radius: 50%;
        }

        i {
          font-size: 15px !important;

          &::before {
            color: #C6C6C6 !important;
            ;
          }
        }


        &.active {
          border: none;
          color: #fff;
          background-color: unset !important;

          i {
            &::before {
              color: #fff !important;
            }
          }

          .ar_dot {
            background-color: #fff !important;
          }
        }
      }
    }
  }
}

.ar-check-modal {
  width: 300px;

  .modal-content {
    border-radius: 13px;
    border: 1px solid #E0E0E0;
    background: #FCFCFC;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .modal-body {
    padding: 15px !important;

    .ar-tabs {
      border-radius: 15px;
      border: 0.26px solid #E0E0E0;
      background: #FFF;
      padding: 3px 8px;

      .active {
        color: #FFFFFF !important;
        border-radius: 15px;
        background: #32457B;
        padding: 1px 12px;

        i {
          &::before {
            color: #FFFFFF !important;
          }
        }
      }
    }

    .img-brder {
      padding-bottom: 8px;
      border-bottom: 0.16px solid #E0E0E0;
    }

    .blue-color {
      color: #32457B !important;
    }
  }
}

.checkin_clr {
  color: #5AB461 !important;
}


.checkout_clr {
  color: #FF6262 !important;
}

.icon-Payments {
  &::before {
    color: #fff !important;
  }
}

.ar_team-popup {
  --bs-modal-width: 631px;

  .modal-content {
    border-radius: 10px;
    border: 1px solid #E0E0E0;
    background: #FCFCFC;
  }

  .modal-body {
    padding: 15px 0px !important;

    .border-2 {
      border: 3px solid #E0E0E0;
    }

    .hired-box {
      border-radius: 8px;
      border: 1px solid #E0E0E0;
      background: #FFF;
      padding: 10px 8px;
      width: 148px;
      height: 124px;

      .icon {
        .icon-menuicon {
          &::before {
            color: #707070;
          }
        }
      }

      .validation_message {
        width: 110px;
        white-space: nowrap;
      }

      .actions_popup {
        right: -3px;
        top: 21px;
      }
    }

    .scroll {
      max-height: 384px;
      overflow-y: auto;
      margin-bottom: 8px;
      border-bottom: 0.5px solid #E0E0E0;
      padding-bottom: 8px;

      &:nth-last-child(1) {
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

.tool_tip {
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: -9px;
    left: 25%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    transform: rotate(177deg);
    border-color: #344777 transparent transparent transparent;
  }

  position: absolute;
  padding: 2px 8px;
  background-color: #344777;
  width: fit-content;
  border-radius: 3px;
  top: 40px;
  right: -47px;
  z-index: 9;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
}

.icon-request {
  &::before {
    color: #fff;
  }
}

.add_new_team {
  .img-container {
    margin-left: -8px;

    &:nth-child(1) {
      margin-left: 0;
    }
  }
}

.add_new_announcement {
  .modal-body {
    padding: 15px 14px !important;
    padding-bottom: 8px !important;

    .input {
      margin-bottom: 0 !important;
    }
  }
}

.active_time-bar {
  height: 23px;

  .bar {
    width: 6px;
    background-color: #5AB461;

    &.inactive {
      background-color: #C6C6C6;
    }

    &.remaining {
      background-color: #fff;
    }
  }
}

.ar_payslip-screen {
  .ar_employee-info {
    height: calc(100vh - 437px) !important;

  }
}

.ar_radio-btn {
  input[type=radio] {
    accent-color: #344777;
    width: 15px;

    &:checked+.text {
      color: #344777;
      font-family: $font-m;
    }
  }
}

.ar_hov-icon {
  .icon-arrow-top-right {
    display: none;

    &::before {
      color: #333 !important;
    }
  }

  &:hover {
    .icon-arrow-top-right {
      display: block;
    }
  }
}

.ar_employee-detail {
  position: absolute;
  top: 29px;
  left: 13px;
  z-index: 10;
  background: white;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .top-header {
    background-color: #ECEFF1;
    padding: 7px 9px;
    border: 1px solid #e3e3e3;
  }

  .scroll {
    max-height: 198px;
    overflow-y: auto;
  }
}

.add_new-policy {
  width: 476px;

  .modal-content {
    border-radius: 13px;
    border: 1px solid #E0E0E0;
    background: #FCFCFC;
  }

  .modal-body {
    padding: 15px !important;
    padding-bottom: 8px !important;

    .nav-tabs {
      .nav-link {
        border: 0;
        padding: 0;

        .number {
          border-radius: 10px;
          width: 15.5px;
          height: 16.5px;
          border: 1px solid #707070;
        }

        .ar-br-right-1 {
          height: 1px;
          background-color: #E0E0E0;
          width: 55px;
          top: 8px;
          position: absolute;
          right: -70px;
        }

        &:hover {
          color: #707070;
        }

        &.active {
          color: #32457B !important;
          font-size: 12px;
          font-family: $font-sb;

          .number {
            border: 2px solid #32457B;
            color: #32457B !important;
          }
        }
      }
    }

    .input {
      margin-bottom: 0;
    }

    label {
      margin-bottom: 3px;
    }

    .icon-plus {
      &::before {
        color: #32457B !important;
      }
    }

    .ar_right-input {
      border-radius: 5px;
      background: rgba(194, 210, 250, 0.12);
      height: 25px;
      padding-left: 10px;
    }
  }
}


.px-10 {
  padding-inline: 10px;
}

.ar_my-request-screen {
  .no_data {
    top: 68%;
    left: 48%;
  }

  .all_employee_grid {
    height: calc(100vh - 260px);
  }

  &.other-requset {
    .all_employee_grid {
      height: calc(100vh - 268px);
    }

    .ar_leave-req_content {
      height: calc(100vh - 332px);
    }
  }

  &.my-leave-requset {
    .all_employee_grid {
      height: calc(100vh - 283px);
    }
  }

  &.my-other-requset {
    .all_employee_grid {
      height: calc(100vh - 214px);
    }
  }
}

.ar_screen-footer {
  .screen_footer {
    position: static !important;
  }
}

.daily-progress-cal {
  .input {
    gap: 0;

    .form-control {
      border-radius: 50px;
    }

    .input-icon {
      top: 11px;
    }
  }
}

// date range picker styling
.hk-daterange-picker {
  .calender-icon {
    display: flex;

    .calender-input {
      position: relative;

      input {
        min-width: 115px;
        padding-right: 30px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    span.icon-calender {
      position: absolute;
      top: 4px;
      right: 14px;

      >span {
        font-size: 18px;
      }
    }

    .calender-btn {
      margin-left: -10px;
      z-index: 1;
      border: 0;
      outline: 0;
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;

      .app-mui-select-no-ui {
        width: max-content;

        .MuiSelect-select {
          font-size: 13px;
          padding-right: 29px;
        }

        svg {
          width: 28px;
          height: 24px;
          top: 0;
          color: #000;
          background: #ff9f44;
          border-radius: 50px;
        }
      }
    }
  }
}

.submenu {
  &:has(.sub-menu-list li a.active) {
    .submenu-top-item {
      border-color: #FD8458;
      background: rgba(194, 210, 250, 0.12);
      border-radius: 6px;
      color: #fff;
    }
  }
}

.expense-nodata {
  .no_data {
    top: 59%;
    left: 48%;
  }
}

.male {
  background: #E0F1FF;
  color: #427ACA !important;
}

.female {
  background: #FFEDFA;
  color: #C539B3 !important;
}

.rather-undefined {
  background-color: #D9d7d7 !important;
  color: #333333 !important;
}

.daily-progress-grid {
  height: calc(100vh - 151px);
  overflow-y: auto;
}

.overflowX-hidden {
  overflow-x: hidden;
}

.avatar-group {
  .img-container {
    margin-left: -8px;

    &:nth-child(1) {
      margin-left: 0;
    }
  }
}

.ar-email-varification {
  width: 640px;
  padding: 32px;

  .ar_header {
    padding: 40px 32px;
    row-gap: 24px;
  }

  .line {
    width: 125px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.24);
  }

  .content {
    padding: 32px;

    .blue-text {
      color: #2230F6;
    }
  }

  .ar-footer {
    padding: 32px;

    .f-family {
      font-family: Pacifico;
      color: #999999;
    }

    .f2-family {
      font-family: Inter;
      color: #999999;
    }
  }
}

.lunch-dinner {
  overflow-y: auto;
  height: calc(100vh - 170px);
  margin-bottom: 15px;
}

.za-annual-leave {
  width: 173px;
  border-radius: 4px;
  background: #F7E8FF;
  padding: 18.5px 8px;

}

.za-casual-leave {
  width: 270px;
  border-radius: 4px;
  background: #D0ECFF;
  padding: 8px;

  .za-header {
    border-bottom: 0.7px solid #DADADA;
  }
}

.za-calender-nodata {
  .no_data {
    top: 55%;
    left: 60%;
  }
}

.za-total-hour {
  width: 364px;
  position: absolute;
  left: 38%;
  top: 16px;
  display: none;
  z-index: 1;

  .total_hours {
    padding: 1px 6px;
    background-color: #344777;
    color: #FFFFFF;
    line-height: normal;
  }

  .the_total_hours {
    padding: 0px 8px 6px;
    background-color: #E2E8F6;
    line-height: normal;
  }
}

.za-today_screenshot {
  padding: 7px 8px;
  background-color: rgba(194, 210, 250, 0.12);
}

.ze {
  border-left: 0.5px solid #E0E0E0;
  height: 27.667px;
}

.za-nodata-found {
  position: relative;
  width: 294px;
  height: 190px;
  padding: 20px 18px;
  border-radius: 13px;
  border: 1px solid #E0E0E0;
  background-color: #FFF;

  .no_data {
    top: 50%;
    left: 50%;
    .text-center{
      background-color: none;
    }
  }
}

// Handlle Scrollbar
.user_basic_info {
  .ar_employee-info {
    height: calc(100vh - 470px);
  }

  .my_info_screen {
    height: calc(100vh - 187px) !important;
    overflow-y: auto;
    overflow-y: auto;
  }

  .academics_screen {
    .accademics_right-side {
      height: calc(100vh - 163px) !important;
      overflow-y: auto;
    }
  }

  .employee_tabs-grid {
    height: calc(100vh - 209px) !important;
  }
}

.employee_basic_info {
  .ar_employee-info {
    height: calc(100vh - 495px) !important;
  }

  .my_info_screen {
    height: calc(100vh - 212px) !important;
    overflow-y: auto;
  }

  .academics_screen {
    .accademics_right-side {
      height: calc(100vh - 190px) !important;
      overflow-y: auto;
    }
  }

  .employee_tabs-grid {
    height: calc(100vh - 232px) !important;
  }
}

.user_overall_view {
  .hk-EA-calender {
    height: calc(100vh - 243px);
    overflow-y: auto;
  }

  .employee_tabs-grid {
    height: calc(100vh - 210px) !important;
  }
}

.employee_overall_veiw {
  .ar_employee-info {
    height: calc(100vh - 502px) !important;
  }

  .hk-EA-calender {
    height: calc(100vh - 274px);
    overflow-y: auto;
  }

  .employee_tabs-grid {
    height: calc(100vh - 243px) !important;
  }
}

.ar_info-icon {
  .icon-info-icon {
    &::before {
      color: #344777;
    }

    &:hover+.za-total-hour {
      display: block;
    }
  }
}

.za-today_screenshot {
  padding: 7px 8px;
  background-color: rgba(194, 210, 250, 0.12);
}

.ze {
  border-left: 0.5px solid #E0E0E0;
  height: 27.667px;
}

.za-nodata-found {
  position: relative;
  width: 294px;
  height: 220px;
  padding: 20px 18px;
  border-radius: 13px;
  border: 1px solid #E0E0E0;
  background-color: #FFF;

  .no_data {
    top: 50%;
    left: 50%;

    .text-center {
      background: unset !important;
    }
  }
}

div[role=columnheader]:has(.ar_info-icon) {
  overflow: visible !important;

}

.za-missed-task {

  // .za-box {
  //   background-color: #FF6262 !important;
  //   width: 7px !important;
  //   height: 7px !important;
  //   border-radius: 50%;
  // }

  .za-icons-1 {
    border-radius: 2px;
    border: 0.5px solid #E0E0E0;
    padding-right: 6px;

    .za-icon-time {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      background: #FD8458;
      padding: 4px 5.85px;

    }

    .icon-Vector-5 {
      &::before {
        color: #A6A6A6;
      }
    }

  }
  .Contract {
    background-color: #FF6262;
  }
  .box {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  .request {
    color: #32457B;
    border-radius: 4px;
    background: rgba(194, 210, 250, 0.12);
    padding-inline: 8px;
  }
}